import React, { useState, useEffect } from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Input, Grid, Segment, Header, Button, List, Divider, Label, Icon, Accordion, TextArea, Loader } from 'semantic-ui-react';
import PropTypes from 'prop-types'
import '../pages/PassageDiscoveryBuilderEditPage.css';
import { makeApiCalls } from '../redux/api';
import { useSelector } from 'react-redux';
import { getWordCount } from '../services/system';
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import "@webscopeio/react-textarea-autocomplete/style.css";
import Hotkeys from 'react-hot-keys';

export const PassageDiscoveryBuilderEditPageAimSection = ({passageDiscovery, setPassageDiscovery, subjectSentenceDescription, subjectSentenceDescriptionWordCount, setSubjectSentenceDescriptionWordCount, aimDescription, setAimDescription, onSaveFailed, changeTab}) => {
    const user = useSelector(state => state.user);
    const [expandAimTitle, setExpandAimTitle] = useState(false);

    const [saveAimButtonColor, setSaveAimButtonColor] = useState('yellow');
    const [saveAimButtonText, setSaveAimButtonText] = useState('Save');
    const [saveAimButtonLoading, setSaveAimButtonLoading] = useState(false);


    useEffect(() => {
        if (passageDiscovery.subject_sentence) {
            setSubjectSentenceDescriptionWordCount(getWordCount(passageDiscovery.subject_sentence));
        } else {
            setSubjectSentenceDescriptionWordCount(0);
        }
    }, []);

    /////// Aim Related ////////
    const onClickAimTitle = () => {
        var tmp = expandAimTitle;
        setExpandAimTitle(!tmp);
    }

    // const onChangeAimDescription = (e, data) => {
    //     console.log('kkk', e);
    //     setAimDescription(data.value);
    // }

    const onChangeAimDescription = (e) => {
        setAimDescription(e.target.value);
    }

    const submitAimToBackend = (newAim, afterSaveSuccess, afterSaveFailed) => {
        var tmpPD = passageDiscovery;
        tmpPD.aim = newAim;
        setPassageDiscovery(tmpPD);
        makeApiCalls(
            [
                {
                    endpoint: (user == undefined? 'PUBLIC_PASSAGE_DISCOVERY_UPDATE': 'MEMBER_PASSAGE_DISCOVERY_UPDATE'),
                    data: tmpPD
                }
            ]
        ).then((res) => {
            afterSaveSuccess(res)
            
        }).catch((err) => {
            afterSaveFailed(err)
        })
    }

    const onClickSaveAim = () => {
        setSaveAimButtonLoading(true);
        submitAimToBackend(
            aimDescription,
            (res) => {
                console.log(res);
                setSaveAimButtonLoading(false);
                setSaveAimButtonColor('green');
                setSaveAimButtonText('Saved!');
                setTimeout(() => {
                    setSaveAimButtonColor('yellow');
                    setSaveAimButtonText('Save');
                }, 2000);
            },
            (err) => {
                console.log(err);
                setSaveAimButtonLoading(false);
                onSaveFailed();
            }
        );

    }


    return (
        <Hotkeys 
            keyName="ctrl+s,command+s,ctrl+up,command+up,ctrl+down,command+down,ctrl+pageup,command+pageup,ctrl+pagedown,command+pagedown,ctrl+enter,command+enter,ctrl+backspace,command+backspace" 
            filter={(event) => {
                return true;
            }}
            onKeyDown={(keyName, e, handle) => {
                e.preventDefault();
                if (keyName == 'ctrl+s' || keyName == 'command+s') {
                    onClickSaveAim();
                }
                if (keyName == 'ctrl+pageup' || keyName == 'command+pageup') {
                    changeTab(2);
                }
                if (keyName == 'ctrl+pagedown' || keyName == 'command+pagedown') {
                    changeTab(4);
                }
                if (keyName == 'ctrl+enter' || keyName == 'command+enter') {
                    var targets = document.getElementsByClassName('aim-edit-textarea');
                    if (targets.length > 0) {
                        targets[0].focus();
                    }
                }
            }}
        >  
            <Accordion>
                <Accordion.Title
                    active={expandAimTitle}
                    index={0}
                    onClick={onClickAimTitle}
                >
                    <Header size="medium"><Icon name='dropdown' />AIM – Main truth or principle</Header>
                </Accordion.Title>
                <Accordion.Content active={expandAimTitle}>
                    <p>
                    At this step, we move from the facts of the passage to the truth the passage teaches us. The aim is the main truth or principle rising from the Bible text. The aim is the key conclusion to learn and remember. It may reflect what God has impressed on you while studying this passage.
                    </p>
                    <List bulleted>
                        <List.Item>
                        The aim captures the primary truth or principle the author is conveying.
                        </List.Item>
                        <List.Item>
                        The aim expresses the main truth taught throughout the passage.
                        </List.Item>
                        <List.Item>
                        The aim must be doctrinally correct – in agreement with the teaching of the entire Bible (not taken out of context).
                        </List.Item>
                        <List.Item>
                        The aim may be a truth about God or people – a biblical truth that leads you somewhere rather than summarizing facts.
                        </List.Item>
                        <List.Item>
                        The aim begins with the phrase “CATL”... cause audience to learn.
                        </List.Item>
                    </List>
                </Accordion.Content>
            </Accordion>
            <Divider />
            <Header size="tiny"><Icon color="yellow" name="exclamation triangle" /> Instructions</Header>
            <List bulleted>
                <List.Item>
                <Label basic style={{'borderTop':"3px solid #fbbd08"}}>Subject Sentence</Label> is displayed for your reference.
                </List.Item>
                <List.Item>
                Fill in your <Label basic>Aim</Label> and click <Label color="yellow">Save</Label> when appropriate.
                </List.Item>
            </List>
            <Divider />
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Segment color="yellow">
                            <Header size="tiny" textAlign="center">Subject Sentence</Header>
                            <Divider />
                            {
                                subjectSentenceDescription === undefined || subjectSentenceDescription === null || subjectSentenceDescription == ''?
                                <Header size="tiny">No subject sentence created yet.</Header>
                                :
                                <Segment>
                                    <Label attached='top'>Word Count: {subjectSentenceDescriptionWordCount.toString() + (subjectSentenceDescriptionWordCount == 1?' Word':' Words')}</Label>
                                    <div
                                        id="subject-sentence-edit-contenteditable-div"
                                        className="subject-sentence-edit-contenteditable-div"
                                        dangerouslySetInnerHTML={{__html: subjectSentenceDescription}}
                                    ></div>
                                </Segment>
                            }
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
                <Divider />
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Segment>
                            {/* <TextArea 
                                rows={4}
                                value={aimDescription}
                                style={{
                                    'width': '100%',
                                    'border': 'none',
                                    'backgroundColor': 'transparent',
                                    'resize': 'none',
                                    'outline': 'none',

                                }}
                                placeholder='CATL: ...'
                                onChange={onChangeAimDescription}
                            /> */}
                            <ReactTextareaAutocomplete
                                className="aim-edit-textarea"
                                loadingComponent={() =>  <Loader active inline />}
                                style={{
                                    'width': '100%',
                                    'border': 'none',
                                    'backgroundColor': 'transparent',
                                    'resize': 'none',
                                    'outline': 'none',
                                    'fontSize': 'max(1rem, 16px)'
                                }}
                                placeholder='CATL: ...'
                                rows={4}
                                value={aimDescription}
                                onChange={onChangeAimDescription}
                                minChar={0}
                                trigger={{
                                    "C": {
                                        dataProvider: token => {
                                            if (aimDescription.includes("CATL: ")) {
                                                return []
                                            } else {
                                                return [
                                                    { name: "CATL: ", char: "CATL: " },
                                                ];
                                            }
                                        },
                                        component: ({ entity: { name, char } }) => <div>{name}</div>,
                                        output: (item, trigger) => item.char
                                    },
                                    "使": {
                                        dataProvider: token => {
                                            if (aimDescription.includes("使聽眾能學到：")) {
                                                return [];
                                            } else {
                                                return [
                                                    { name: "使聽眾能學到：", char: "使聽眾能學到：" },
                                                ];
                                            }
                                        },
                                        component: ({ entity: { name, char } }) => <div>{name}</div>,
                                        output: (item, trigger) => item.char
                                    }
                                }}
                            />
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Button color={saveAimButtonColor} onClick={onClickSaveAim} loading={saveAimButtonLoading} fluid>{saveAimButtonText}</Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Hotkeys>
    );
};

PassageDiscoveryBuilderEditPageAimSection.propTypes = {
    passageDiscovery: PropTypes.object,
    setPassageDiscovery: PropTypes.func,
    subjectSentenceDescription: PropTypes.string,
    subjectSentenceDescriptionWordCount: PropTypes.number,
    setSubjectSentenceDescriptionWordCount: PropTypes.func,
    aimDescription: PropTypes.string,
    setAimDescription: PropTypes.func,
    onSaveFailed: PropTypes.func
}
