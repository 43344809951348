import { makeApiCalls } from "../redux/api";

export function getLessonList(
    page
) {
    return makeApiCalls(
        [
            {
                endpoint: 'PUBLIC_LESSON_LIST_GET',
                params: {
                    'page': page
                }
            },
        ]
    );
}

export function getLesson(
    lessonCode
) {
    return makeApiCalls(
        [
            {
                endpoint: 'PUBLIC_LESSON_GET',
                slugFragments: {
                    lesson_code: lessonCode
                }
            },
        ]
    );
}

export function storeLessonAnswer(answer) {
    localStorage.setItem('lesson-answer', JSON.stringify(answer));
}

export function getLessonAnswer() {
    var answer = localStorage.getItem('lesson-answer');
    return answer ? JSON.parse(answer) : {};
}

export function removeLessonAnswer() {
    localStorage.removeItem('lesson-answer');
}