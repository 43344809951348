import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import 'semantic-ui-css/semantic.min.css';
import { Dimmer, Loader, Form, Container, Message, Grid, Segment, Header, Button, TextArea, Image, List, Progress, Divider, Label, Icon, Tab, Input, Accordion, Checkbox, Rating, Card, GridRow, GridColumn} from 'semantic-ui-react';
import { ResponsiveContainer } from '../containers/ResponsiveContainer';
import { useForm } from "react-hook-form";
import { login, storeJwtToken } from '../services/authenticate';
import { setUser } from '../redux/actions';
import { makeApiCalls } from '../redux/api';

export const LoginPage = () => {
    const user = useSelector(state =>
        state.user
    );
    const dispatch = useDispatch();
    const dispatchTool = (toDo, ...args) => {
        dispatch(toDo(...args));
    }
    const history = useHistory();
    const { register, handleSubmit } = useForm();
    const [displayLoader, setDisplayLoader] = useState(false);
    const [displayAccountActivatedMessage, setDisplayAccountActivatedMessage] = useState(false);
    const [displayResetPasswordEmailSentMessage, setDisplayResetPasswordEmailSentMessage] = useState(false);
    const [displayPasswordResetMessage, setDisplayPasswordResetMessage] = useState(false);
    const [displayLoginFailedMessage, setDisplayLoginFailedMessage] = useState(false);

    useEffect(() => {
        setDisplayAccountActivatedMessage(false);
        setDisplayResetPasswordEmailSentMessage(false);
        setDisplayPasswordResetMessage(false);

        var urlParams = new URLSearchParams(window.location.search);
        var ac = urlParams.get('ac');
        var fp = urlParams.get('fp');
        var rp = urlParams.get('rp');

        if (ac == "1") {
            setDisplayAccountActivatedMessage(true);
        }

        if (fp == "1") {
            setDisplayResetPasswordEmailSentMessage(true);
        }

        if (rp == "1") {
            setDisplayPasswordResetMessage(true);
        }
    }, [])

    const onSignup = (formData) => {
        // display loader
        setDisplayLoader(true);

        // reset login messsages
        setDisplayLoginFailedMessage(false);

        // login
        login(
            formData.email,
            formData.password,
            dispatchTool
        )
        .then((response) => {
            setDisplayLoader(false);

            // redirect to passage discovery start page
            history.push('/passage-discovery-builder/start');
        })
        .catch((err) => {
            console.log(err);
            setDisplayLoader(false);
            setDisplayLoginFailedMessage(true);
        });
        // makeApiCalls(
        //     [
        //         {
        //             endpoint: 'AUTH_LOGIN_WITH_EMAIL',
        //             data: {
        //                 "login": formData.email,
        //                 "password": formData.password,
        //             }
        //         }
        //     ],
        // )
        // .then((response) => {
        //     setDisplayLoader(false);

        //     // store response data
        //     storeJwtToken(response[0].data.token);
        //     dispatch(setUser(response[0].data.user));
        //     // redirect to passage discovery start page
        //     history.push('/passage-discovery-builder/start');
        // })
        // .catch((err) => {
        //     console.log(err);
        //     setDisplayLoader(false);
        //     setDisplayLoginFailedMessage(true);
        // });
    }

    return (
        <ResponsiveContainer
            headerActiveTab="Log in"
        >
            <Container>
                <Dimmer active={displayLoader}>
                    <Loader>Loading</Loader>
                </Dimmer>
                {
                    displayLoginFailedMessage && 
                    <Message icon size='large'  color="red">
                        <Icon name='times' />
                        <Message.Content>
                            <Message.Header>Log in failed.</Message.Header>
                            Please try again.
                        </Message.Content>
                    </Message>
                }
                {
                    displayAccountActivatedMessage &&
                    <Message icon size='large' color="green">
                        <Icon name='check' />
                        <Message.Content>
                            <Message.Header>You have successfully activated your account.</Message.Header>
                            Please login with your account credentials
                        </Message.Content>
                    </Message>
                }
                {
                    displayResetPasswordEmailSentMessage &&
                    <Message icon size='large' color="green">
                        <Icon name='check' />
                        <Message.Content>
                            <Message.Header>Reset Password Email sent!</Message.Header>
                            Please check for the email and proceed.
                        </Message.Content>
                    </Message>
                }
                {
                    displayPasswordResetMessage &&
                    <Message icon size='large' color="green">
                        <Icon name='check' />
                        <Message.Content>
                            <Message.Header>You have successfully reset your password.</Message.Header>
                            Please login with your new account credentials
                        </Message.Content>
                    </Message>
                }
                <Message size='large' attached>
                    <Message.Header>Log in</Message.Header>
                    {/* <Message.Content>
                        Whether a leader of a BSF class or interested to serve, create an account to join us and gain access to the tools.
                    </Message.Content> */}
                </Message>
                <Form className='attached fluid segment' onSubmit={handleSubmit(onSignup)}>
                    <Form.Field width={16}>
                        <label htmlFor="email">Email</label>
                        <input type="text" name="email" id="email" ref={register} />
                    </Form.Field>
                    <Form.Field width={16}>
                        <label htmlFor="password">Password</label>
                        <input type="password" name="password" id="password" ref={register} />
                    </Form.Field>
                    <Form.Field width={16}>
                        <Grid>
                            <GridRow>
                                <GridColumn width={8}>
                                    {/* <Form.Checkbox fluid="true" label='Remember me'/> */}
                                </GridColumn>
                                <GridColumn width={8}>
                                    <a style={{'float': 'right'}} href='/forgot-password'>Forgot Password</a>
                                </GridColumn>
                            </GridRow>
                        </Grid>
                    </Form.Field>
                    <Button color="yellow" fluid>Log in</Button>
                </Form>

                <Message attached='bottom' warning>
                    <Icon name='help' />
                    New to BSF Teams?&nbsp;<a href='/signup'>Sign up here</a>&nbsp;instead.
                </Message>
            </Container>
            
        </ResponsiveContainer>
    );
};