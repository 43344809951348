const initState = {
    user: null,
    siteFontSize: 14
};

export default function reducer(
    state = initState,
    { type, payload }
) {
    switch (type) {
        case 'SET_USER':
            return {
                ...state,
                user: payload
            }
        case 'SET_SITE_FONT_SIZE':
            return {
                ...state,
                siteFontSize: payload
            }
    }

    return state;
}