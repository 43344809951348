import React, { useState, useEffect } from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Input, TextArea, Grid, Segment, Header, Button, List, Divider, Label, Icon, Accordion } from 'semantic-ui-react';
import PropTypes from 'prop-types'
import '../pages/PassageDiscoveryBuilderEditPage.css';
import { makeApiCalls } from '../redux/api';
import { useSelector } from 'react-redux';
import { getCaretPosition, getWordCount } from '../services/system';
import Hotkeys from 'react-hot-keys';
import {PassageDiscoveryBuilderDivisionListDisplay} from '../components/PassageDiscoveryBuilderDivisionListDisplay';

export const PassageDiscoveryBuilderEditPageSubjectSentenceSection = ({passageDiscovery, setPassageDiscovery, divisions, subjectSentenceDescription, setSubjectSentenceDescription, subjectSentenceDescriptionWordCount, setSubjectSentenceDescriptionWordCount, onSaveFailed, changeTab}) => {
    const user = useSelector(state => state.user);
    const [expandSubjectSentenceTitle, setExpandSubjectSentenceTitle] = useState(false);

    const [saveSubjectSentenceButtonColor, setSaveSubjectSentenceButtonColor] = useState('yellow');
    const [saveSubjectSentenceButtonText, setSaveSubjectSentenceButtonText] = useState('Save');
    const [saveSubjectSentenceButtonLoading, setSaveSubjectSentenceButtonLoading] = useState(false);

    useEffect(() => {
        if (passageDiscovery.subject_sentence) {
            var el = document.getElementById('subject-sentence-edit-contenteditable-div');
            el.innerHTML += passageDiscovery.subject_sentence;
            setSubjectSentenceDescriptionWordCount(getWordCount(passageDiscovery.subject_sentence));
        } else {
            setSubjectSentenceDescriptionWordCount(0);
        }
    }, []);

    useEffect(() => {
        document.getElementById('subject-sentence-edit-contenteditable-div').addEventListener('keydown', (evt) => {
            if (evt.key === "Enter") {
                evt.preventDefault();
            }
        });
    }, [])

    /////// Subject Sentence Related ////////
    const onClickSubjectSentenceTitle = () => {
        var tmp = expandSubjectSentenceTitle;
        setExpandSubjectSentenceTitle(!tmp);
    }

    const onChangeSubjectSentenceDescription = (e) => {
        // console.log(window.getSelection());
        setSubjectSentenceDescriptionWordCount(getWordCount(e.target.innerHTML));
    }

    const submitSubjectSentenceToBackend = (newSubjectSentence, afterSaveSuccess, afterSaveFailed) => {
        var tmpPD = passageDiscovery;
        tmpPD.subject_sentence = newSubjectSentence;
        
        setPassageDiscovery(tmpPD);
        makeApiCalls(
            [
                {
                    endpoint: (user == undefined? 'PUBLIC_PASSAGE_DISCOVERY_UPDATE': 'MEMBER_PASSAGE_DISCOVERY_UPDATE'),
                    data: tmpPD
                }
            ]
        ).then((res) => {
            afterSaveSuccess(res)
            
        }).catch((err) => {
            afterSaveFailed(err)
        })
    }

    const onClickSaveSubjectSentence = () => {
        var newSubjectSentence = document.getElementById('subject-sentence-edit-contenteditable-div').innerHTML;
        setSaveSubjectSentenceButtonLoading(true);
        submitSubjectSentenceToBackend(
            newSubjectSentence,
            (res) => {
                // console.log(res);
                setSubjectSentenceDescription(newSubjectSentence);

                setSaveSubjectSentenceButtonLoading(false);
                setSaveSubjectSentenceButtonColor('green');
                setSaveSubjectSentenceButtonText('Saved!');
                setTimeout(() => {
                    setSaveSubjectSentenceButtonColor('yellow');
                    setSaveSubjectSentenceButtonText('Save');
                }, 2000);
            },
            (err) => {
                console.log(err);
                setSaveSubjectSentenceButtonLoading(false);
                onSaveFailed();
            }
        );

    }

    const onClickInsertProperNoun = () => {
        // console.log(window.getSelection());
        var el = document.getElementById('subject-sentence-edit-contenteditable-div');
        el.innerHTML += '&nbsp;<div class="ui label" contenteditable="true" placeholder="Proper Noun"></div>&nbsp;';
        setSubjectSentenceDescriptionWordCount(getWordCount(el.innerHTML));
    }

    return (
        <Hotkeys 
            keyName="ctrl+s,command+s,ctrl+pageup,command+pageup,ctrl+pagedown,command+pagedown,ctrl+enter,command+enter,ctrl+shift+l,command+shift+l" 
            filter={(event) => {
                return true;
            }}
            onKeyDown={(keyName, e, handle) => {
                e.preventDefault();
                if (keyName == 'ctrl+s' || keyName == 'command+s') {
                    onClickSaveSubjectSentence();
                }
                if (keyName == 'ctrl+pageup' || keyName == 'command+pageup') {
                    changeTab(1);
                }
                if (keyName == 'ctrl+pagedown' || keyName == 'command+pagedown') {
                    changeTab(3);
                }
                if (keyName == 'ctrl+enter' || keyName == 'command+enter') {
                    var targets = document.getElementsByClassName('subject-sentence-edit-textarea');
                    if (targets.length > 0) {
                        targets[0].focus();
                    }
                }
                if (keyName == 'ctrl+shift+l' || keyName == 'command+shift+l') {
                    onClickInsertProperNoun();
                }
            }}
        >  
            <Accordion>
                <Accordion.Title
                    active={expandSubjectSentenceTitle}
                    index={0}
                    onClick={onClickSubjectSentenceTitle}
                >
                    <Header size="medium"><Icon name='dropdown' />SUBJECT SENTENCE – Passage summary</Header>
                </Accordion.Title>
                <Accordion.Content active={expandSubjectSentenceTitle}>
                    <p>
                    The subject sentence is a factual summary of the entire passage.
                    </p>
                    <List bulleted>
                        <List.Item>
                        Write a single sentence of ten words or less (twenty characters or less for Chinese) that summarizes the factual content of the passage.
                        </List.Item>
                        <List.Item>
                        The sentence must have a subject and verb.
                        </List.Item>
                        <List.Item>
                        Stick to the facts: the content of the passage, not your interpretation or conclusion.
                        </List.Item>
                        <List.Item>
                        Incorporate your division sentences and reduce to a concise summary.
                        </List.Item>
                        <List.Item>
                        Start by writing a sentence that is too long and then refine to ten words.
                        </List.Item>
                        <List.Item>
                        Include details to identify this passage among similar passages in the Bible.
                        </List.Item>
                    </List>
                    <p>
                    The first three steps focus on <strong>facts</strong>, with the goal of getting the information from the passage into your mind. The exercise of reducing and refining the content is invaluable in understanding the passage.
                    </p>
                </Accordion.Content>
            </Accordion>
            <Divider />
            <Header size="tiny"><Icon color="yellow" name="exclamation triangle" /> Instructions</Header>
            <List bulleted>
                <List.Item>
                <Label basic style={{'borderTop':"3px solid #fbbd08"}}>Divisions</Label> is displayed for your reference.
                </List.Item>
                <List.Item>
                Fill in your <Label basic>Subject Sentence</Label> and click <Label color="yellow">Save</Label> when appropriate.
                </List.Item>
                <List.Item>
                Click <Label><Icon name='translate' />Insert Proper Noun</Label> to insert a <Label>Proper Noun</Label>. Click on the <Label>Proper Noun</Label> to edit the text inside. Any text inside <Label>Proper Noun</Label> will be counted as 1 word.
                </List.Item>
                <List.Item>
                The <Label>Word Count</Label> will be automatically calculated as you type. Chinese and English are both supported.
                </List.Item>
            </List>
            <Divider />
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Segment color="yellow">
                            <Header size="tiny" textAlign="center">Divisions</Header>
                            <Divider />
                            <PassageDiscoveryBuilderDivisionListDisplay
                                passageDiscovery={passageDiscovery}
                                divisions={divisions}
                                ordered={true}
                                onClickDivision={(index) => {}}
                                getContentListItemClassName={() => null}
                            ></PassageDiscoveryBuilderDivisionListDisplay>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
                <Divider />
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Button onClick={onClickInsertProperNoun}><Icon name='translate' />Insert Proper Noun</Button>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Segment>
                            <Label attached='top'>Word Count: {subjectSentenceDescriptionWordCount.toString() + (subjectSentenceDescriptionWordCount == 1?' Word':' Words')}</Label>
                            <div
                                id="subject-sentence-edit-contenteditable-div"
                                className="subject-sentence-edit-contenteditable-div"
                                placeholder='What is the factual summary of the entire passage?'
                                contentEditable={true}
                                onInput={onChangeSubjectSentenceDescription}
                                onClick={onChangeSubjectSentenceDescription}
                            ></div>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Button color={saveSubjectSentenceButtonColor} onClick={onClickSaveSubjectSentence} loading={saveSubjectSentenceButtonLoading} fluid>{saveSubjectSentenceButtonText}</Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>       
        </Hotkeys>
    );
};

PassageDiscoveryBuilderEditPageSubjectSentenceSection.propTypes = {
    passageDiscovery: PropTypes.object,
    setPassageDiscovery: PropTypes.func,
    divisions: PropTypes.array,
    subjectSentenceDescription: PropTypes.array,
    setSubjectSentenceDescription: PropTypes.func,
    subjectSentenceDescriptionWordCount: PropTypes.array,
    setSubjectSentenceDescriptionWordCount: PropTypes.func,
    onSaveFailed: PropTypes.func
}
