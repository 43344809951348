import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import 'semantic-ui-css/semantic.min.css';
import { Grid, Segment, Header, Button, TextArea, Image, List, Progress, Divider, Label, Icon, Tab, Input, Accordion, Checkbox, Rating, Card} from 'semantic-ui-react';
import { ResponsiveContainer } from '../containers/ResponsiveContainer';
import './HomePage.css';
import appIcon from '../assets/images/pray-icon.png';

export const HomePage = () => {
    const user = useSelector(state =>
        state.user
    );
    const dispatch = useDispatch();
    const history = useHistory();
    return (
        <ResponsiveContainer
            headerActiveTab="Home"
        >
            <div className="home-page-banner-container">
                <div className="home-page-banner-app-icon-container">
                    <img className="home-page-banner-app-icon" src={appIcon} />
                </div>
                <p>Discover passage anytime and anywhere with minimal documentation and maximum focus in the bible.</p>
            </div>
            
        </ResponsiveContainer>
    );
};