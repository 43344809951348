import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import 'semantic-ui-css/semantic.min.css';
import { Container, Grid, Segment, Header, Button, TextArea, Image, List, Progress, Divider, Label, Icon, Tab, Input, Accordion, Checkbox, Rating, Card} from 'semantic-ui-react';
import { ResponsiveContainer } from '../containers/ResponsiveContainer';
import { makeApiCalls } from '../redux/api';
import PropTypes from 'prop-types';
import {PassageDiscoveryOutputDisplayComponent} from '../components/PassageDiscoveryOutputDisplayComponent';
import { getUser } from '../services/authenticate';
import { getUserProfile } from '../services/user';
import { getWordCount } from '../services/system';

export const PassageDiscoveryBuilderOutputPage = ({match}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [passageDiscovery, setPassageDiscovery] = useState();
    const [selectedBibleVersions, setSelectedBibleVersions] = useState();
    const [contents, setContents] = useState();
    const [divisions, setDivisions] = useState();
    const [subjectSentenceDescription, setSubjectSentenceDescription] = useState();
    const [subjectSentenceDescriptionWordCount, setSubjectSentenceDescriptionWordCount] = useState(0);
    const [aimDescription, setAimDescription] = useState();
    const [applications, setApplications] = useState();
    const user = useSelector(state =>
        state.user
    );

    const dispatchTool = (toDo, ...args) => {
        dispatch(toDo(...args));
    }


    useEffect(() => {
        getUserProfile(dispatchTool)
        .then((response) => {

            makeApiCalls([
                {
                    endpoint: 'MEMBER_PASSAGE_DISCOVERY_GET',
                    slugFragments: {
                        passage_discovery_code: match.params.code
                    }
                }
            ]).then((res) => {
                setPassageDiscovery(res[0].data);
                setSelectedBibleVersions(Object.keys(res[0].data.verses));
    
                // setup contents
                if (res[0].data.content === null) {
                    setContents([]);
                } else {
                    setContents(res[0].data.content);
                }
    
                // setup divisions
                if (res[0].data.division === null) {
                    setDivisions([]);
                } else {
                    setDivisions(res[0].data.division);
                }
    
                // setup subject sentence
                setSubjectSentenceDescription(res[0].data.subject_sentence);
                setSubjectSentenceDescriptionWordCount(getWordCount(res[0].data.subject_sentence));
    
                // set up aim
                setAimDescription(res[0].data.aim);
    
                // set up application 
                if (res[0].data.application === null) {
                    setApplications([]);
                } else {
                    setApplications(res[0].data.application);
                }
    
            }).catch((err) => {
    
            });
        })
        .catch((err) => {
            makeApiCalls([
                {
                    endpoint: 'PUBLIC_PASSAGE_DISCOVERY_GET',
                    slugFragments: {
                        passage_discovery_code: match.params.code
                    }
                }
            ]).then((res) => {
                setPassageDiscovery(res[0].data);
                setSelectedBibleVersions(Object.keys(res[0].data.verses));
    
                // setup contents
                if (res[0].data.content === null) {
                    setContents([]);
                } else {
                    setContents(res[0].data.content);
                }
    
                // setup divisions
                if (res[0].data.division === null) {
                    setDivisions([]);
                } else {
                    setDivisions(res[0].data.division);
                }
    
                // setup subject sentence
                setSubjectSentenceDescription(res[0].data.subject_sentence);
                setSubjectSentenceDescriptionWordCount(getWordCount(res[0].data.subject_sentence));
    
                // set up aim
                setAimDescription(res[0].data.aim);
    
                // set up application 
                if (res[0].data.application === null) {
                    setApplications([]);
                } else {
                    setApplications(res[0].data.application);
                }
    
            }).catch((err) => {
    
            });
        })
    }, []);
    
    return (
        <ResponsiveContainer
            headerActiveTab="Passage Discovery Builder"
        >
            <PassageDiscoveryOutputDisplayComponent
                passageDiscovery={passageDiscovery}
                contents={contents}
                divisions={divisions}
                subjectSentenceDescription={subjectSentenceDescription}
                subjectSentenceDescriptionWordCount={subjectSentenceDescriptionWordCount}
                aimDescription={aimDescription}
                applications={applications}
            />
        </ResponsiveContainer>
    );
};

PassageDiscoveryBuilderOutputPage.propTypes = {
    match: PropTypes.object.isRequired,
}