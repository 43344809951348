import React, { useState, useEffect } from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Input, Grid, Segment, Header, Button, List, Divider, Label, Icon, Accordion, TextArea, Message } from 'semantic-ui-react';
import PropTypes from 'prop-types'
import '../pages/PassageDiscoveryBuilderEditPage.css';
import { makeApiCalls } from '../redux/api';
import { useSelector } from 'react-redux';
import Hotkeys from 'react-hot-keys';

export const PassageDiscoveryBuilderEditPageApplicationSection = ({passageDiscovery, setPassageDiscovery, divisions, aimDescription, applications, setApplications, onSaveFailed, changeTab}) => {
    const user = useSelector(state => state.user);
    const [expandApplicationTitle, setExpandApplicationTitle] = useState(false);

    // save application button
    const [saveApplicationButtonColor, setSaveApplicationButtonColor] = useState('yellow');
    const [saveApplicationButtonText, setSaveApplicationButtonText] = useState('Save');
    const [saveApplicationButtonLoading, setSaveApplicationButtonLoading] = useState(false);

    const [applicationsUpdateCount, setApplicationsUpdateCount] = useState(0);

    useEffect(() => {
    }, []);

    /////// Application Related ////////
    const onClickApplicationTitle = () => {
        var tmp = expandApplicationTitle;
        setExpandApplicationTitle(!tmp);
    }

    const onChangeApplications = (e, data) => {
        var tmp = applications;
        tmp[data.order].description = data.value;
        setApplications(tmp);
        setApplicationsUpdateCount(applicationsUpdateCount + 1);
    }

    const submitApplicationsToBackend = (newApplications, afterSaveSuccess, afterSaveFailed) => {
        var tmpPD = passageDiscovery;
        tmpPD.application = newApplications;
        setPassageDiscovery(tmpPD);
        makeApiCalls(
            [
                {
                    endpoint: (user == undefined? 'PUBLIC_PASSAGE_DISCOVERY_UPDATE': 'MEMBER_PASSAGE_DISCOVERY_UPDATE'),
                    data: tmpPD
                }
            ]
        ).then((res) => {
            afterSaveSuccess(res)
            
        }).catch((err) => {
            afterSaveFailed(err)
        })
    }

    const onClickSaveApplication = () => {
        setSaveApplicationButtonLoading(true);
        submitApplicationsToBackend(
            applications,
            (res) => {
                // console.log(res);
                setSaveApplicationButtonLoading(false);
                setSaveApplicationButtonColor('green');
                setSaveApplicationButtonText('Saved!');
                setTimeout(() => {
                    setSaveApplicationButtonColor('yellow');
                    setSaveApplicationButtonText('Save');
                }, 2000);
            },
            (err) => {
                console.log(err);
                setSaveApplicationButtonLoading(false);
                onSaveFailed();
            }
        );
    }

    return (
        <Hotkeys 
            keyName="ctrl+s,command+s,ctrl+up,command+up,ctrl+down,command+down,ctrl+pageup,command+pageup,ctrl+pagedown,command+pagedown,ctrl+enter,command+enter,ctrl+backspace,command+backspace" 
            filter={(event) => {
                return true;
            }}
            onKeyDown={(keyName, e, handle) => {
                e.preventDefault();
                if (keyName == 'ctrl+s' || keyName == 'command+s') {
                    onClickSaveApplication();
                }
                if (keyName == 'ctrl+pageup' || keyName == 'command+pageup') {
                    changeTab(3);
                }
                if (keyName == 'ctrl+pagedown' || keyName == 'command+pagedown') {
                    changeTab(5);
                }
                if (keyName == 'ctrl+enter' || keyName == 'command+enter') {
                    var targets = document.getElementsByClassName('application-edit-textarea');
                    for (let target of targets) {
                        if (target.value == '') {
                            target.focus();
                            return;
                        }
                    }
                }
            }}
        >  
            <Accordion>
                <Accordion.Title
                    active={expandApplicationTitle}
                    index={0}
                    onClick={onClickApplicationTitle}
                >
                    <Header size="medium"><Icon name='dropdown' />APPLICATIONS – Questions motivating change</Header>
                </Accordion.Title>
                <Accordion.Content active={expandApplicationTitle}>
                    <p>
                    Take the aim and put it into everyday life. The applications motivate you or those you lead to put the aim into action. The questions aim the main teaching of the passage into hearts and lives.
                    </p>
                    <List bulleted>
                        <List.Item>
                        Applications are written in question form.
                        </List.Item>
                        <List.Item>
                        Prepare at least one application per division.
                        </List.Item>
                        <List.Item>
                        Questions have the goal of transformation of the heart rather than merely surface behavior change.
                        </List.Item>
                        <List.Item>
                        Balance questions that convict of wrong thinking with positive questions that encourage trust in and surrender to God.
                        </List.Item>
                        <List.Item>
                        Balance questions that encourage evaluation of thinking and actions with questions that invite change in thinking and living (for example a right view of God, trust in Him, priorities in life, etc.).
                        </List.Item>
                        <List.Item>
                        Questions may be targeted at yourself or toward a particular category of people in your audience, (such as unbelievers or the discouraged). Consider varying life stages (students, parents, workers, retired, etc.).
                        </List.Item>
                    </List>
                </Accordion.Content>
            </Accordion>
            <Divider />
            <Header size="tiny"><Icon color="yellow" name="exclamation triangle" /> Instructions</Header>
            <List bulleted>
                <List.Item>
                <Label basic style={{'borderTop':"3px solid #fbbd08"}}>Aim</Label> is displayed for your reference.
                </List.Item>
                <List.Item>
                Fill in your applications according to <Label>Divisions</Label> below and click <Label color="yellow">Save</Label> when appropriate.
                </List.Item>
            </List>
            <Divider />
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Segment color="yellow">
                            <Header size="tiny" textAlign="center">Aim</Header>
                            <Divider />
                            <Segment>
                                <TextArea 
                                    rows={4}
                                    value={aimDescription}
                                    style={{
                                        'width': '100%',
                                        'border': 'none',
                                        'backgroundColor': 'transparent',
                                        'resize': 'none',
                                        'outline': 'none',

                                    }}
                                    placeholder='CATL: ...'
                                    disabled
                                />
                            </Segment>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider />
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                            <List ordered>
                                {
                                    divisions && divisions.length == 0 &&
                                    <Header size="tiny">No divisions created yet.</Header>
                                }
                                {
                                    applications && applications.map((application, index) => (
                                        <List.Item key={index}>
                                            <Message>
                                                <Message.Header>
                                                {
                                                    passageDiscovery && passageDiscovery.scopes && passageDiscovery.scopes.map((scope, index) => (
                                                        <>
                                                            {
                                                                application.scopes[index].scope_from_verse && application.scopes[index].scope_to_verse &&
                                                                <>{scope.scope_book} {application.scopes[index].scope_from_verse} - {application.scopes[index].scope_to_verse}</>
                                                            }
                                                            {
                                                                application.scopes[index] != undefined && application.scopes[index].scope_from_verse != undefined && application.scopes[index + 1] && application.scopes[index + 1].scope_from_verse != undefined &&
                                                                <>, </>
                                                            }
                                                        </>
                                                    ))
                                                }
                                                </Message.Header>
                                                {
                                                    divisions && divisions[index] && 
                                                    <p>
                                                        {divisions[index].description}
                                                    </p>
                                                }
                                            </Message>
                                            <Segment>
                                                <TextArea 
                                                    className="application-edit-textarea"
                                                    order={index}
                                                    rows={8}
                                                    value={application.description}
                                                    style={{
                                                        'width': '100%',
                                                        'border': 'none',
                                                        'backgroundColor': 'transparent',
                                                        'resize': 'none',
                                                        'outline': 'none',
                                                        'fontSize': 'max(1em, 16px)'

                                                    }}
                                                    placeholder='What is the question for this application?'
                                                    onChange={onChangeApplications}
                                                />
                                            </Segment>
                                            {/* <TextArea
                                                order={index}
                                                rows={8}
                                                value={application.description}
                                                style={{'width': '100%'}}
                                                onChange={onChangeApplications}
                                            /> */}
                                            <br/>
                                            <Divider />
                                        </List.Item>
                                    ))
                                }
                            </List>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Button color={saveApplicationButtonColor} onClick={onClickSaveApplication} loading={saveApplicationButtonLoading} fluid>{saveApplicationButtonText}</Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Hotkeys>
    );
};

PassageDiscoveryBuilderEditPageApplicationSection.propTypes = {
    passageDiscovery: PropTypes.object,
    setPassageDiscovery: PropTypes.func,
    divisions: PropTypes.array,
    aimDescription: PropTypes.string,
    applications: PropTypes.array,
    setApplications: PropTypes.func,
    onSaveFailed: PropTypes.func
}
