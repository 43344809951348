import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import 'semantic-ui-css/semantic.min.css';
import { Pagination, Table, Dimmer, Loader, Form, Comment, Select, Grid, Segment, Header, Button, TextArea, Image, List, Progress, Divider, Label, Icon, Tab, Input, Accordion, Checkbox, Rating, Card, Container, Dropdown} from 'semantic-ui-react';
import { ResponsiveContainer } from '../containers/ResponsiveContainer';
import { makeApiCalls } from '../redux/api';
import './LessonsPage.css';
import { getUser } from '../services/authenticate';
import { getUserProfile } from '../services/user';
import {PassageDiscoveryScopeForm} from '../components/PassageDiscoveryScopeForm';
import { getLessonList } from '../services/lessons';
import { useTranslation } from 'react-i18next';
  

export const LessonsPage = () => {
    const { t, i18n } = useTranslation();

    const user = useSelector(state =>
        state.user
    );
    const dispatch = useDispatch();
    const history = useHistory();

    const dispatchTool = (toDo, ...args) => {
        dispatch(toDo(...args));
    }

    const [lessons, setLessons] = useState([]);
    const [totalPages, setTotalPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);

    const [displayLoader, setDisplayLoader] = useState(false);

    useEffect(() => {
        getLessonList(1)
        .then((res) => {
            setLessons(res[0].data.lessons);
            setTotalPages(res[0].data.total_pages);
        })
        .catch((err) => {

        });
    }, []);

    const onPageChange = (event, data) => {
        getLessonList(data.activePage)
        .then((res) => {
            setLessons(res[0].data.lessons);
            setTotalPages(res[0].data.total_pages);
        })
        .catch((err) => {

        });
    }

    const onClickLesson = (code) => {
        history.push('/lesson/edit/' + code);
    }

    return (
        <ResponsiveContainer
            headerActiveTab="Lessons"
        >
            <Container>
                <Dimmer active={displayLoader}>
                    <Loader>Loading</Loader>
                </Dimmer>
                <Header as='h1'>
                    {t('lessons.bsf_lessons')}
                </Header>

                <Grid>
                {
                    true && 
                    <Grid.Row>
                        <Grid.Column>
                            
                            <Table singleLine selectable color="yellow">
                                <Table.Body>
                                    {
                                        lessons.map((item, index) => (
                                            <Table.Row key={index} onClick={() => onClickLesson(item.lesson_code)} style={{cursor: 'pointer'}}>
                                                <Table.Cell>
                                                    {
                                                        i18n.language == 'en' &&
                                                        <>{item.lesson_sequence_en}</>
                                                    }
                                                    {
                                                        i18n.language == 'zh_HK' &&
                                                        <>{item.lesson_sequence_zh_hk}</>
                                                    }
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {
                                                        i18n.language == 'en' &&
                                                        <>{item.title_en}</>
                                                    }
                                                    {
                                                        i18n.language == 'zh_HK' &&
                                                        <>{item.title_zh_hk}</>
                                                    }
                                                </Table.Cell>
                                            </Table.Row>
                                        ))
                                    }
                                </Table.Body>

                                <Table.Footer>
                                    <Table.Row>
                                        <Table.HeaderCell colSpan='2'>
                                            <Pagination
                                                activePage={currentPage}
                                                boundaryRange={1}
                                                onPageChange={onPageChange}
                                                size='mini'
                                                siblingRange={1}
                                                totalPages={totalPages}
                                            />
                                        </Table.HeaderCell>
                                    </Table.Row>

                                </Table.Footer>
                            </Table>
                        </Grid.Column>
                    </Grid.Row>
                }
                </Grid>

            </Container>
            
        </ResponsiveContainer>
    );
};