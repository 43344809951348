import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import 'semantic-ui-css/semantic.min.css';
import { Pagination, Table, Dimmer, Loader, Select, Grid, Segment, Header, Button, TextArea, Image, List, Progress, Divider, Label, Icon, Tab, Input, Accordion, Checkbox, Rating, Card, Container, Dropdown} from 'semantic-ui-react';
import { ResponsiveContainer } from '../containers/ResponsiveContainer';
import { makeApiCalls } from '../redux/api';
import './PassageDiscoveryBuilderStartPage.css';
import { getUser } from '../services/authenticate';
import { getUserProfile } from '../services/user';
import {PassageDiscoveryScopeForm} from '../components/PassageDiscoveryScopeForm';

  

export const PassageDiscoveryBuilderStartPage = () => {
    const user = useSelector(state =>
        state.user
    );
    const dispatch = useDispatch();
    const history = useHistory();
    const [selectedScopes, setSelectedScopes] = useState([]);
    const [displayLoader, setDisplayLoader] = useState(false);

    // passage discovery
    const [passageDiscoveries, setPassageDiscoveries] = useState([]);
    const [totalPages, setTotalPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);

    const dispatchTool = (toDo, ...args) => {
        dispatch(toDo(...args));
    }

    const version = 'NIV';
    
    useEffect(() => {
        // check for user
        // setDisplayLoader(true);
        getUserProfile(dispatchTool)
        .then((res) => {
        })
        .catch((err) => {
            // do sth
        });

        makeApiCalls(
            [
                {
                    endpoint: 'MEMBER_PASSAGE_DISCOVERY_LIST'
                },
            ]
        ).then((res) => {
            setPassageDiscoveries(res[0].data.passage_discoveries);
            setTotalPages(res[0].data.total_pages);
            // setDisplayLoader(false);
        }).catch((err) => {
            // setDisplayLoader(false);
        });
    }, []);

    const onStartPassageDiscovery = () => {
        setDisplayLoader(true);
        makeApiCalls(
            [
                {
                    endpoint: (user == undefined? 'PUBLIC_PASSAGE_DISCOVERY_CREATE': 'MEMBER_PASSAGE_DISCOVERY_CREATE'),
                    data: {
                        scopes: selectedScopes
                    }
                }
            ]
        ).then((res) => {
            setDisplayLoader(false);
            history.push('/passage-discovery-builder/edit/' + res[0].data.code);
        }).catch((err) => {
            
        });
    }

    const onPageChange = (event, data) => {
        // console.log(event);
        // console.log(data);
        makeApiCalls(
            [
                {
                    endpoint: 'MEMBER_PASSAGE_DISCOVERY_LIST',
                    params: {
                        'page': data.activePage
                    }
                },
            ]
        ).then((res) => {
            setCurrentPage(data.activePage);
            setPassageDiscoveries(res[0].data.passage_discoveries);
            // setTotalPages(res[0].data.total_pages);
        }).catch((err) => {

        });
    }

    const onClickPassageDiscovery = (code) => {
        history.push('/passage-discovery-builder/edit/' + code);
    }

    return (
        <ResponsiveContainer
            headerActiveTab="Passage Discovery Builder"
        >
            <Container>
                <Dimmer active={displayLoader}>
                    <Loader>Loading</Loader>
                </Dimmer>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Segment
                                color="yellow"
                            >
                                <Header size='medium' textAlign='center'>
                                    <Header.Content>Start New Passage Discovery</Header.Content>
                                </Header>

                                <PassageDiscoveryScopeForm
                                    selectedScopes={selectedScopes}
                                    setSelectedScopes={setSelectedScopes}

                                    submitButtonText="Start"
                                    onClickSubmitButton={onStartPassageDiscovery}

                                    bibleVersion={version}
                                    
                                    displayLoader={false}
                                ></PassageDiscoveryScopeForm>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                    {
                        passageDiscoveries.length > 0 && totalPages && 
                        <Grid.Row>
                            <Grid.Column>
                                
                                <Table singleLine selectable color="yellow">
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Scope</Table.HeaderCell>
                                            <Table.HeaderCell>Create Date</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {
                                            passageDiscoveries.map((item, index) => (
                                                <Table.Row key={index} onClick={() => onClickPassageDiscovery(item.code)}>
                                                    <Table.Cell>
                                                        {
                                                            item.scopes.map((scope, index) => (
                                                                <>
                                                                    <span>{scope.scope_book + ' ' + scope.scope_from_chapter + ':' + scope.scope_from_verse + ' - ' + scope.scope_to_chapter + ':' + scope.scope_to_verse}</span>
                                                                    {
                                                                        index + 1 < item.scopes.length && <span>,&nbsp;</span>
                                                                    }
                                                                </>
                                                            ))
                                                        }
                                                    </Table.Cell>
                                                    <Table.Cell>{item.created_at}</Table.Cell>
                                                </Table.Row>
                                            ))
                                        }
                                    </Table.Body>

                                    <Table.Footer>
                                        <Table.Row>
                                            <Table.HeaderCell colSpan='2'>
                                                <Pagination
                                                    activePage={currentPage}
                                                    boundaryRange={1}
                                                    onPageChange={onPageChange}
                                                    size='mini'
                                                    siblingRange={1}
                                                    totalPages={totalPages}
                                                    
                                                    // Heads up! All items are powered by shorthands, if you want to hide one of them, just pass `null` as value
                                                    // ellipsisItem={showEllipsis ? undefined : null}
                                                    // firstItem={showFirstAndLastNav ? undefined : null}
                                                    // lastItem={showFirstAndLastNav ? undefined : null}
                                                    // prevItem={showPreviousAndNextNav ? undefined : null}
                                                    // nextItem={showPreviousAndNextNav ? undefined : null}
                                                />
                                            </Table.HeaderCell>
                                        </Table.Row>

                                    </Table.Footer>
                                </Table>
                            </Grid.Column>
                        </Grid.Row>
                    }
                </Grid>

            </Container>
            
        </ResponsiveContainer>
    );
};