import React, { useState } from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Container, Grid, Segment, Header, Button, TextArea, Image, List, Progress, Divider, Label, Icon, Tab, Input, Accordion, Checkbox, Rating, Card} from 'semantic-ui-react';
import PropTypes from 'prop-types'
import {PassageDiscoveryBuilderContentListDisplay} from '../components/PassageDiscoveryBuilderContentListDisplay';
import {PassageDiscoveryBuilderDivisionListDisplay} from '../components/PassageDiscoveryBuilderDivisionListDisplay';

export const PassageDiscoveryOutputDisplayComponent = ({passageDiscovery, contents, divisions, subjectSentenceDescription, subjectSentenceDescriptionWordCount, aimDescription, applications}) => {

  return (
    <Container className="output-page-component" id="output-submission-container">
        <Grid>
            {
                passageDiscovery && 
                <Grid.Row>
                    <Grid.Column width={16}>
                    <Header size="large">
                    {
                        passageDiscovery.scopes.map((scope, index) => (
                            <>
                                <span>
                                    {scope.scope_book + ' ' + scope.scope_from_chapter + ':' + scope.scope_from_verse + ' - ' + scope.scope_to_chapter + ':' + scope.scope_to_verse}
                                    {
                                        index + 1 < passageDiscovery.scopes.length && <>, </>
                                    }
                                </span>
                            </>
                        ))
                    }
                    </Header>
                    </Grid.Column>
                </Grid.Row>
            }
            <Grid.Row>
                <Grid.Column width={16}>
                    <Segment color="yellow">
                        <Header size="tiny" textAlign="center">Contents</Header>
                        <Divider />
                        <PassageDiscoveryBuilderContentListDisplay
                            passageDiscovery={passageDiscovery}
                            contents={contents}
                            ordered={false}
                            displayPrettyIndex={true}
                        ></PassageDiscoveryBuilderContentListDisplay>
                    </Segment>
                </Grid.Column>
            </Grid.Row>
            <span style={{'opacity': '0'}}>------</span>
            <Grid.Row>
                <Grid.Column width={16}>
                    <Segment color="yellow">
                        <Header size="tiny" textAlign="center">Divisions</Header>
                        <Divider />
                        <PassageDiscoveryBuilderDivisionListDisplay
                            passageDiscovery={passageDiscovery}
                            divisions={divisions}
                            ordered={false}
                            displayPrettyIndex={true}
                        ></PassageDiscoveryBuilderDivisionListDisplay>
                    </Segment>
                </Grid.Column>
            </Grid.Row>
            <span style={{'opacity': '0'}}>------</span>
            <Grid.Row>
                <Grid.Column width={16}>
                    <Segment color="yellow">
                        <Header size="tiny" textAlign="center">Subject Sentence</Header>
                        <Divider />
                        {
                            subjectSentenceDescription === undefined || subjectSentenceDescription === null || subjectSentenceDescription == ''?
                            <Header size="tiny">No subject sentence created yet.</Header>
                            :
                            <>
                                <Header size="tiny"><div dangerouslySetInnerHTML={{__html: subjectSentenceDescription}}></div></Header>
                                <br />
                                ({subjectSentenceDescriptionWordCount.toString() + (subjectSentenceDescriptionWordCount == 1?' Word':' Words')})
                            </>
                        }
                        <br />
                        <br />
                    </Segment>
                </Grid.Column>
            </Grid.Row>
            <span style={{'opacity': '0'}}>------</span>
            <Grid.Row>
                <Grid.Column width={16}>
                    <Segment color="yellow">
                        <Header size="tiny" textAlign="center">Aim</Header>
                        <Divider />
                        {
                            aimDescription === undefined || aimDescription === null || aimDescription == ''?
                            <Header size="tiny">No aim created yet.</Header>
                            :
                            <>
                            {
                                aimDescription.split('\r\n').map((aimLine) => (
                                    <Header size="tiny">{aimLine}</Header>
                                ))
                            }
                            </>
                        }
                        <br />
                    </Segment>
                </Grid.Column>
            </Grid.Row>
            <span style={{'opacity': '0'}}>------</span>
            <Grid.Row>
                <Grid.Column width={16}>
                    <Segment color="yellow">
                        <Header size="tiny" textAlign="center">Application</Header>
                        <Divider />
                        <List divided>
                            {
                                applications && applications.length == 0 &&
                                <Header size="tiny">No applications created yet.</Header>
                            }
                            {
                                applications && applications.map((application, index) => (
                                    <List.Item
                                        key={index} 
                                    >
                                        <List.Header>
                                            <Label basic>
                                                {index+1}
                                            </Label>
                                            <span style={{'opacity': '0'}}>. </span>
                                            <Label>
                                            {
                                                passageDiscovery && passageDiscovery.scopes && passageDiscovery.scopes.map((scope, index) => (
                                                    <>
                                                        {
                                                            application.scopes[index].scope_from_verse && application.scopes[index].scope_to_verse &&
                                                            <>{scope.scope_book} {application.scopes[index].scope_from_verse} - {application.scopes[index].scope_to_verse}</>
                                                        }
                                                        {
                                                            application.scopes[index] != undefined && application.scopes[index].scope_from_verse != undefined && application.scopes[index + 1] && application.scopes[index + 1].scope_from_verse != undefined &&
                                                            <>, </>
                                                        }
                                                    </>
                                                ))
                                            }
                                            </Label>
                                        </List.Header>
                                        <br />
                                        <List.Content>
                                            {
                                                application.description.split('\r\n').map((descriptionLine) => (
                                                    <p>{descriptionLine}</p>
                                                ))
                                            }
                                        </List.Content>
                                        <br />
                                    </List.Item>
                                ))
                            }
                        </List>
                    </Segment>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </Container>
  );
};

PassageDiscoveryOutputDisplayComponent.propTypes = {
    passageDiscovery: PropTypes.object,
    contents: PropTypes.array,
    divisions: PropTypes.array,
    subjectSentenceDescription: PropTypes.string,
    subjectSentenceDescriptionWordCount: PropTypes.number,
    aimDescription: PropTypes.string,
    applications: PropTypes.array,
}
