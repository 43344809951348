import { makeApiCalls } from "../redux/api";
import { setUser } from "../redux/actions";

// jwt token
export function storeJwtToken(token) {
    localStorage.setItem('jwt-token',token);
}

export function getJwtToken() {
    return localStorage.getItem('jwt-token');
}

export function removeJwtToken() {
    localStorage.removeItem('jwt-token');
}

// register
export function register(
    email, 
    password, 
) {
    return makeApiCalls(
        [
            {
                endpoint: 'AUTH_REGISTER',
                data: {
                    "username": email,
                    "email": email,
                    "password": password,
                    "password_confirmation": password
                }
            }
        ],
    );
}

export function accountActivation(
    activationCode
) {
    return makeApiCalls(
        [
            {
                endpoint: 'AUTH_ACCOUNT_ACTIVATION',
                data: {
                    "activation_code": activationCode,
                }
            }
        ],
    )
}

export function login(
    email, 
    password,
    dispatchTool=null
) {
    return makeApiCalls(
        [
            {
                endpoint: 'AUTH_LOGIN_WITH_EMAIL',
                data: {
                    "login": email,
                    "password": password,
                }
            }
        ],
    ).then((response) => {
        storeJwtToken(response[0].data.token);
        if (dispatchTool) {
            dispatchTool(setUser, response[0].data.user);
        }
        return response;
    });
}


export function getUser(
    dispatchTool=null
) {
    return makeApiCalls(
        [
            {
                endpoint: 'AUTH_GET_USER',
            }
        ],
    ).then((response) => {
        if (dispatchTool) {
            dispatchTool(setUser, response[0].data.user);
        }
        return response;
    });
}

export function forgotPassword(
    email
) {
    return makeApiCalls(
        [
            {
                endpoint: 'AUTH_FORGOT_PASSWORD',
                data: {
                    "email": email,
                }
            }
        ],
    );
}

export function resetPassword(
    activationCode, 
    password,
) {
    return makeApiCalls(
        [
            {
                endpoint: 'AUTH_RESET_PASSWORD',
                data: {
                    "reset_password_code": activationCode,
                    "password": password,
                }
            }
        ],
    );
}

export function logout(
    dispatchTool=null
) {
    return makeApiCalls(
        [
            {
                endpoint: 'AUTH_LOGOUT'
            }
        ],
    ).then((response) => {
        removeJwtToken();
        if (dispatchTool) {
            dispatchTool(setUser, null);
        }
        return response;
    });
}