import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import 'semantic-ui-css/semantic.min.css';
import { Pagination, Table, Dimmer, Loader, Modal, Form, Comment, Select, Grid, Segment, Header, Button, TextArea, Image, List, Progress, Divider, Label, Icon, Tab, Input, Accordion, Checkbox, Rating, Card, Container, Dropdown} from 'semantic-ui-react';
import { ResponsiveContainer } from '../containers/ResponsiveContainer';
import { makeApiCalls } from '../redux/api';
import './LessonPage.css';
import { getUser } from '../services/authenticate';
import { getUserProfile } from '../services/user';
import {PassageDiscoveryScopeForm} from '../components/PassageDiscoveryScopeForm';
import { getLesson, getLessonAnswer, storeLessonAnswer } from '../services/lessons';
import { useTranslation } from 'react-i18next';
import {DebounceInput} from 'react-debounce-input';  

export const LessonPage = ({match}) => {
    const { t, i18n } = useTranslation();

    const user = useSelector(state =>
        state.user
    );
    const dispatch = useDispatch();
    const history = useHistory();

    const dispatchTool = (toDo, ...args) => {
        dispatch(toDo(...args));
    }

    const [lesson, setLesson] = useState();
    const [answer, setAnswer] = useState({});
    const [bibleVersions, setBibleVersions] = useState();
    const [selectedBibleVersions, setSelectedBibleVersions] = useState();
    const [bibleVersionSettingCount, setBibleVersionSettingCount] = useState(0);

    const [activeTabIndex, setActiveTabIndex] = useState(0);

    const [displayLoader, setDisplayLoader] = useState(true);
    const [displayVerses, setDisplayVerses] = useState(false);
    const [selectedVerses, setSelectedVerses] = useState();

    useEffect(() => {
        getLesson(match.params.code)
        .then((res) => {
            setLesson(res[0].data);
            setBibleVersions(Object.keys(res[0].data.bible_verses[Object.keys(res[0].data.bible_verses)[0]]));
            setSelectedBibleVersions(Object.keys(res[0].data.bible_verses[Object.keys(res[0].data.bible_verses)[0]]));
            
            var localAnswer = getLessonAnswer();
            console.log('localAnswer', localAnswer);
            if (localAnswer && localAnswer[res[0].data.lesson_code]) {
                setAnswer(localAnswer[res[0].data.lesson_code]);
            }
            setDisplayLoader(false);
        })
        .catch((err) => {
            setDisplayLoader(false);
            history.push('/lessons');
        });
    }, []);

    const onClickVersesLabel = (verses) => {
        setSelectedVerses(verses);
        setDisplayVerses(true);
    }

    const parseVerse = (verse, version) => {
        if (version == 'NIV') {
            return verse.replace(/(<([^>\]]+)>)/gi, "").replace(/(\[([^>\]]+)\])/gi, "").replace("  ", " ");
        } else if (version == 'RCUV') {
            return verse.replace(/(<f>)/gi, '（').replace(/(<\/n>)/gi, '）').replace(/(<([^>\]]+)>)/gi, "");
        }
    }
    
    const onClickBibleVersion = (version) => {
        var tmp = selectedBibleVersions;
        if (selectedBibleVersions.includes(version)) {
            if (selectedBibleVersions.length > 1) {
                var index = tmp.indexOf(version);
                tmp.splice(index, 1);
            }
        } else {
            tmp.push(version);
        }
        setSelectedBibleVersions(tmp);
        var count = bibleVersionSettingCount;
        setBibleVersionSettingCount(count+1);
    }

    const onChangeAnswer = (event, dayIndex, question) => {
        console.log(event.target.value);
        
        // update state
        var tmpAnswer = answer;
        tmpAnswer[question.question_number] = event.target.value;
        setAnswer(tmpAnswer);

        // update local storage
        var localAnswer = getLessonAnswer();
        localAnswer[lesson.lesson_code] = tmpAnswer;
        storeLessonAnswer(localAnswer);
    }

    return (
        <ResponsiveContainer
            headerActiveTab="Lessons"
        >
            <>
            <Dimmer active={displayLoader}>
                <Loader>Loading</Loader>
            </Dimmer>
            <Modal
                onClose={() => setDisplayVerses(false)}
                onOpen={() => setDisplayVerses(true)}
                open={displayVerses}
            >
                {
                    selectedVerses &&
                    <>
                        <Modal.Header>
                            {
                                i18n.language == 'zh_HK' &&
                                <>{selectedVerses.scope_name_zh_hk}</>
                            }
                            {
                                i18n.language == 'en' &&
                                <>{selectedVerses.scope_name_en}</>
                            }
                        </Modal.Header>
                        <Modal.Content>
                            <div className="verses-container">
                                <List divided>
                                    {
                                        Object.keys(lesson.bible_verses[selectedVerses.scope_name_en]['NIV']).map((verseKey, verseIndex) => (
                                            <>
                                            <List.Item 
                                                key={`verse-${verseKey}-${verseIndex}`} 
                                            >
                                                <List.Header><Label>{verseKey}</Label></List.Header>
                                                <br />
                                                <List.Content key={`verse-content-${verseKey}-${verseIndex}`}>
                                                {
                                                    Object.keys(lesson.bible_verses[selectedVerses.scope_name_en]).map((version) => (
                                                        <>
                                                            {
                                                                selectedBibleVersions && selectedBibleVersions.includes(version) &&
                                                                <p>{parseVerse(lesson.bible_verses[selectedVerses.scope_name_en][version][verseKey], version)}</p>
                                                            }
                                                        </>
                                                    ))
                                                }
                                                </List.Content>
                                            </List.Item>
                                            </>
                                        ))
                                    }                                
                                </List>
                            </div>
                        </Modal.Content>
                        <Modal.Actions>
                            {
                                bibleVersions && bibleVersions.map((version) => (
                                    <Checkbox checked={selectedBibleVersions.includes(version)} className="version-checkbox" label={version} onChange={() => onClickBibleVersion(version)}/>
                                ))
                            }
                            <Button color='black' onClick={() => setDisplayVerses(false)}>Back</Button>
                        </Modal.Actions>
                    </>
                }
            </Modal>
            {
                lesson && !displayLoader &&
                <Container>
                    <Header as='h1'>
                        {
                            i18n.language == 'zh_HK' &&
                            <>{lesson.lesson_sequence_zh_hk}</>
                        }
                        {
                            i18n.language == 'en' &&
                            <>{lesson.lesson_sequence_en}</>
                        }
                    </Header>
                    <Header as='h2'>
                        {
                            i18n.language == 'zh_HK' &&
                            <>{lesson.title_zh_hk}</>
                        }
                        {
                            i18n.language == 'en' &&
                            <>{lesson.title_en}</>
                        }
                    </Header>

                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Tab
                                    id='lesson-page-tab'
                                    onTabChange={(event, data) => setActiveTabIndex(data.activeIndex)}
                                    menu={{className: "wrapped"}} 
                                    panes={
                                        [1, 2, 3, 4, 5, 6].map(
                                            (dayIndex) => {
                                                return { menuItem: t(`lessons.day_${dayIndex}`), render: () => 
                                                    <Tab.Pane
                                                        data-tab={`day-${dayIndex}`}
                                                    >
                                                        <Header as='h3'>
                                                            {
                                                                i18n.language == 'zh_HK' &&
                                                                <>{lesson[`day_${dayIndex}_title_zh_hk`]}</>
                                                            }
                                                            {
                                                                i18n.language == 'en' &&
                                                                <>{lesson[`day_${dayIndex}_title_en`]}</>
                                                            }
                                                        </Header>
                                                        <Header as='h4'>
                                                            {
                                                                i18n.language == 'zh_HK' &&
                                                                <>{lesson[`day_${dayIndex}_subtitle_zh_hk`]}</>
                                                            }
                                                            {
                                                                i18n.language == 'en' &&
                                                                <>{lesson[`day_${dayIndex}_subtitle_en`]}</>
                                                            }
                                                        </Header>
                                                        {
                                                            lesson[`day_${dayIndex}_bible_scopes`].length > 0 &&
                                                            <div className="day-verses-container">
                                                                {
                                                                    lesson[`day_${dayIndex}_bible_scopes`].map((verses) => (
                                                                        <Label onClick={() => onClickVersesLabel(verses)}>
                                                                            {
                                                                                i18n.language == 'zh_HK' &&
                                                                                <>{verses.scope_name_zh_hk}</>
                                                                            }
                                                                            {
                                                                                i18n.language == 'en' &&
                                                                                <>{verses.scope_name_en}</>
                                                                            }
                                                                        </Label>
                                                                    ))
                                                                }
                                                            </div>
                                                        }
                                                        {
                                                            lesson[`day_${dayIndex}_questions`].map((question, questionIndex) => (
                                                                <>
                                                                    <Header as='h4' className="question-text">
                                                                        {
                                                                            i18n.language == 'zh_HK' &&
                                                                            <>{question.question_number}. {question.question_zh_hk}</>
                                                                        }
                                                                        {
                                                                            i18n.language == 'en' &&
                                                                            <>{question.question_number}. {question.question_en}</>
                                                                        }
                                                                    </Header>
                                                                    {
                                                                        question.bible_scopes.length > 0 &&
                                                                        <div className="day-verses-container">
                                                                            {
                                                                                question.bible_scopes.map((verses) => (
                                                                                    <Label onClick={() => onClickVersesLabel(verses)}>
                                                                                        {
                                                                                            i18n.language == 'zh_HK' &&
                                                                                            <>{verses.scope_name_zh_hk}</>
                                                                                        }
                                                                                        {
                                                                                            i18n.language == 'en' &&
                                                                                            <>{verses.scope_name_en}</>
                                                                                        }
                                                                                    </Label>
                                                                                ))
                                                                            }
                                                                        </div>
                                                                    }
                                                                    {/* <TextArea rows={8} className="answer-textarea"/> */}
                                                                    <DebounceInput
                                                                        element="textarea"
                                                                        rows={8}
                                                                        className="answer-textarea"
                                                                        debounceTimeout={300}
                                                                        value={answer[question.question_number]}
                                                                        onChange={event => onChangeAnswer(event, dayIndex, question)} 
                                                                    />
                                                                </>
                                                            ))
                                                        }
                                                    </Tab.Pane> 
                                                };
                                            }
                                        )
                                    }
                                ></Tab>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                </Container>
            }
            </>
            
        </ResponsiveContainer>
    );
};