import { createMedia } from '@artsy/fresnel';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  Segment,
  Sidebar,
  Visibility,
} from 'semantic-ui-react'
import { Link, useHistory } from 'react-router-dom';
import './ResponsiveContainer.css';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../services/authenticate';
import defaultProfilePic from '../assets/images/default_profile_pic.png';
import { useTranslation } from 'react-i18next';

const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
        mobile: 0,
        tablet: 768,
        computer: 1024,
    },
})

class MobileContainer extends Component {
    state = {}
    
    handleSidebarHide = () => this.setState({ sidebarOpened: false })
  
    handleToggle = () => this.setState({ sidebarOpened: true })
  
    render() {
      const { children, headerActiveTab, user, onClickLogout, i18n, t, onSwitchLang} = this.props
      const { sidebarOpened } = this.state

      return (
        <Media as={Sidebar.Pushable} at='mobile'>
          <Sidebar.Pushable>
            <Sidebar
              as={Menu}
              animation='overlay'
              onHide={this.handleSidebarHide}
              vertical
              visible={sidebarOpened}
            >
              {
                user && user.id && 
                <Menu.Item as={Link} to='/profile' style={{display:"flex", justifyContent: "center"}}>
                  <Image src={user && user.avatar && user.avatar.path? user.avatar.path : defaultProfilePic} style={{'height': '80px', 'width': '80px','objectFit': 'cover'}} avatar bordered size="tiny"/>
                </Menu.Item>
              }
              <Menu.Item style={{display:"flex", justifyContent: "center"}}>
                <Button onClick={onSwitchLang} color='yellow'>
                  {
                    i18n.language == 'zh_HK' && <>EN</>
                  }
                  {
                    i18n.language == 'en' && <>繁</>
                  }
                </Button>
              </Menu.Item>
              <Menu.Item as={Link} to='/' active={headerActiveTab == 'Home'}>{t('header.homepage')}</Menu.Item>
              <Menu.Item as={Link} to='/lessons' active={headerActiveTab == 'Lessons'}>{t('header.lessons')}</Menu.Item>
              <Menu.Item as={Link} to='/passage-discovery-builder/start' active={headerActiveTab == 'Passage Discovery Builder'}>{t('header.passage_discovery_builder')}</Menu.Item>
              {/* <Menu.Item as={Link} active={headerActiveTab == "Leader's Meeting (Coming Soon)"} disabled>Leader's Meeting (Coming Soon)</Menu.Item> */}
              {/* <Menu.Item as={Link} to='/my-teams' active={headerActiveTab == 'My Teams'}>{t('header.my_teams')}</Menu.Item> */}
              {
                user == undefined?
                <>
                  <Menu.Item as={Link} to='/login' active={headerActiveTab == 'Log in'}>{t('header.login')}</Menu.Item>
                  <Menu.Item as={Link} to='/signup' active={headerActiveTab == 'Sign up'}>{t('header.signup')}</Menu.Item>
                </>
                :
                <>
                  <Menu.Item onClick={onClickLogout}>{t('header.logout')}</Menu.Item>
                </>
              }
            </Sidebar>
  
            <Sidebar.Pusher dimmed={sidebarOpened}>
              <Segment
                textAlign='center'
                style={{ padding: '1em 0em' }}
                // vertical
              >
                <Container>
                  <Menu secondary size='large'>
                    <Menu.Item onClick={this.handleToggle}>
                      <Icon name='sidebar' />
                    </Menu.Item>
                    <Menu.Item position='right'>
                      <Header color='yellow'>BSF Teams</Header>
                    </Menu.Item>
                  </Menu>
                </Container>
                {/* <HomepageHeading mobile /> */}
              </Segment>
              <div className="mobile-main-content-container" style={{ "minHeight": window.innerHeight - 195,}}>
                {children}
              </div>
              <Segment vertical style={{ margin: '0em 0em 0em', padding: '2em 0em' }}>
                <Container textAlign='center'>
                  <Divider />
                  {/* <List horizontal divided link size='small'>
                    <List.Item as='a' href='https://www.mybsf.org/terms-of-use'>
                      Terms and Conditions
                    </List.Item>
                    <List.Item as='a' href='https://www.mybsf.org/privacy-policy'>
                      Privacy Policy
                    </List.Item>
                  </List> */}
                  Copyright © {new Date().getFullYear().toString()} <strong>BSF Teams</strong>
                </Container>
              </Segment>
            </Sidebar.Pusher>
          </Sidebar.Pushable>
        </Media>
      )
    }
}
  
MobileContainer.propTypes = {
  children: PropTypes.node,
  headerActiveTab: PropTypes.string
}

class DesktopContainer extends Component {
    state = {}

    hideFixedMenu = () => this.setState({ fixed: false })
    showFixedMenu = () => this.setState({ fixed: true })

    render() {
        const { children, headerActiveTab, user, onClickLogout, i18n, t, onSwitchLang } = this.props
        const { fixed } = this.state

        return (
        <Media greaterThan='mobile'>
            <Visibility
              once={false}
              onBottomPassed={this.showFixedMenu}
              onBottomPassedReverse={this.hideFixedMenu}
              style={{background: 'linear-gradient(to bottom, white, rgba(255, 255, 255, 0))'}}
            >
            <Segment
                textAlign='center'
                style={{ minHeight: 90, padding: '1em 0em' }}
                vertical
            >
                <Menu
                fixed={fixed ? 'top' : null}
                pointing={!fixed}
                secondary={!fixed}
                size='large'
                >
                <Container>
                    <Menu.Item>
                      <Header color='yellow'>BSF Teams</Header>
                    </Menu.Item>
                    <Menu.Item as={Link} to='/' active={headerActiveTab == 'Home'} >{t('header.homepage')}</Menu.Item>
                    <Menu.Item as={Link} to='/lessons' active={headerActiveTab == 'Lessons'}>{t('header.lessons')}</Menu.Item>
                    <Menu.Item as={Link} to='/passage-discovery-builder/start' active={headerActiveTab == 'Passage Discovery Builder'} >{t('header.passage_discovery_builder')}</Menu.Item>
                    {/* <Menu.Item as={Link} active={headerActiveTab == "Leader's Meeting (Coming Soon)"} disabled>Leader's Meeting (Coming Soon)</Menu.Item> */}
                    {/* <Menu.Item as={Link} to='/my-teams' active={headerActiveTab == 'My Teams'}>{t('header.my_teams')}</Menu.Item> */}
                    <Menu.Item position='right'>
                      <Button onClick={onSwitchLang} color='yellow' style={{margin: '0 1.5em'}}>
                        {
                          i18n.language == 'zh_HK' && <>EN</>
                        }
                        {
                          i18n.language == 'en' && <>繁</>
                        }
                      </Button>
                      {
                        user == undefined?
                        <>
                          <Button as={Link} to='/login'>{t('header.login')}</Button>
                          <Button as={Link} to='/signup' primary={fixed} style={{ marginLeft: '0.5em' }}>{t('header.signup')}</Button>
                        </>
                        :
                        <>
                          <Button onClick={onClickLogout}>{t('header.logout')}</Button>
                          <Button as={Link} to='/profile' style={{backgroundColor: 'transparent', padding: '0 1.5em'}}>
                            <Image src={user && user.avatar && user.avatar.path? user.avatar.path : defaultProfilePic} style={{'height': '35px', 'width': '35px','objectFit': 'cover'}} avatar bordered size="mini"/>
                          </Button>
                        </>
                      }
                    </Menu.Item>
                </Container>
                </Menu>
                {/* <HomepageHeading /> */}
            </Segment>
            </Visibility>

            <Container style={{ "minHeight": window.innerHeight - 195 }}>
                {children}
            </Container>
            <Segment vertical style={{ margin: '0em 0em 0em', padding: '2em 0em' }}>
                <Container textAlign='center'>
                  <Divider />
                  {/* <List horizontal divided link size='small'>
                    <List.Item as='a' href='https://www.mybsf.org/terms-of-use'>
                      Terms and Conditions
                    </List.Item>
                    <List.Item as='a' href='https://www.mybsf.org/privacy-policy'>
                      Privacy Policy
                    </List.Item>
                  </List> */}
                  Copyright © {new Date().getFullYear().toString()} <strong>BSF Teams</strong>
                </Container>
              </Segment>
        </Media>
        )
    }
}

DesktopContainer.propTypes = {
    children: PropTypes.node,
    headerActiveTab: PropTypes.string
}

export const ResponsiveContainer = ({ children, headerActiveTab }) => {
  const { t, i18n } = useTranslation();
  const user = useSelector(state => state.user);
  const history = useHistory();
  const dispatch = useDispatch();
  const dispatchTool = (toDo, ...args) => {
    dispatch(toDo(...args));
  }

  const onClickLogout = () => {
    logout(dispatchTool)
    .then((res) => {
      history.push('/login');
    })
    .catch((err) => {

    } );
  }

  const onSwitchLang = () =>  {
    if (i18n.language == 'zh_HK') {
      i18n.changeLanguage('en');
    } else if (i18n.language == 'en') {
      i18n.changeLanguage('zh_HK');
    }
  }    

  return (
      /* Heads up!
       * For large applications it may not be best option to put all page into these containers at
       * they will be rendered twice for SSR.
       */
      <MediaContextProvider style={{'minHeight': window.innerHeight}}>
        <DesktopContainer headerActiveTab={headerActiveTab} user={user} onClickLogout={onClickLogout} i18n={i18n} t={t} onSwitchLang={onSwitchLang}>{children}</DesktopContainer>
        <MobileContainer headerActiveTab={headerActiveTab} user={user} onClickLogout={onClickLogout} i18n={i18n} t={t} onSwitchLang={onSwitchLang}>{children}</MobileContainer>
      </MediaContextProvider>
  );
}
  
ResponsiveContainer.propTypes = {
    children: PropTypes.node,
    headerActiveTab: PropTypes.string,
}