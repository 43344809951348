import { createMedia } from '@artsy/fresnel';
import React, { useState, useEffect } from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Popup, Message, Grid, Segment, Header, Select, Button, TextArea, List, Divider, Label, Icon, Accordion, Checkbox, Confirm, Sidebar, Modal} from 'semantic-ui-react';
import PropTypes from 'prop-types'
import '../pages/PassageDiscoveryBuilderEditPage.css';
import { makeApiCalls } from '../redux/api';
import { useSelector } from 'react-redux';
import Hotkeys from 'react-hot-keys';
import {PassageDiscoveryBuilderContentListDisplay} from '../components/PassageDiscoveryBuilderContentListDisplay';

// content structure in passage discovery:
// {
//     scopes: [
//         {
//             scope_book: (some book name)
//             scope_book_number: (some book number)
//             scope_from_verse: (some verse key),
//             scope_to_verse: (some verse key),
//         }
//     ],
//     description: (some string)
// }


export const PassageDiscoveryBuilderEditPageContentSection = ({passageDiscovery, setPassageDiscovery, contents, setContents, onSaveFailed, changeTab}) => {
    const { MediaContextProvider, Media } = createMedia({
        breakpoints: {
            mobile: 0,
            tablet: 768,
            computer: 1024,
        },
    })
    
    const user = useSelector(state => state.user);
    // Content related
    const [displayingScopeIndex, setDisplayingScopeIndex] = useState();

    const [selectedBibleVersions, setSelectedBibleVersions] = useState();
    const [contentSelectedFromVerseIndex, setContentSelectedFromVerseIndex] = useState();
    const [contentSelectedToVerseIndex, setContentSelectedToVerseIndex] = useState();
    const [contentDescription, setContentDescription] = useState();
    const [selectedSavedContentIndex, setSelectedSavedContentIndex] = useState();
    const [displayContentConfirmBox, setDisplayContentConfirmBox] = useState(false);
    const [bibleVersionSettingCount, setBibleVersionSettingCount] = useState(0);
    const [expandContentTitle, setExpandContentTitle] = useState(false);

    const [saveContentButtonColor, setSaveContentButtonColor] = useState('yellow');
    const [saveContentButtonText, setSaveContentButtonText] = useState('Save');
    const [saveContentButtonLoading, setSaveContentButtonLoading] = useState(false);

    const [contentRecordOpened, setContentRecordOpened] = useState(false);

    const [isDescriptionBoxOpened, setIsDescriptionBoxOpened] = useState(false);

    const [plumber, setPlumber] = useState(0);

    useEffect(() => {
        setSelectedBibleVersions(Object.keys(passageDiscovery.verses[0]));
        setDisplayingScopeIndex(0);
        setContentSelectedFromVerseIndex(Array(passageDiscovery.scopes.length).fill(undefined));
        setContentSelectedToVerseIndex(Array(passageDiscovery.scopes.length).fill(undefined));
    }, []);

    const getVerseKeys = () => {
        var toReturn = [];

        if (passageDiscovery && passageDiscovery.verses) {
            passageDiscovery.verses.map((scope, index) => {
                toReturn.push(Object.keys(passageDiscovery.verses[index][Object.keys(passageDiscovery.verses[index])[0]]));
            });
        }

        return toReturn;
    }

    const isIncludedInContent = (verseKey) => {
        var included = false;
        var verseKeys = getVerseKeys()[displayingScopeIndex];
        if (verseKeys) {
            var targetIndex = verseKeys.indexOf(verseKey);
            if (contents) {
                contents.map((content, index) => {
                    if (
                        verseKeys.indexOf(content.scopes[displayingScopeIndex].scope_from_verse) <= targetIndex && 
                        verseKeys.indexOf(content.scopes[displayingScopeIndex].scope_to_verse) >= targetIndex
                    ) {
                        included = true;
                    }
                });
            }
            return included;
        }

        return false;
    }

    const onClickBibleVersion = (version) => {
        var tmp = selectedBibleVersions;
        if (selectedBibleVersions.includes(version)) {
            if (selectedBibleVersions.length > 1) {
                var index = tmp.indexOf(version);
                tmp.splice(index, 1);
            }
        } else {
            tmp.push(version);
        }
        setSelectedBibleVersions(tmp);
        var count = bibleVersionSettingCount;
        setBibleVersionSettingCount(count+1);
        
    }

    const onClickContentVerse = (index) => {
        var tmpContentSelectedFromVerseIndex = contentSelectedFromVerseIndex;
        var tmpContentSelectedToVerseIndex = contentSelectedToVerseIndex;
        if (contentSelectedFromVerseIndex[displayingScopeIndex] !== undefined) {
            if (contentSelectedToVerseIndex[displayingScopeIndex] !== undefined) {
                // Case 1: both from and to verses are selected
                // if it is < from verse, update from verse
                // if it is == from verse, set to verse to none
                // if it is > from verse and < to verse, update to verse
                // if it is == to verse, unselect to verse and update to index - 1
                // if it is > to verse, update to verse
                if (index < contentSelectedFromVerseIndex[displayingScopeIndex]) {
                    // setContentSelectedToVerseIndex(index);
                    tmpContentSelectedFromVerseIndex[displayingScopeIndex] = index;
                } else if (index == contentSelectedFromVerseIndex[displayingScopeIndex]) {
                    // setContentSelectedToVerseIndex(undefined);
                    tmpContentSelectedToVerseIndex[displayingScopeIndex] = undefined;
                } else if (index > contentSelectedFromVerseIndex[displayingScopeIndex] && index < contentSelectedToVerseIndex[displayingScopeIndex]) {
                    // setContentSelectedToVerseIndex(index);
                    tmpContentSelectedToVerseIndex[displayingScopeIndex] = index;
                } else if (index == contentSelectedToVerseIndex[displayingScopeIndex]) {
                    // setContentSelectedToVerseIndex(index-1);
                    tmpContentSelectedToVerseIndex[displayingScopeIndex] = index-1;
                } else {
                    // setContentSelectedToVerseIndex(index);
                    tmpContentSelectedToVerseIndex[displayingScopeIndex] = index;
                }
            } else {
                // Case 2: only from verse is selected
                // if it is < from verse, update from verse and set the original from verse as to verse
                // if it is == from verse, set from verse to none
                // if it is > from verse, update to verse
                if (index < contentSelectedFromVerseIndex[displayingScopeIndex]) {
                    // setContentSelectedToVerseIndex(contentSelectedFromVerseIndex);
                    tmpContentSelectedToVerseIndex[displayingScopeIndex] = contentSelectedFromVerseIndex[displayingScopeIndex];
                    // setContentSelectedFromVerseIndex(index);
                    tmpContentSelectedFromVerseIndex[displayingScopeIndex] = index;
                } else if (index == contentSelectedFromVerseIndex[displayingScopeIndex]) {
                    // setContentSelectedFromVerseIndex(undefined);
                    tmpContentSelectedFromVerseIndex[displayingScopeIndex] = undefined;
                } else {
                    // setContentSelectedToVerseIndex(index);
                    tmpContentSelectedToVerseIndex[displayingScopeIndex] = index;
                }

            }
        } else {
            // Case 3: none is ever selected
            // set it to be from verse
            // setContentSelectedFromVerseIndex(index);
            tmpContentSelectedFromVerseIndex[displayingScopeIndex] = index;
        }

        setContentSelectedFromVerseIndex(tmpContentSelectedFromVerseIndex);
        setContentSelectedToVerseIndex(tmpContentSelectedToVerseIndex);
        setPlumber(plumber + 1);
    }

    const onChangeContentDescription = (e, data) => {
        setContentDescription(data.value);
    }

    const submitContentToBackend = (newContent, afterSaveSuccess, afterSaveFailed) => {
        var tmpPD = passageDiscovery;
        tmpPD.content = newContent;
        setPassageDiscovery(tmpPD);
        makeApiCalls(
            [
                {
                    endpoint: (user == undefined? 'PUBLIC_PASSAGE_DISCOVERY_UPDATE': 'MEMBER_PASSAGE_DISCOVERY_UPDATE'),
                    data: tmpPD
                }
            ]
        ).then((res) => {
            afterSaveSuccess(res)
            
        }).catch((err) => {
            afterSaveFailed(err)
        })
    }

    const onClickSaveContent = () => {
        // parse selected content
        var fromVerseIndex = contentSelectedFromVerseIndex;
        var toVerseIndex = contentSelectedToVerseIndex;

        Array(passageDiscovery.scopes).fill(undefined).map((_, index) => {
            if (fromVerseIndex[index] !== undefined) {
                if (toVerseIndex[index] === undefined) {
                    toVerseIndex[index] = fromVerseIndex[index];
                }
            }
        })

        // if there is selectedSavedContentIndex, save to the original
        // if no selectedSavedContentIndex, push in new one
        if (!fromVerseIndex.every(el => el == undefined)) {
            setSaveContentButtonLoading(true);
            var verseKeys = getVerseKeys();
            var tmp = contents.slice();
            
            // construct new content
            var newContent = {
                scopes: [],
                description: contentDescription
            };

            fromVerseIndex.map((fromIndex, index) => {
                newContent.scopes[index] = {
                    scope_book: fromVerseIndex[index] != undefined ? passageDiscovery.scopes[index].scope_book : undefined,
                    scope_book_number: fromVerseIndex[index] != undefined ? passageDiscovery.scopes[index].scope_book_number : undefined,
                    scope_from_verse: verseKeys[index][fromVerseIndex[index]],
                    scope_to_verse: verseKeys[index][toVerseIndex[index]],
                };
            });
            
            // insert new content and sort
            if (selectedSavedContentIndex === undefined) {
                tmp.push(newContent);
                // tmp.sort((a, b) => verseKeys.indexOf(a.scope_from_verse) > verseKeys.indexOf(b.scope_from_verse)? 1 : -1);
                tmp.sort((a, b) => {
                    var aFirstIndex = a.scopes.findIndex(el => el.scope_from_verse != undefined);
                    var bFirstIndex = b.scopes.findIndex(el => el.scope_from_verse != undefined);
                    // assume FirstIndex must be >= 0
                    if (aFirstIndex > bFirstIndex) {
                        return 1;
                    } else if (aFirstIndex < bFirstIndex) {
                        return -1;
                    } else {
                        return verseKeys[aFirstIndex].indexOf(a.scopes[aFirstIndex].scope_from_verse) > verseKeys[bFirstIndex].indexOf(b.scopes[bFirstIndex].scope_from_verse)? 1 : -1;
                    }
                });
            } else {
                tmp[selectedSavedContentIndex] = newContent
            }

            // save content to backend
            submitContentToBackend(
                tmp,
                (res) => {
                    console.log(res);
                    setContents(tmp);
                    setContentSelectedFromVerseIndex(Array(passageDiscovery.scopes.length).fill(undefined));
                    setContentSelectedToVerseIndex(Array(passageDiscovery.scopes.length).fill(undefined));
                    setContentDescription('');
                    setSelectedSavedContentIndex(undefined);

                    setSaveContentButtonLoading(false);
                    setSaveContentButtonColor('green');
                    setSaveContentButtonText('Saved!');
                    setTimeout(() => {
                        setSaveContentButtonColor('yellow');
                        setSaveContentButtonText('Save');
                    }, 2000);
                },
                (err) => {
                    console.log(err);
                    setSaveContentButtonLoading(false);
                    onSaveFailed();
                }
            );        
        }

        // if (fromVerseIndex !== undefined) {
        //     // if to verse is not selected, that means only one verse was selected
        //     // make to verse == from verse
        //     if (toVerseIndex === undefined) {
        //         toVerseIndex = fromVerseIndex;
        //     }
        //     setSaveContentButtonLoading(true);
        //     var verseKeys = getVerseKeys();
        //     var tmp = contents.slice();
        //     var newContent = {
        //         scope_from_verse:verseKeys[fromVerseIndex],
        //         scope_to_verse:verseKeys[toVerseIndex],
        //         description: contentDescription
        //     };
        //     if (selectedSavedContentIndex === undefined) {
        //         console.log('|||', contents);
        //         tmp.push(newContent);
        //         console.log('|||>', contents);
        //         tmp.sort((a, b) => verseKeys.indexOf(a.scope_from_verse) > verseKeys.indexOf(b.scope_from_verse)? 1 : -1);
        //     } else {
        //         tmp[selectedSavedContentIndex] = newContent
        //     }
            
        //     // save content to backend
        //     submitContentToBackend(
        //         tmp,
        //         (res) => {
        //             console.log(res);
        //             setContents(tmp);
        //             setContentSelectedFromVerseIndex(Array(passageDiscovery.scopes.length).fill(undefined));
        //             setContentSelectedToVerseIndex(Array(passageDiscovery.scopes.length).fill(undefined));
        //             setContentDescription('');
        //             setSelectedSavedContentIndex(undefined);

        //             setSaveContentButtonLoading(false);
        //             setSaveContentButtonColor('green');
        //             setSaveContentButtonText('Saved!');
        //             setTimeout(() => {
        //                 setSaveContentButtonColor('yellow');
        //                 setSaveContentButtonText('Save');
        //             }, 2000);
        //         },
        //         (err) => {
        //             console.log(err);
        //             setSaveContentButtonLoading(false);
        //             onSaveFailed();
        //         }
        //     );        
        // } else {
        //     // do nothing
        // }
    }

    const onClickClearContent = () => {
        setDisplayContentConfirmBox(true);
    }

    const onCancelClearContent = () => {
        setDisplayContentConfirmBox(false);
    }

    const onConfirmClearContent = () => {
        // if there is selectedSavedContentIndex, clear the selected saved content
        // if no selectedSavedContentIndex, just clear the current work on content
        if (selectedSavedContentIndex !== undefined) {
            var tmp = contents;
            tmp.splice(selectedSavedContentIndex, 1)
            setContents(tmp);
        }
        setContentSelectedFromVerseIndex(Array(passageDiscovery.scopes.length).fill(undefined));
        setContentSelectedToVerseIndex(Array(passageDiscovery.scopes.length).fill(undefined));
        setContentDescription('');
        setSelectedSavedContentIndex(undefined);
        setDisplayContentConfirmBox(false);

        // save content to backend
        submitContentToBackend(
            tmp,
            (res) => {

            },
            (err) => {

            }
        );
    }

    const onClickSavedContent = (index) => {
        // if there is selectedSavedContentIndex and == index, turn off selection and set undefined
        // if there is selectedSavedContentIndex and != index, update selection
        // if no selectedSavedContentIndex, update selection
        if (
            index != undefined &&
            (selectedSavedContentIndex === undefined || (selectedSavedContentIndex !== undefined && selectedSavedContentIndex != index))
        ) {
            var savedContent = contents[index];
            var firstIndex = savedContent.scopes.findIndex(el => el.scope_from_verse != undefined);
            var verseKeys = getVerseKeys();
            if (savedContent) {
                var tmpContentSelectedFromVerseIndex = contentSelectedFromVerseIndex;
                var tmpContentSelectedToVerseIndex = contentSelectedToVerseIndex;
                savedContent.scopes.map((scope, index) => {
                    tmpContentSelectedFromVerseIndex[index] = verseKeys[index].indexOf(scope.scope_from_verse);
                    tmpContentSelectedToVerseIndex[index] = verseKeys[index].indexOf(scope.scope_to_verse);
                })
                setContentSelectedFromVerseIndex(tmpContentSelectedFromVerseIndex);
                setContentSelectedToVerseIndex(tmpContentSelectedToVerseIndex);
                setContentDescription(savedContent.description);
                setSelectedSavedContentIndex(index);

                if (firstIndex == displayingScopeIndex) {
                    var targetVerse = document.getElementById(`verse-index-${displayingScopeIndex}-${verseKeys.indexOf(savedContent.scope_from_verse)}`);
                    if (targetVerse) {
                        targetVerse.scrollIntoView({behavior:'smooth', block: 'start', inline: "nearest"});
                    }
                }
            }
        } else {
            setContentSelectedFromVerseIndex(Array(passageDiscovery.scopes.length).fill(undefined));
            setContentSelectedToVerseIndex(Array(passageDiscovery.scopes.length).fill(undefined));
            setContentDescription('');
            setSelectedSavedContentIndex(undefined);
        }
    }

    const onClickContentTitle = () => {
        var tmp = expandContentTitle;
        setExpandContentTitle(!tmp);
    }

    const parseVerse = (verse, version) => {
        if (version == 'NIV') {
            return verse.replace(/(<([^>\]]+)>)/gi, "").replace(/(\[([^>\]]+)\])/gi, "").replace("  ", " ");
        } else if (version == 'RCUV') {
            return verse.replace(/(<f>)/gi, '（').replace(/(<\/n>)/gi, '）').replace(/(<([^>\]]+)>)/gi, "");
        }
    }

    const isVerseVisible = function (ele) {
        var container = document.getElementById('verses-container');
        
        const eleTop = ele.offsetTop;
        const eleBottom = eleTop + ele.clientHeight;
    
        const containerTop = container.scrollTop;
        const containerBottom = containerTop + container.clientHeight;
    
        // The element is fully visible in the container
        return (
            (eleTop >= containerTop && eleBottom <= containerBottom)
            // Some part of the element is visible in the container
            // (eleTop < containerTop && containerTop < eleBottom) ||
            // (eleTop < containerBottom && containerBottom < eleBottom)
        );
    };

    const getVerseCont = () => {
        var verseCount = 0;
        Object.keys(passageDiscovery.verses).map((bookNumber) => {
            verseCount += Object.keys(passageDiscovery.verses[bookNumber][selectedBibleVersions[0]]).length;
        });

        return verseCount;
    }

    const getSuggestedDensityMin = () => {
        return Math.round(getVerseCont()/20);
    }

    const getSuggestedDensityMax = () => {
        return Math.round(getVerseCont()/10);
    }

    const getScopeOptions = () => {
        var options = [];

        if (passageDiscovery && passageDiscovery.scopes) {
            passageDiscovery.scopes.map((scope, index) =>{
                options.push( { key: index, value: index, text: `${scope.scope_book} ${scope.scope_from_chapter}:${scope.scope_from_verse} - ${scope.scope_to_chapter}:${scope.scope_to_verse}` },);
            });
        }
        return options;
    }

    const onSelectDisplayingScope = (e, data) => {
        var tmpContentSelectedFromVerseIndex = contentSelectedFromVerseIndex;
        var tmpContentSelectedToVerseIndex = contentSelectedToVerseIndex;
        var tmpDisplayingScopeIndex = displayingScopeIndex;

        tmpContentSelectedFromVerseIndex[tmpDisplayingScopeIndex] = undefined;
        tmpContentSelectedToVerseIndex[tmpDisplayingScopeIndex] = undefined;

        setContentSelectedFromVerseIndex(tmpContentSelectedFromVerseIndex);
        setContentSelectedToVerseIndex(tmpContentSelectedToVerseIndex);
        setDisplayingScopeIndex(data.value);
    }

    return (
        <Hotkeys 
            keyName="ctrl+s,command+s,shift+up,shift+down,ctrl+pageup,ctrl+pagedown,command+pageup,command+pagedown,ctrl+enter,command+enter,ctrl+backspace,command+backspace" 
            filter={(event) => {
                return true;
            }}
            onKeyDown={(keyName, e, handle) => {
                console.log(keyName);
                e.preventDefault();
                if (keyName == 'ctrl+s' || keyName == 'command+s') {
                    onClickSaveContent();
                }
                if (keyName == 'ctrl+backspace' || keyName == 'command+backspace') {
                    onClickSavedContent(undefined);
                }
                if (keyName == 'shift+up') {
                    var indexToBeClicked;
                    if (contentSelectedFromVerseIndex[displayingScopeIndex] == undefined) {
                        if (contents.length > 0 && getVerseKeys().length > 0) {
                            var lastSelectedIndex = getVerseKeys()[displayingScopeIndex].indexOf(contents.filter(item => item.scopes[displayingScopeIndex].scope_from_verse != undefined)[contents.filter(item => item.scopes[displayingScopeIndex].scope_from_verse != undefined).length - 1].scopes[displayingScopeIndex].scope_to_verse);
                            if (lastSelectedIndex < Object.keys(passageDiscovery.verses[displayingScopeIndex][selectedBibleVersions[0]]).length) {
                                indexToBeClicked = lastSelectedIndex + 1;
                            } 
                        } else {
                            indexToBeClicked = 0;
                        }
                    } else {
                        if (contentSelectedToVerseIndex[displayingScopeIndex] == undefined) {
                            if (contentSelectedFromVerseIndex[displayingScopeIndex] > 0) {
                                indexToBeClicked = contentSelectedFromVerseIndex[displayingScopeIndex] - 1;
                            } else {
                                indexToBeClicked = contentSelectedFromVerseIndex[displayingScopeIndex];
                            }
                        } else {
                            if (contentSelectedToVerseIndex[displayingScopeIndex] - 1 >= 0) {
                                indexToBeClicked = contentSelectedToVerseIndex[displayingScopeIndex] - 1;
                            }
                        }
                    }

                    if (indexToBeClicked != undefined) {
                        onClickContentVerse(indexToBeClicked);
                        var target = document.getElementById(`verse-index-${displayingScopeIndex}-${indexToBeClicked}`);
                        if (target && !isVerseVisible(target)) {
                            target.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
                        }
                    }
                }
                if (keyName == 'shift+down') {
                    var indexToBeClicked;
                    if (contentSelectedFromVerseIndex[displayingScopeIndex] == undefined) {
                        if (contents.length > 0 && getVerseKeys().length > 0) {
                            var lastSelectedIndex = getVerseKeys()[displayingScopeIndex].indexOf(contents.filter(item => item.scopes[displayingScopeIndex].scope_from_verse != undefined)[contents.filter(item => item.scopes[displayingScopeIndex].scope_from_verse != undefined).length - 1].scopes[displayingScopeIndex].scope_to_verse);
                            if (lastSelectedIndex < Object.keys(passageDiscovery.verses[displayingScopeIndex][selectedBibleVersions[0]]).length) {
                                indexToBeClicked = lastSelectedIndex + 1;
                            } 
                        } else {
                            indexToBeClicked = 0;
                        }
                    } else {
                        if (contentSelectedToVerseIndex[displayingScopeIndex] == undefined) {
                            if (contentSelectedFromVerseIndex[displayingScopeIndex] + 1 < Object.keys(passageDiscovery.verses[displayingScopeIndex][selectedBibleVersions[0]]).length) {
                                indexToBeClicked = contentSelectedFromVerseIndex[displayingScopeIndex] + 1;
                            }
                        } else {
                            if (contentSelectedToVerseIndex[displayingScopeIndex] + 1 < Object.keys(passageDiscovery.verses[displayingScopeIndex][selectedBibleVersions[0]]).length) {
                                indexToBeClicked = contentSelectedToVerseIndex[displayingScopeIndex] + 1;
                            }
                        }
                    }

                    if (indexToBeClicked != undefined) {
                        onClickContentVerse(indexToBeClicked);
                        var target = document.getElementById(`verse-index-${displayingScopeIndex}-${indexToBeClicked}`);
                        if (target && !isVerseVisible(target)) {
                            target.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
                        }
                    }
                }
                if (keyName == 'ctrl+pageup' || keyName == 'command+pageup') {
                    changeTab(5);
                }
                if (keyName == 'ctrl+pagedown' || keyName == 'command+pagedown') {
                    changeTab(1);
                }
                if (keyName == 'ctrl+enter' || keyName == 'command+enter') {
                    var targets = document.getElementsByClassName('content-edit-desktop-textarea');
                    if (targets.length > 0) {
                        targets[0].focus();
                        targets[0].scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
                    }
                }
            }}
        >  
            <Confirm
                    content="Are you sure to clear the current verses selection / content?"
                    open={displayContentConfirmBox}
                    onCancel={onCancelClearContent}
                    onConfirm={onConfirmClearContent}
            />
            <Accordion>
                <Accordion.Title
                    active={expandContentTitle}
                    index={0}
                    onClick={onClickContentTitle}
                >
                    <Header size="medium"><Icon name='dropdown' />CONTENT – List of Topics or Events</Header>
                </Accordion.Title>
                <Accordion.Content active={expandContentTitle}>
                    <p>
                        This step focuses on the details of the passage and allows God to speak to you personally. Ask yourself “What is this passage about?”
                    </p>
                    <List bulleted>
                        <List.Item>
                        10 to 20 items: Each item may contain one or more verse(s).
                        </List.Item>
                        <List.Item>
                        Write verse numbers next to the item.
                        </List.Item>
                        <List.Item>
                        Think and shrink: Reduce the entire passage to a list of words, phrases and/or sentences that capture the content. Your own style develops over time.
                        </List.Item>
                        <List.Item>
                        Facts only: The goal is to remember the facts. Record what the passage is saying, not your interpretation or conclusion. Using the same words the Bible uses is helpful but not required.
                        </List.Item>
                        <List.Item>
                        Note repeated words/phrases, names, places, conversations, etc.
                        </List.Item>
                    </List>
                </Accordion.Content>
            </Accordion>
            <Divider />
            <Header size="tiny"><Icon color="yellow" name="exclamation triangle" /> Instructions</Header>
            <List bulleted>
                <List.Item>
                Select your desired bible versions by clicking <Label color="black">Bible Versions</Label> below.
                </List.Item>
                <List.Item>
                Select desired <Label basic>Verses</Label> for the scope of the content that you are constructing.
                </List.Item>
                <List.Item>
                Fill in your summary about the content and click <Label color="yellow">Save</Label> when appropriate. The content will be saved to <Label basic style={{'borderTop':"3px solid #fbbd08"}}>Content</Label> at the page bottom.
                </List.Item>
                <List.Item>
                Click <Label>Clear</Label> when you want to drop the current draft of content.
                </List.Item>
                <List.Item>
                Click <Label color="yellow">View Saved Content</Label> to see the saved content. The list of <Label basic style={{'borderTop':"3px solid #fbbd08"}}>Content</Label> will slide in from the right. Click the backdrop to return.
                </List.Item>
                <List.Item>
                If you want to update any of the saved content, click on the desired ones in <Label basic style={{'borderTop':"3px solid #fbbd08"}}>Content</Label>. You can also remove it  during editing by clicking <Label color="black">Delete</Label> at the page bottom.
                </List.Item>
                <List.Item>
                Click on <Label>Clear</Label> if you want to cancel editing.
                </List.Item>
            </List>
            <Divider />
            
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                        {
                            passageDiscovery && passageDiscovery.verses && selectedBibleVersions &&
                            
                            <Header size="medium" textAlign="center" color="yellow">
                                <strong>Suggested Density</strong>
                                <Header.Subheader>
                                    <strong style={{'color': 'black'}}>{getSuggestedDensityMin()} - {getSuggestedDensityMax()}</strong> verses per content for <strong style={{'color': 'black'}}>{getVerseCont()}</strong> verses in total
                                </Header.Subheader>
                            </Header>
                        }
                        <Popup
                            on='click'
                            position="bottom center"
                            pinned
                            trigger={<Button color="black" content='Bible Versions' fluid/>}
                        >
                            <Popup.Content>
                                <List>
                                    {
                                        passageDiscovery && passageDiscovery.verses && Object.keys(passageDiscovery.verses[0]).map((version, index) => (
                                            <List.Item key={index}>
                                                <Checkbox 
                                                    label={version} 
                                                    checked={selectedBibleVersions && selectedBibleVersions.includes(version)}
                                                    onClick={() => onClickBibleVersion(version)}
                                                />
                                            </List.Item>
                                        ))
                                    }
                                </List>
                            </Popup.Content>
                        </Popup>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider />
            <Header size="tiny" textAlign="center">Verses</Header>
            <Button color='yellow' fluid onClick={() => setContentRecordOpened(true)}>View Saved Content</Button>
            <Divider />
            <Sidebar.Pushable>
                <Sidebar
                animation='overlay'
                direction='right'
                onHide={() => setContentRecordOpened(false)}
                vertical
                visible={contentRecordOpened}
                >
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Segment color="yellow">
                                    <Header size="tiny" textAlign="center">Content</Header>
                                    <Divider />
                                    <PassageDiscoveryBuilderContentListDisplay
                                        passageDiscovery={passageDiscovery}
                                        contents={contents}
                                        ordered={true}
                                        selectedSavedContentIndex={selectedSavedContentIndex}
                                        onClickContent={(index) => {onClickSavedContent(index);setContentRecordOpened(false);}}
                                    ></PassageDiscoveryBuilderContentListDisplay>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Sidebar>
    
                <Sidebar.Pusher dimmed={contentRecordOpened}>
                    <Grid>
                        <div 
                            id="verses-container"
                            className="verses-container"
                            style={{
                                'maxHeight': window.innerHeight*0.6, 
                                'minHeight': window.innerHeight*0.2, 
                                'width': '100%'
                            }}
                        >
                            <List divided>
                                {
                                    passageDiscovery && selectedBibleVersions && Object.keys(passageDiscovery.verses[displayingScopeIndex][selectedBibleVersions[0]]).map((key, index) => (
                                        <List.Item 
                                            key={index} 
                                            id={`verse-index-${displayingScopeIndex}-${index}`}
                                            style={{
                                                'minHeight': '3rem', 
                                                'paddingLeft': '6px', 
                                                'paddingTop': '10px', 
                                                'paddingBottom': '10px', 
                                                'borderLeft': (isIncludedInContent(key)?'3px solid black':''),
                                            }} 
                                            className={
                                                contentSelectedFromVerseIndex[displayingScopeIndex] !== undefined?
                                                (
                                                    contentSelectedToVerseIndex[displayingScopeIndex] !== undefined?
                                                    (
                                                        contentSelectedFromVerseIndex[displayingScopeIndex] <= index && contentSelectedToVerseIndex[displayingScopeIndex] >= index ?
                                                        'active-list-item'
                                                        :
                                                        ''
                                                        )
                                                    :
                                                    (
                                                        contentSelectedFromVerseIndex[displayingScopeIndex] == index?
                                                        'active-list-item'
                                                        :
                                                        ''
                                                    )
                                                )
                                                :
                                                ''
                                            }
                                            onClick={() => onClickContentVerse(index)}
                                        >
                                            <List.Header><Label>{key}</Label></List.Header>
                                            <br />
                                            <List.Content key={index}>
                                            {
                                                Object.keys(passageDiscovery.verses[displayingScopeIndex]).map((version, index) => (
                                                    <>
                                                        {
                                                            selectedBibleVersions && selectedBibleVersions.includes(version) &&
                                                            <p>{parseVerse(passageDiscovery.verses[displayingScopeIndex][version][key], version)}</p>
                                                        }
                                                    </>
                                                ))
                                            }
                                            </List.Content>
                                        </List.Item>
                                    ))
                                }
                            </List>
                        </div>
                        
                        <Grid.Row>
                            <Grid.Column width={16}>
                                {
                                    getScopeOptions().length > 1 &&
                                    <Message className="scopes-selector-container">
                                        <span><b>Passages: </b></span>
                                        {
                                            passageDiscovery && passageDiscovery.scopes && passageDiscovery.scopes[displayingScopeIndex] && 
                                            <Select options={getScopeOptions()} value={displayingScopeIndex} onChange={onSelectDisplayingScope}/>
                                        }
                                    </Message>
                                }
                                
                                <Segment className="content-edit-desktop-container">
                                    <Label attached='top'>
                                        <b>Selection:&nbsp;</b> 
                                        {
                                            contentSelectedFromVerseIndex && !contentSelectedFromVerseIndex.every(el => el == undefined) &&
                                            <>
                                                {
                                                    passageDiscovery && passageDiscovery.scopes && passageDiscovery.scopes.map((scope, index) => (
                                                        <>
                                                            {
                                                                contentSelectedFromVerseIndex[index] != undefined &&
                                                                <>{scope.scope_book} {getVerseKeys()[index][contentSelectedFromVerseIndex[index]]} {contentSelectedToVerseIndex[index] ? '-':''} {getVerseKeys()[index][contentSelectedToVerseIndex[index]]}</>
                                                            }
                                                            {
                                                                contentSelectedFromVerseIndex[index] != undefined && contentSelectedFromVerseIndex[index + 1] != undefined &&
                                                                <>, </>
                                                            }
                                                        </>
                                                    ))
                                                }
                                            </>
                                        }
                                    </Label>
                                    <TextArea 
                                        className="content-edit-desktop-textarea"
                                        rows={4}
                                        value={contentDescription}
                                        style={{
                                            'width': '100%',
                                            'border': 'none',
                                            'backgroundColor': 'transparent',
                                            'resize': 'none',
                                            'outline': 'none',
                                            'fontSize': 'max(1em, 16px)'

                                        }}
                                        placeholder='What is this passage about?' 
                                        onChange={onChangeContentDescription}
                                    />
                                </Segment>
                                <Segment className="content-edit-mobile-container">
                                    <Label attached='top'>
                                        <b>Selection:&nbsp;</b>
                                        {
                                            contentSelectedFromVerseIndex && !contentSelectedFromVerseIndex.every(el => el == undefined) &&
                                            <>
                                                {
                                                    passageDiscovery && passageDiscovery.scopes && passageDiscovery.scopes.map((scope, index) => (
                                                        <>
                                                            {
                                                                contentSelectedFromVerseIndex[index] != undefined &&
                                                                <>{scope.scope_book} {getVerseKeys()[index][contentSelectedFromVerseIndex[index]]} {contentSelectedToVerseIndex[index] ? '-':''} {getVerseKeys()[index][contentSelectedToVerseIndex[index]]}</>
                                                            }
                                                            {
                                                                contentSelectedFromVerseIndex[index] != undefined && contentSelectedFromVerseIndex[index + 1] != undefined &&
                                                                <>, </>
                                                            }
                                                        </>
                                                    ))
                                                }
                                            </>
                                        }
                                    </Label>
                                    <TextArea 
                                        className="content-edit-mobile-textarea"
                                        rows={4}
                                        value={contentDescription}
                                        style={{
                                            'width': '100%',
                                            'border': 'none',
                                            'backgroundColor': 'transparent',
                                            'resize': 'none',
                                            'outline': 'none',
                                            'fontSize': 'max(1em, 16px)'

                                        }}
                                        placeholder='What is this passage about?' 
                                        onChange={onChangeContentDescription}
                                    />
                                    <Modal
                                        onOpen={() => setIsDescriptionBoxOpened(true)}
                                        open={isDescriptionBoxOpened}
                                        trigger={
                                            <Icon className="content-edit-mobile-button" name="expand arrows alternate" />
                                        }
                                    >
                                        <Modal.Header>What is this passage about?</Modal.Header>
                                        <Modal.Content>
                                            <Segment>
                                                <TextArea
                                                    id="mobile-content-modal-textarea" 
                                                    rows={4}
                                                    defaultValue={contentDescription}
                                                    style={{
                                                        'width': '100%',
                                                        'border': 'none',
                                                        'backgroundColor': 'transparent',
                                                        'resize': 'none',
                                                        'outline': 'none',
                                                        'fontSize': 'max(1em, 16px)'

                                                    }}
                                                    placeholder='What is this passage about?' 
                                                />
                                            </Segment>
                                        </Modal.Content>
                                        <Modal.Actions>
                                            <Button 
                                                color='yellow' 
                                                onClick={(e) => {
                                                var textarea = document.getElementById("mobile-content-modal-textarea" );
                                                if (textarea) {
                                                    onChangeContentDescription(e, textarea);
                                                }
                                                setIsDescriptionBoxOpened(false);
                                            }}>
                                                Continue
                                            </Button>
                                        </Modal.Actions>
                                    </Modal>
                                </Segment>
                                {
                                    (
                                        (contentDescription != undefined && contentDescription != null && contentDescription != '') || 
                                        contentSelectedFromVerseIndex != undefined && !contentSelectedFromVerseIndex.every(el => el == undefined)
                                    ) &&
                                    <p style={{'color': 'red'}}><Icon name="warning circle"/>You have not saved yet.</p>
                                }
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Button.Group fluid>
                                    <Button onClick={() => onClickSavedContent(undefined)}>Cancel</Button>
                                    <Button.Or />
                                    <Button color={saveContentButtonColor} onClick={onClickSaveContent} loading={saveContentButtonLoading}>{saveContentButtonText}</Button>
                                </Button.Group>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Button fluid color='black' onClick={onClickClearContent}>Delete</Button>
                            </Grid.Column>
                        </Grid.Row>
                        
                    </Grid>
                </Sidebar.Pusher>
            </Sidebar.Pushable>
            <Divider />
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Segment color="yellow">
                            <Header size="tiny" textAlign="center">Saved Content</Header>
                            <Divider />
                            <PassageDiscoveryBuilderContentListDisplay
                                passageDiscovery={passageDiscovery}
                                contents={contents}
                                ordered={true}
                                selectedSavedContentIndex={selectedSavedContentIndex}
                                onClickContent={onClickSavedContent}
                            ></PassageDiscoveryBuilderContentListDisplay>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            
        </Hotkeys>
    );
};

PassageDiscoveryBuilderEditPageContentSection.propTypes = {
    passageDiscovery: PropTypes.object,
    setPassageDiscovery: PropTypes.func,
    contents: PropTypes.array,
    setContents: PropTypes.func,
    onSaveFailed: PropTypes.func
}
