import { makeApiCalls } from "../redux/api";
import { setUser } from "../redux/actions";

export function getUserProfile(
    dispatchTool=null
) {
    return makeApiCalls(
        [
            {
                endpoint: 'MEMBER_GET_USER_PROFILE',
            }
        ],
    ).then((response) => {
        if (dispatchTool) {
            dispatchTool(setUser, response[0].data.user);
        }
        return response;
    });
}

export function updateUser(
    user,
    dispatchTool=null
) {
    return makeApiCalls(
        [
            {
                endpoint: 'MEMBER_UPDATE_USER',
                data: user
            }
        ],
    )
    .then((response) => {
        if (dispatchTool && response[0].data.id) {
            dispatchTool(setUser, response[0].data);
        }
    });
}

export function updateUserAvatar(
    avatarData,
) {
    return makeApiCalls(
        [
            {
                endpoint: 'MEMBER_UPDATE_USER_AVATAR',
                data: {
                    'avatar': avatarData
                }
            }
        ],
    );
}