import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import 'semantic-ui-css/semantic.min.css';
import { Message, Confirm, Popup, Container, Dimmer, Loader, Grid, Segment, Header, Button, Dropdown, TextArea, Image, List, Progress, Divider, Label, Icon, Tab, Input, Accordion, Checkbox, Rating, Card, Modal} from 'semantic-ui-react';
import { ResponsiveContainer } from '../containers/ResponsiveContainer';
import PropTypes from 'prop-types';
import { makeApiCalls } from '../redux/api';
import './PassageDiscoveryBuilderEditPage.css';
import {PassageDiscoveryOutputDisplayComponent} from '../components/PassageDiscoveryOutputDisplayComponent';
import { PassageDiscoveryBuilderEditPageContentSection } from '../components/PassageDiscoveryBuilderEditPageContentSection';
import { PassageDiscoveryBuilderEditPageDivisionSection } from '../components/PassageDiscoveryBuilderEditPageDivisionSection';
import { PassageDiscoveryBuilderEditPageSubjectSentenceSection } from '../components/PassageDiscoveryBuilderEditPageSubjectSentenceSection';
import { PassageDiscoveryBuilderEditPageAimSection } from '../components/PassageDiscoveryBuilderEditPageAimSection';
import { PassageDiscoveryBuilderEditPageApplicationSection } from '../components/PassageDiscoveryBuilderEditPageApplicationSection';
import { getUser } from '../services/authenticate';
import { getUserProfile } from '../services/user';
import { setSiteFontSize } from '../redux/actions';
import { createMedia } from '@artsy/fresnel';
import Hotkeys from 'react-hot-keys';
import { getWordCount } from '../services/system';
import { downloadAsDocx } from '../services/passagediscoveries';
import {PassageDiscoveryScopeForm} from '../components/PassageDiscoveryScopeForm';
import buddyJesus from '../assets/images/buddy_jesus.png';

export const PassageDiscoveryBuilderEditPage = ({match}) => {
    const { MediaContextProvider, Media } = createMedia({
        breakpoints: {
            mobile: 0,
            tablet: 768,
            computer: 1024,
        },
    })

    const user = useSelector(state =>
        state.user
    );
    const siteFontSize = useSelector(state =>
        state.siteFontSize
    );
    const dispatch = useDispatch();
    const history = useHistory();
    const [displayLoader, setDisplayLoader] = useState(false);
    const [passageDiscovery, setPassageDiscovery] = useState();
    const [displaySaveFailureConfirmBox, setDisplaySaveFailureConfirmBox] = useState(false);

    // scope edit modal
    const [displayScopeEditModal, setDisplayScopeEditModal] = useState(false);

    // copy button
    const [copyButtonColor, setCopyButtonColor] = useState('yellow');
    const [copyButtonIcon, setCopyButtonIcon] = useState('copy');
    const [copyButtonText, setCopyButtonText] = useState('Copy');

    // email submission accordion
    const [expandEmailSubmissionTitle, setExpandEmailSubmissionTitle] = useState(false);

    // email submission button
    const [emailSubmissionButtonColor, setEmailSubmissionButtonColor] = useState('yellow');
    const [emailSubmissionButtonText, setEmailSubmissionButtonText] = useState('Submit');
    const [emailSubmissionButtonLoading, setEmailSubmissionButtonLoading] = useState(false);

    // copy output accordion
    const [expandCopyOutputTitle, setExpandCopyOutputTitle] = useState(false);

    const [activeTabIndex, setActiveTabIndex] = useState(0);

    // Content related
    const [contents, setContents] = useState();

    // Division Related
    const [divisions, setDivisions] = useState();
    
    // Subject Sentence Related
    const [subjectSentenceDescription, setSubjectSentenceDescription] = useState();
    const [subjectSentenceDescriptionWordCount, setSubjectSentenceDescriptionWordCount] = useState(0);
    
    // Aim Related
    const [aimDescription, setAimDescription] = useState();

    // Application Related
    const [applications, setApplications] = useState();
    
    // LM related
    const [leadersMeetingsOptions, setLeadersMeetingsOptions] = useState();
    const [selectedLeadersMeetingsId, setSelectedLeadersMeetingsId] = useState();

    // shortcut menu related
    const [displayShortcutMenu, setDisplayShortcutMenu] = useState(false);

    // For updating scope
    const [selectedScopes, setSelectedScopes] = useState([]);
    const [displayUpdateScopeLoader, setDisplayUpdateScopeLoader] = useState(false);
    const [scopeFormErrorMessage, setScopeFormErrorMessage] = useState();
    
    // For encouragement
    const [displayEncouragement, setDisplayEncouragement] = useState(false);

    const version = 'NIV';

    const dispatchTool = (toDo, ...args) => {
        dispatch(toDo(...args));
    }

    useEffect(() => {
        getUserProfile(dispatchTool)
        .then((response) => {
            makeApiCalls([
                {
                    endpoint: 'MEMBER_PASSAGE_DISCOVERY_GET',
                    slugFragments: {
                        passage_discovery_code: match.params.code
                    }
                },
                {
                    endpoint: 'MEMBER_LEADERS_MEETINGS_GET_BY_USER',
                }
            ]).then((res) => {
                // passage discovery
                if (res[0].data.id) {
                    setPassageDiscovery(res[0].data);
                    setSelectedScopes(res[0].data.scopes);
                    
                    // setup contents
                    if (res[0].data.content === undefined || res[0].data.content === null) {
                        setContents([]);
                    } else {
                        setContents(res[0].data.content);
                    }
                    
                    // setup divisions
                    if (res[0].data.division === undefined || res[0].data.division === null) {
                        setDivisions([]);
                    } else {
                        setDivisions(res[0].data.division);
                    }
                    
                    // setup subject sentence
                    setSubjectSentenceDescription(res[0].data.subject_sentence);
                    if (res[0].data.subject_sentence) {
                        setSubjectSentenceDescriptionWordCount(getWordCount(res[0].data.subject_sentence));
                    }
        
                    // set up aim
                    setAimDescription(res[0].data.aim);
        
                    // set up application 
                    if (res[0].data.application === undefined || res[0].data.application === null) {
                        setApplications([]);
                    } else {
                        setApplications(res[0].data.application);
                    }

                    // set encouragement
                    var verseCount = 0;
                    Object.keys(res[0].data.verses).map((bookNumber) => {
                        verseCount += Object.keys(res[0].data.verses[bookNumber][Object.keys(res[0].data.verses[0])[0]]).length;
                    });
                    if (verseCount > 100) { // magic number
                        setDisplayEncouragement(true);
                    }
                } else {
                    // invalid passage discovery, bring to start page
                    history.push('/passage-discovery-builder/start');
                }

                // leaders meetings
                if (res[1].data) {
                    var tmpLeadersMeetingsOptions = [];
                    Object.keys(res[1].data).map((lmId) => {
                        tmpLeadersMeetingsOptions.push({
                            key: lmId,
                            text: res[1].data[lmId],
                            value: lmId,
                        });
                    })

                    // http response force json to sort by key ascendingly, therefore needs to reverse
                    tmpLeadersMeetingsOptions.reverse();
                    setLeadersMeetingsOptions(tmpLeadersMeetingsOptions);
                }
            }).catch((err) => {
    
            });
        })
        .catch((err) => {
            makeApiCalls([
                {
                    endpoint: 'PUBLIC_PASSAGE_DISCOVERY_GET',
                    slugFragments: {
                        passage_discovery_code: match.params.code
                    }
                }
            ]).then((res) => {
                if (res[0].data.id) {
                    setPassageDiscovery(res[0].data);
                    
                    // setup contents
                    if (res[0].data.content === undefined || res[0].data.content === null) {
                        setContents([]);
                    } else {
                        setContents(res[0].data.content);
                    }
                    
                    // setup divisions
                    if (res[0].data.division === undefined || res[0].data.division === null) {
                        setDivisions([]);
                    } else {
                        setDivisions(res[0].data.division);
                    }
                    
                    // setup subject sentence
                    setSubjectSentenceDescription(res[0].data.subject_sentence);
        
                    // set up aim
                    setAimDescription(res[0].data.aim);
        
                    // set up application 
                    if (res[0].data.application === undefined || res[0].data.application === null) {
                        setApplications([]);
                    } else {
                        setApplications(res[0].data.application);
                    }
                } else {
                    // invalid passage discovery, bring to start page
                    history.push('/passage-discovery-builder/start');
                }
    
            }).catch((err) => {
    
            });
        })
    }, []);

    const changeTab = (index) => {
        var tabElms = document.getElementById('edit-page-tab').getElementsByClassName("ui wrapped menu");
        if (tabElms.length > 0) {
            var tabElm = tabElms[0];
            tabElm.children[index].click();
        }
    }

    const onSaveFailed = () => {
        setDisplaySaveFailureConfirmBox(true);
    }

    const onClickCopyUrl = (url) => {
        // copy
        const el = document.createElement('textarea');
        el.value = url;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        // change button color
        setCopyButtonColor('green');
        setCopyButtonIcon('check');
        setCopyButtonText('Copied!');
        setTimeout(() => {
            setCopyButtonColor('yellow');
            setCopyButtonIcon('copy');
            setCopyButtonText('Copy');
        }, 2000)

    }
        
    /////// Submit Related ////////
    
    const onClickEmailSubmissionTitle = () => {
        var tmp = expandEmailSubmissionTitle;
        setExpandEmailSubmissionTitle(!tmp);
    }

    const onChangeSelectedLeadersMeetingsOption = (event, data) => {
        setSelectedLeadersMeetingsId(data.value);
    }

    const onClickEmailSubmission = () => {
        setEmailSubmissionButtonLoading(true);
        makeApiCalls(
            [
                {
                    endpoint: 'MEMBER_PASSAGE_DISCOVERY_EMAIL_SUBMISSION',
                    data: {
                        'passage_discovery_id': passageDiscovery.id,
                        'leaders_meeting_id': selectedLeadersMeetingsId,
                    }
                }
            ]
        ).then((res) => {
            console.log('email submission success', res);
            setEmailSubmissionButtonLoading(false);
            setEmailSubmissionButtonColor('green');
            setEmailSubmissionButtonText('Submitted!');
            setTimeout(() => {
                setEmailSubmissionButtonColor('yellow');
                setEmailSubmissionButtonText('Submit');
            }, 2000);
        })
        .catch((err) => {
            setEmailSubmissionButtonLoading(false);
        });
    }

    const onClickCopyOutputTitle = () => {
        var tmp = expandCopyOutputTitle;
        setExpandCopyOutputTitle(!tmp);
    }

    const onSiteFontSizeChange = (event) => {
        dispatch(setSiteFontSize(event.target.value));
    }

    const onClickEditScope = () => {
        setDisplayScopeEditModal(true);
    }

    const onClickDownloadAsDocx = () => {
        downloadAsDocx(passageDiscovery);
    }

    const onClickUpdatePassageDiscoveryScope = () => {
        var tmpPD = passageDiscovery;
        tmpPD.scopes = selectedScopes;

        setDisplayUpdateScopeLoader(true);
        makeApiCalls(
            [
                {
                    endpoint: (user == undefined? 'PUBLIC_PASSAGE_DISCOVERY_UPDATE': 'MEMBER_PASSAGE_DISCOVERY_UPDATE'),
                    data: tmpPD
                }
            ]
        ).then((res) => {
            window.location.reload();
        }).catch((err) => {
            setDisplayScopeEditModal(false);
            setDisplayUpdateScopeLoader(false);
        })
        
    }

    return (
        <ResponsiveContainer
            headerActiveTab="Passage Discovery Builder"
        >
            <Hotkeys 
                keyName="ctrl+shift+h,command+shift+h" 
                filter={(event) => {
                    return true;
                }}
                onKeyDown={(keyName, e, handle) => {
                    e.preventDefault();
                    if (keyName == 'ctrl+shift+h' || keyName == 'command+shift+h') {
                        setDisplayShortcutMenu(!displayShortcutMenu);
                    }
                }}
            >  
            </Hotkeys>
            <Modal
                onClose={() => setDisplayEncouragement(false)}
                onOpen={() => setDisplayEncouragement(true)}
                open={displayEncouragement}
            >
                <Modal.Content image style={{gap: '20px'}}>
                    <div style={{maxWidth: '500px', minHeight: '200px', display: 'flex', justifyContent:'center', alignItems: 'center', flexDirection: 'column'}}>
                        <p>It's a long passage... </p>
                        <p style={{fontSize: '18px'}}><b>but you will receive power when the Holy Spirit has come upon you; and you shall be My witnesses both in Jerusalem, and in all Judea and Samaria, and even to the remotest part of the earth.</b></p>
                        <p><b>Acts 1:8</b></p>
                    </div>
                    <Image size='medium' src={buddyJesus} wrapped style={{flex: '1 1 auto'}} />
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content="I believe in you Jesus"
                        labelPosition='right'
                        icon='checkmark'
                        onClick={() => setDisplayEncouragement(false)}
                        positive
                    />
                </Modal.Actions>
            </Modal>
            <Container>
                <MediaContextProvider>
                    <Media greaterThan='mobile'>
                        <i className="snes-jp-logo shortcut-menu-button" onClick={() => setDisplayShortcutMenu(!displayShortcutMenu)}></i>
                        <div className={`short-menu-container ${displayShortcutMenu ? '' : 'hide'} nes-container with-title`}>
                            <p className="title">Shortcut Menu</p>
                            <label className="nes-btn close-button" onClick={() => setDisplayShortcutMenu(false)}>
                                <i className="nes-icon close is-small"></i>
                            </label>
                            <br></br>
                            <br></br>
                            <br></br>
                            <div className="short-menu-content-container">
                                <div className="item">
                                    <span className="nes-text">Select previous / next verse or content</span>
                                    <div>
                                        <label className="nes-btn">shift</label>
                                        <label className="nes-btn">↑ / ↓</label>
                                    </div>
                                </div>
                                <div className="item">
                                    <span className="nes-text">Jump to text box</span>
                                    <div>
                                        <label className="nes-btn">ctrl / cmd</label>
                                        <label className="nes-btn">Enter ↵ </label>
                                    </div>
                                </div>
                                <div className="item">
                                    <span className="nes-text">Insert Proper Noun</span>
                                    <div>
                                        <label className="nes-btn">ctrl / cmd</label>
                                        <label className="nes-btn">shift</label>
                                        <label className="nes-btn">L</label>
                                    </div>
                                </div>
                                <div className="item">
                                    <span className="nes-text">Save</span>
                                    <div>
                                        <label className="nes-btn">ctrl / cmd</label>
                                        <label className="nes-btn">S</label>
                                    </div>
                                </div>
                                <div className="item">
                                    <span className="nes-text">Cancel</span>
                                    <div>
                                        <label className="nes-btn">ctrl / cmd</label>
                                        <label className="nes-btn">backspace</label>
                                    </div>
                                </div>
                                <div className="item">
                                    <span className="nes-text">Jump to previous / next part</span>
                                    <div>
                                        <label className="nes-btn">ctrl / cmd</label>
                                        <label className="nes-btn">page up / page down</label>
                                    </div>
                                </div>
                                <div className="item">
                                    <span className="nes-text">Open / Close this Menu</span>
                                    <div>
                                        <label className="nes-btn">ctrl / cmd</label>
                                        <label className="nes-btn">shift</label>
                                        <label className="nes-btn">H</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Media>
                </MediaContextProvider>
                <Confirm
                        content="Save Failed. Please try again. You might also need to refresh the page."
                        open={displaySaveFailureConfirmBox}
                        onCancel={() => setDisplaySaveFailureConfirmBox(false)}
                        onConfirm={() => setDisplaySaveFailureConfirmBox(false)}
                />
                <Dimmer active={displayLoader}>
                    <Loader>Loading</Loader>
                </Dimmer>
                {
                    passageDiscovery &&
                    <Grid>
                        {
                            passageDiscovery && 
                            <Grid.Row>
                                <Grid.Column width={16} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                    <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                                        <Header size="large" style={{margin: '0'}}>
                                            {
                                                passageDiscovery.scopes.map((scope, index) => (
                                                    <>
                                                        <span>
                                                            {scope.scope_book + ' ' + scope.scope_from_chapter + ':' + scope.scope_from_verse + ' - ' + scope.scope_to_chapter + ':' + scope.scope_to_verse}
                                                            {
                                                                index + 1 < passageDiscovery.scopes.length && <>, </>
                                                            }
                                                        </span>
                                                    </>
                                                ))
                                            }
                                        </Header>
                                        <Modal
                                            onClose={() => setDisplayScopeEditModal(false)}
                                            open={displayScopeEditModal}
                                            trigger={<Icon name="edit" onClick={onClickEditScope} className="edit-scope-button"/>}
                                        >
                                            <Modal.Header>Edit Scope</Modal.Header>
                                            <Modal.Content>
                                                <PassageDiscoveryScopeForm
                                                    selectedScopes={selectedScopes}
                                                    setSelectedScopes={setSelectedScopes}

                                                    submitButtonText="Update"
                                                    onClickSubmitButton={onClickUpdatePassageDiscoveryScope}

                                                    errorMessage={scopeFormErrorMessage}

                                                    bibleVersion={version}

                                                    displayLoader={displayUpdateScopeLoader}
                                                ></PassageDiscoveryScopeForm>
                                            </Modal.Content>
                                        </Modal>
                                    </div>
                                    <Popup
                                        on='click'
                                        position="bottom right"
                                        pinned
                                        trigger={
                                            <Button style={{fontSize: '1.2em', padding: '0.5em 1em'}}>
                                                <Icon name='font' />
                                            </Button>
                                        }
                                    >
                                        <Popup.Content>
                                           <div className="passage-discovery-edit-page-font-size-control-container">
                                                <div className="passage-discovery-edit-page-font-size-indicator">
                                                    <p style={{fontSize: '14px', margin: 0}}>A</p>
                                                    <p style={{fontSize: '30px', margin: 0}}>A</p>
                                                </div>
                                                <div className="passage-discovery-edit-page-font-size-range-container">
                                                    <input type="range" min="14" max="30" value={siteFontSize} onChange={onSiteFontSizeChange}/>
                                                </div>
                                           </div>
                                        </Popup.Content>
                                    </Popup>
                                </Grid.Column>
                            </Grid.Row>
                        }

                        {
                            !(user && user.id && user.email) &&
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <Message>
                                        <Message.Header>If you do not have an account, copy and keep this URL for later edits or <a href='/signup'>Sign up here</a>.</Message.Header>
                                        <Message.Content>
                                            <br></br>
                                            <Input 
                                                action={{
                                                    color: copyButtonColor,
                                                    labelPosition: 'right',
                                                    icon: copyButtonIcon,
                                                    content: copyButtonText,
                                                    onClick: () => onClickCopyUrl(window.location)
                                                }} 
                                                style={{'width': '100%'}} 
                                                placeholder={window.location} 
                                            />
                                        </Message.Content>
                                    </Message>
                                </Grid.Column>
                            </Grid.Row>
                        }
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Tab
                                    id='edit-page-tab'
                                    onTabChange={(event, data) => setActiveTabIndex(data.activeIndex)}
                                    menu={{className: "wrapped"}} 
                                    panes={
                                        [
                                            // Content Panel
                                            { menuItem: 'Content', render: () => 
                                                <Tab.Pane
                                                    data-tab='content'
                                                >
                                                    <PassageDiscoveryBuilderEditPageContentSection 
                                                        passageDiscovery={passageDiscovery}
                                                        setPassageDiscovery={setPassageDiscovery}
                                                        contents={contents}
                                                        setContents={setContents}
                                                        onSaveFailed={onSaveFailed}
                                                        changeTab={changeTab}
                                                    />
                                                </Tab.Pane> 
                                            },

                                            // Division
                                            { menuItem: 'Divisions', render: () => 
                                                <Tab.Pane>
                                                    <PassageDiscoveryBuilderEditPageDivisionSection 
                                                        passageDiscovery={passageDiscovery}
                                                        setPassageDiscovery={setPassageDiscovery}
                                                        contents={contents}
                                                        divisions={divisions}
                                                        setDivisions={setDivisions}
                                                        applications={applications}
                                                        setApplications={setApplications}
                                                        onSaveFailed={onSaveFailed}
                                                        changeTab={changeTab}
                                                    />
                                                </Tab.Pane> 
                                            },

                                            // Subject Sentence
                                            { menuItem: 'Subject Sentence', render: () => 
                                                <Tab.Pane>
                                                    <PassageDiscoveryBuilderEditPageSubjectSentenceSection 
                                                        passageDiscovery={passageDiscovery}
                                                        setPassageDiscovery={setPassageDiscovery}
                                                        divisions={divisions}
                                                        subjectSentenceDescription={subjectSentenceDescription}
                                                        setSubjectSentenceDescription={setSubjectSentenceDescription}
                                                        subjectSentenceDescriptionWordCount={subjectSentenceDescriptionWordCount}
                                                        setSubjectSentenceDescriptionWordCount={setSubjectSentenceDescriptionWordCount}
                                                        onSaveFailed={onSaveFailed}
                                                        changeTab={changeTab}
                                                    />
                                                </Tab.Pane> 
                                            },

                                            // Aim
                                            { menuItem: 'Aim', render: () => 
                                                <Tab.Pane>
                                                    <PassageDiscoveryBuilderEditPageAimSection 
                                                        passageDiscovery={passageDiscovery}
                                                        setPassageDiscovery={setPassageDiscovery}
                                                        subjectSentenceDescription={subjectSentenceDescription}
                                                        subjectSentenceDescriptionWordCount={subjectSentenceDescriptionWordCount}
                                                        setSubjectSentenceDescriptionWordCount={setSubjectSentenceDescriptionWordCount}
                                                        aimDescription={aimDescription}
                                                        setAimDescription={setAimDescription}
                                                        onSaveFailed={onSaveFailed}
                                                        changeTab={changeTab}
                                                    />
                                                </Tab.Pane> 
                                            },

                                            // Application
                                            { menuItem: 'Application', render: () => 
                                                <Tab.Pane>
                                                    <PassageDiscoveryBuilderEditPageApplicationSection 
                                                        passageDiscovery={passageDiscovery}
                                                        setPassageDiscovery={setPassageDiscovery}
                                                        divisions={divisions}
                                                        aimDescription={aimDescription}
                                                        applications={applications}
                                                        setApplications={setApplications}
                                                        onSaveFailed={onSaveFailed}
                                                        changeTab={changeTab}
                                                    />
                                                </Tab.Pane> 
                                            },

                                            // Preview aka Output
                                            { menuItem: 'Submit', render: () => 
                                                <Tab.Pane>
                                                    <Hotkeys 
                                                        keyName="ctrl+pageup,command+pageup,ctrl+pagedown,command+pagedown" 
                                                        filter={(event) => {
                                                            return true;
                                                        }}
                                                        onKeyDown={(keyName, e, handle) => {
                                                            e.preventDefault();
                                                            if (keyName == 'ctrl+pageup' || keyName == 'command+pageup') {
                                                                changeTab(4);
                                                            }
                                                            if (keyName == 'ctrl+pagedown' || keyName == 'command+pagedown') {
                                                                changeTab(0);
                                                            }
                                                        }}
                                                    >  
                                                        {
                                                            leadersMeetingsOptions && Object.keys(leadersMeetingsOptions).length > 0 &&
                                                            <>
                                                                <Accordion>
                                                                    <Accordion.Title
                                                                        active={expandEmailSubmissionTitle}
                                                                        index={0}
                                                                        onClick={onClickEmailSubmissionTitle}
                                                                    >
                                                                        <Header size="medium"><Icon name='dropdown' />EMAIL SUBMISSION</Header>
                                                                    </Accordion.Title>
                                                                    <Accordion.Content active={expandEmailSubmissionTitle}>
                                                                        <Segment>
                                                                            <Dropdown
                                                                                placeholder='Select Reminder to reply to'
                                                                                fluid
                                                                                search
                                                                                selection
                                                                                options={leadersMeetingsOptions}
                                                                                onChange={onChangeSelectedLeadersMeetingsOption}
                                                                            />
                                                                            <br></br>
                                                                            <Button color={emailSubmissionButtonColor} onClick={onClickEmailSubmission} loading={emailSubmissionButtonLoading} fluid>{emailSubmissionButtonText}</Button>
                                                                        </Segment>
                                                                    </Accordion.Content>
                                                                </Accordion>
                                                                <Divider />
                                                            </>
                                                        }
                                                        <Accordion>
                                                            <Accordion.Title
                                                                active={expandCopyOutputTitle}
                                                                index={0}
                                                                onClick={onClickCopyOutputTitle}
                                                            >
                                                                <Header size="medium"><Icon name='dropdown' />COPY OUTPUT</Header>
                                                            </Accordion.Title>
                                                            <Accordion.Content active={expandCopyOutputTitle}>
                                                                <Button fluid onClick={onClickDownloadAsDocx}><Icon name="file word"/>Download as Word Document</Button>
                                                                <br></br>
                                                                <Segment style={{margin: '0 -1em -1em -1em'}}>
                                                                    <PassageDiscoveryOutputDisplayComponent
                                                                        passageDiscovery={passageDiscovery}
                                                                        contents={contents}
                                                                        divisions={divisions}
                                                                        subjectSentenceDescription={subjectSentenceDescription}
                                                                        subjectSentenceDescriptionWordCount={subjectSentenceDescriptionWordCount}
                                                                        aimDescription={aimDescription}
                                                                        applications={applications}
                                                                    />
                                                                </Segment>
                                                            </Accordion.Content>
                                                        </Accordion>
                                                    </Hotkeys>
                                                </Tab.Pane>
                                            }
                                        ]
                                    } 
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={8}>
                                {
                                    activeTabIndex == 1 &&
                                    <Button fluid onClick={() => changeTab(0)} content='Content' icon='left arrow' size='big' labelPosition='left' compact={true} style={{'textAlign': 'right', 'paddingRight': '0.5em !important'}}/>
                                }
                                {
                                    activeTabIndex == 2 &&
                                    <Button fluid onClick={() => changeTab(1)} content='Division' icon='left arrow' size='big' labelPosition='left' compact={true} style={{'textAlign': 'right', 'paddingRight': '0.5em !important'}}/>
                                }
                                {
                                    activeTabIndex == 3 &&
                                    <Button fluid onClick={() => changeTab(2)} content='Subject Sentence' icon='left arrow' size='big' labelPosition='left' compact={true} style={{'textAlign': 'right', 'paddingRight': '0.5em !important'}}/>
                                }
                                {
                                    activeTabIndex == 4 &&
                                    <Button fluid onClick={() => changeTab(3)} content='Aim' icon='left arrow' size='big' labelPosition='left' compact={true} style={{'textAlign': 'right', 'paddingRight': '0.5em !important'}}/>
                                }
                                {
                                    activeTabIndex == 5 &&
                                    <Button fluid onClick={() => changeTab(4)} content='Application' icon='left arrow' size='big' labelPosition='left' compact={true} style={{'textAlign': 'right', 'paddingRight': '0.5em !important'}}/>
                                }
                            </Grid.Column>
                            <Grid.Column width={8}>
                                {
                                    activeTabIndex == 0 &&
                                    <Button fluid onClick={() => changeTab(1)} content='Division' icon='right arrow' size='big' labelPosition='right' compact={true} style={{'textAlign': 'left'}}/>
                                }
                                {
                                    activeTabIndex == 1 &&
                                    <Button fluid onClick={() => changeTab(2)} content='Subject Sentence' icon='right arrow' size='big' labelPosition='right' compact={true} style={{'textAlign': 'left'}}/>
                                }
                                {
                                    activeTabIndex == 2 &&
                                    <Button fluid onClick={() => changeTab(3)} content='Aim' icon='right arrow' size='big' labelPosition='right' compact={true} style={{'textAlign': 'left'}}/>
                                }
                                {
                                    activeTabIndex == 3 &&
                                    <Button fluid onClick={() => changeTab(4)} content='Application' icon='right arrow' size='big' labelPosition='right' compact={true} style={{'textAlign': 'left'}}/>
                                }
                                {
                                    activeTabIndex == 4 &&
                                    <Button fluid onClick={() => changeTab(5)} content='Submit' icon='right arrow' size='big' labelPosition='right' compact={true} style={{'textAlign': 'left'}}/>
                                }
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                }
            </Container>
        </ResponsiveContainer>
    );
};

PassageDiscoveryBuilderEditPage.propTypes = {
    match: PropTypes.object.isRequired,
}