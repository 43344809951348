import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import 'semantic-ui-css/semantic.min.css';
import { Dimmer, Loader, Popup, Form, Container, Message, Grid, Segment, Header, Button, TextArea, Image, List, Progress, Divider, Label, Icon, Tab, Input, Accordion, Checkbox, Rating, Card} from 'semantic-ui-react';
import { ResponsiveContainer } from '../containers/ResponsiveContainer';
import { useForm } from "react-hook-form";
import { makeApiCalls } from '../redux/api';

export const SignupPage = () => {
    const user = useSelector(state =>
        state.user
    );
    const dispatch = useDispatch();
    const history = useHistory();
    const {register, handleSubmit, getValues} = useForm();
    const [emailIsValid, setEmailIsValid] = useState();
    const [passwordIsValid, setPasswordIsValid] = useState();
    const [displayRegistrationSuccessMessage, setDisplayRegistrationSuccessMessage] = useState(false);
    const [displayRegistrationFailedMessage, setDisplayRegistrationFailedMessage] = useState(false);
    const [displayLoader, setDisplayLoader] = useState(false);

    const validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const onChangeEmail = (event) => {
        setEmailIsValid(validateEmail(event.target.value));
    }

    const onChangePassword = (event) => {
        setPasswordIsValid(
            event.target.value == getValues('password_confirmation') && 
            event.target.value.length > 7
        );
    }
    
    const onChangePasswordConfirmation = (event) => {
        setPasswordIsValid(
            event.target.value == getValues('password')&& 
            event.target.value.length > 7
        );
    }

    const onSignup = (formData) => {
        // display loader
        setDisplayLoader(true);

        // reset sign up messages
        setDisplayRegistrationSuccessMessage(false);
        setDisplayRegistrationFailedMessage(false);

        // register
        makeApiCalls(
            [
                {
                    endpoint: 'AUTH_REGISTER',
                    data: {
                        "username": formData.email,
                        "email": formData.email,
                        "password": formData.password,
                        "password_confirmation": formData.password,
                        "name": formData.name,
                        "surname": formData.surname
                    }
                }
            ],
        )
        .then((res) => {
            setDisplayLoader(false);
            setDisplayRegistrationSuccessMessage(true);
            setTimeout(() => {
                history.push('/login');
            }, 3000);
        })
        .catch((err) => {
            setDisplayLoader(false);
            setDisplayRegistrationFailedMessage(true);
        });
    }

    return (
        <ResponsiveContainer
            headerActiveTab="Sign up"
        >
            <Container>
                <Dimmer active={displayLoader}>
                    <Loader>Loading</Loader>
                </Dimmer>
                {
                    displayRegistrationSuccessMessage &&
                    <>
                        <Message icon size='large' color="green">
                            <Icon name='check' />
                            <Message.Content>
                                <Message.Header>You have successfully signed up.</Message.Header>
                                Please check your mailbox for activation email to activate your account.
                            </Message.Content>
                        </Message>
                    </> 
                }
                {
                    displayRegistrationFailedMessage && 
                    <Message icon size='large'  color="red">
                        <Icon name='times' />
                        <Message.Content>
                            <Message.Header>Sign up failed.</Message.Header>
                            Please try again.
                        </Message.Content>
                    </Message>
                }
                <Message size='large' attached>
                    <Message.Header>Join us!</Message.Header>
                    <Message.Content>
                        Whether a leader of a BSF class or interested to serve, create an account to join us and gain access to the tools.
                    </Message.Content>
                </Message>

                <Form className='attached fluid segment' onSubmit={handleSubmit(onSignup)}>
                    <Form.Group widths='equal'>
                        <Form.Field width={8}>
                            <label htmlFor="name">First Name</label>
                            <input type="text" name="name" id="name" ref={register}/>
                        </Form.Field>
                        <Form.Field width={8}>
                            <label htmlFor="surname">Last Name</label>
                            <input type="text" name="surname" id="surname" ref={register}/>
                        </Form.Field>
                    </Form.Group>
                    <Form.Field width={16} className={emailIsValid==false?'error':''}>
                        <label htmlFor="email">Email {emailIsValid == true && <Icon color="green" name='check' />}</label>
                        {
                            emailIsValid==false && 
                            <Message color="red">
                                <Message.List items={[
                                    'Please enter a valid email.',
                                ]} />
                            </Message>
                        }
                        <input type="email" name="email" id="email" ref={register} onChange={onChangeEmail}/>
                    </Form.Field>
                    <Form.Group widths='equal'>
                        <Form.Field width={8} className={passwordIsValid==false?'error':''}>
                            <label htmlFor="password">Password {passwordIsValid == true && <Icon color="green" name='check' />}</label>
                            {
                                passwordIsValid == false &&
                                <Message color="red">
                                    <Message.List items={[
                                        'Please make sure the passwords match.',
                                        'Please choose a password longer than 8 characters.'
                                    ]} />
                                </Message>
                            }
                            <input type="password" name="password" id="password" ref={register} onChange={onChangePassword}/>
                        </Form.Field>
                        <Form.Field width={8} className={passwordIsValid==false?'error':''}>
                            <label htmlFor="password_confirmation">Confirm Password {passwordIsValid == true && <Icon color="green" name='check' />}</label>
                            {
                                passwordIsValid == false &&
                                <Message color="red">
                                    <Message.List items={[
                                        'Please make sure the passwords match.',
                                        'Please choose a password longer than 8 characters.'
                                    ]} />
                                </Message>
                            }
                            <input type="password" name="password_confirmation" id="password_confirmation" ref={register} onChange={onChangePasswordConfirmation}/>
                        </Form.Field>
                    </Form.Group>
                    {/* <Form.Checkbox inline label='I agree to the terms and conditions' /> */}
                    <Button color="yellow" fluid className={(emailIsValid == true && passwordIsValid == true) ? '' : 'disabled'}>Create Account</Button>
                </Form>

                <Message attached='bottom' warning>
                    <Icon name='help' />
                    Already have an account?&nbsp;<a href='/login'>Log in here</a>&nbsp;instead.
                </Message>
            </Container>
            
        </ResponsiveContainer>
    );
};