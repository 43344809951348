import axios, { AxiosResponse } from 'axios';
import { getJwtToken } from '../services/authenticate';
// import { getJwtToken } from '../services/authentication';

// const API_HOST = process.env.REACT_APP_API_HOST || '';
const API_HOST = process.env.REACT_APP_API_HOST || "http://127.0.0.1:8080";
const METHOD_POST = 'post';
const METHOD_GET = 'get';
const METHOD_PATCH = 'patch';
export const exceptionStatusCodes = [400, 404, 500, 504];
const API_LIST = {
    PUBLIC_BIBLE_GET_BOOK_LIST: {
        method: METHOD_POST,
        url: '/api/public/bible/list/books',
        jwt_auth: false,
    },
    PUBLIC_BIBLE_GET_CHAPTER_LIST: {
        method: METHOD_POST,
        url: '/api/public/bible/list/chapters',
        jwt_auth: false,
    },
    PUBLIC_BIBLE_GET_VERSE_LIST: {
        method: METHOD_POST,
        url: '/api/public/bible/list/verses',
        jwt_auth: false,
    },

    PUBLIC_PASSAGE_DISCOVERY_CREATE: {
        method: METHOD_POST,
        url: '/api/public/passage-discovery/create',
        jwt_auth: false,
    },
    PUBLIC_PASSAGE_DISCOVERY_UPDATE: {
        method: METHOD_POST,
        url: '/api/public/passage-discovery/update',
        jwt_auth: false,
    },
    PUBLIC_PASSAGE_DISCOVERY_GET: {
        method: METHOD_GET,
        url: '/api/public/passage-discovery/{passage_discovery_code}',
        jwt_auth: false,
    }, 
    PUBLIC_LESSON_LIST_GET: {
        method: METHOD_GET,
        url: '/api/public/lesson/list',
        jwt_auth: false,
    }, 
    PUBLIC_LESSON_GET: {
        method: METHOD_GET,
        url: '/api/public/lesson/detail/{lesson_code}',
        jwt_auth: false,
    }, 

    PUBLIC_PASSAGE_DISCOVERY_GET: {
        method: METHOD_GET,
        url: '/api/public/passage-discovery/{passage_discovery_code}',
        jwt_auth: false,
    }, 

    MEMBER_PASSAGE_DISCOVERY_LIST: {
        method: METHOD_GET,
        url: '/api/member/passage-discovery',
        jwt_auth: true,
    }, 
    MEMBER_PASSAGE_DISCOVERY_CREATE: {
        method: METHOD_POST,
        url: '/api/member/passage-discovery/create',
        jwt_auth: true,
    },
    MEMBER_PASSAGE_DISCOVERY_UPDATE: {
        method: METHOD_POST,
        url: '/api/member/passage-discovery/update',
        jwt_auth: true,
    },
    MEMBER_PASSAGE_DISCOVERY_GET: {
        method: METHOD_GET,
        url: '/api/member/passage-discovery/{passage_discovery_code}',
        jwt_auth: true,
    }, 
    MEMBER_PASSAGE_DISCOVERY_EMAIL_SUBMISSION: {
        method: METHOD_POST,
        url: '/api/member/passage-discovery/email-submission',
        jwt_auth: true,
    }, 

    MEMBER_LEADERS_MEETINGS_GET_BY_USER: {
        method: METHOD_GET,
        url: '/api/member/leaders-meeting',
        jwt_auth: true,
    }, 


    MEMBER_GET_USER_PROFILE : {
        method: METHOD_GET,
        url: '/api/member/user/profile',
        jwt_auth: true,
    },
    MEMBER_UPDATE_USER : {
        method: METHOD_POST,
        url: '/api/member/user/update',
        jwt_auth: true,
    },
    MEMBER_UPDATE_USER_AVATAR : {
        method: METHOD_POST,
        url: '/api/member/user/avatar/update',
        jwt_auth: true,
    },
    
    AUTH_REGISTER : {
        method: METHOD_POST,
        url: '/api/auth/register',
        jwt_auth: false,
    },
    AUTH_ACCOUNT_ACTIVATION : {
        method: METHOD_POST,
        url: '/api/auth/account-activation',
        jwt_auth: false,
    },
    AUTH_LOGIN_WITH_EMAIL : {
        method: METHOD_POST,
        url: '/api/auth/login',
        jwt_auth: false,
    },
    AUTH_GET_USER : {
        method: METHOD_GET,
        url: '/api/auth/me',
        jwt_auth: true,
    },
    AUTH_FORGOT_PASSWORD : {
        method: METHOD_POST,
        url: '/api/auth/forgot-password',
        jwt_auth: true,
    },
    AUTH_RESET_PASSWORD : {
        method: METHOD_POST,
        url: '/api/auth/reset-password',
        jwt_auth: true,
    },
    AUTH_LOGOUT : {
        method: METHOD_POST,
        url: '/api/auth/logout',
        jwt_auth: true,
    }
};

export function makeApiCalls(apiList){
    var calls = [];
    // getJwtToken((token) => {
        apiList.forEach(function (call){
            // prepare api variables
            var apiInfo = API_LIST[call.endpoint];
            var apiData = call.data;
            var apiParam = call.params;
            var apiHeaders = apiInfo.headers || {};
            var apiEndpoint = API_HOST + apiInfo.url;

            if (call.slugFragments) {
                for (let key in call.slugFragments) {
                    let value = call.slugFragments[key];
                    apiEndpoint = apiEndpoint.replace('{' + key + '}', value);
                }
            }

            if (apiInfo.jwt_auth) {
                apiHeaders = Object.assign({}, apiHeaders, {'Authorization':'Bearer ' + getJwtToken()});
            }

            // append api call
            if (apiInfo.method == METHOD_POST) {
                calls.push(
                    axios.post(apiEndpoint, apiData, {headers: apiHeaders})
                );
            } else if (API_LIST[call.endpoint].method == METHOD_GET) {
                calls.push(
                    axios.get(apiEndpoint, {headers: apiHeaders, params: apiParam})
                );
            } else if (apiInfo.method == METHOD_PATCH) {
                calls.push(
                    axios.patch(apiEndpoint, apiData, {headers: apiHeaders})
                );
            }
        });
        return axios.all(calls);
    // });
}