import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import 'semantic-ui-css/semantic.min.css';
import { Dimmer, Loader, Popup, Form, Container, Message, Grid, Segment, Header, Button, TextArea, Image, List, Progress, Divider, Label, Icon, Tab, Input, Accordion, Checkbox, Rating, Card} from 'semantic-ui-react';
import { ResponsiveContainer } from '../containers/ResponsiveContainer';
import { useForm } from "react-hook-form";
import defaultProfilePic from '../assets/images/default_profile_pic.png';
import { updateUser, updateUserAvatar } from '../services/user';
import './ProfilePage.css';
import { getUserProfile } from '../services/user';

export const ProfilePage = () => {
    const user = useSelector(state =>
        state.user
    );
    const dispatch = useDispatch();
    const dispatchTool = (toDo, ...args) => {
        dispatch(toDo(...args));
    }
    const history = useHistory();
    const {register, handleSubmit, getValues} = useForm();
    const [displayLoader, setDisplayLoader] = useState(false);
    const [displayProfileSavedMessage, setDisplayProfileSavedMessage] = useState(false);
    const [displayAvatarFileTooLargeAlert, setDisplayAvatarFileTooLargeAlert] = useState(false);

    useEffect(() => {
        console.log('profile effect', user);
        if (!(user && user.id)) {
            history.push('/');
        }
    }, [user])

    const onSave = (formData) => {
        console.log('formData', formData.name);
        // display loader
        setDisplayLoader(true);

        // save profile
        updateUser(
            formData
        )
        .then((res) => {
            setDisplayLoader(false);
            setDisplayProfileSavedMessage(true);
            setTimeout(() => {
                setDisplayProfileSavedMessage(false);
            }, 3000);
        })
        .catch((err) => {
            setDisplayLoader(false);
            console.log('err', err);
        });
    }

    const onClickAvatar = () => {
        var element = document.getElementById('avatar-input');
        if (element) {
            element.click();
        }
    }

    const updateAvatar =  (avatar) => {
        var file_size = 0;   
        if (avatar){      
            //check uploaded file size
            file_size = avatar[0].size;
            // 10MB
            if (file_size > 10506316){
                setDisplayAvatarFileTooLargeAlert(true);
            } else {
                try {
                    readUploadedFileAsText(avatar[0])
                    .then((fileContents) => {
                        updateUserAvatar(
                            fileContents,
                        )
                        .then(() => {
                            getUserProfile(dispatchTool);
                        });
                    });    

                } catch (e) {
                    console.warn(e.message)
                }
            }
        }
    }

    const readUploadedFileAsText = (inputFile) => {
        const reader = new FileReader();
      
        return new Promise((resolve, reject) => {
            function resizeMe(img) {
  
                var canvas = document.createElement('canvas');
              
                var max_width = 1024;
                var max_height = 1024;

                var width = img.width;
                var height = img.height;
              
                // calculate the width and height, constraining the proportions
                if (width > height) {
                  if (width > max_width) {
                    //height *= max_width / width;
                    height = Math.round(height *= max_width / width);
                    width = max_width;
                  }
                } else {
                  if (height > max_height) {
                    //width *= max_height / height;
                    width = Math.round(width *= max_height / height);
                    height = max_height;
                  }
                }
                
                // resize the canvas and draw the image data into it
                canvas.width = width;
                canvas.height = height;
                var ctx = canvas.getContext("2d");
                if (ctx) {
                    ctx.drawImage(img, 0, 0, width, height);
                }
                
                // preview.appendChild(canvas); 
                
                var imageUrl = canvas.toDataURL("image/jpeg"); // get the data from canvas as 70% JPG (can be also PNG, etc.)
                return imageUrl.replace(/^data:image\/(png|jpg);base64,/, "");
            }
            reader.onerror = () => {
                reader.abort();
                reject(new DOMException("Problem parsing input file."));
            };
      
            reader.onload = function (event) {
                // blob stuff
                if (event.target && event.target.result) {
                    var blob = new Blob([event.target.result]); // create blob...
                    window.URL = window.URL || window.webkitURL;
                    var blobURL = window.URL.createObjectURL(blob); // and get it's URL

                    // helper Image object
                    var image = document.createElement("img");
                    image.src = blobURL;
                    //preview.appendChild(image); // preview commented out, I am using the canvas instead
                    image.onload = function() {
                        // have to wait till it's loaded
                        var resized = resizeMe(image); // send it to canvas
                        resolve(resized);
                    }
                }
            };

            reader.readAsArrayBuffer(inputFile);
        });
    };

    return (
        <>
            {
                user && user.id &&
                <ResponsiveContainer>
                    <Container>
                        <Dimmer active={displayLoader}>
                            <Loader>Loading</Loader>
                        </Dimmer>
                        {
                            displayProfileSavedMessage &&
                            <Message icon size='large' color="green">
                                <Icon name='check' />
                                <Message.Content>
                                    <Message.Header>Profile Saved!</Message.Header>
                                </Message.Content>
                            </Message>
                        }
                        <Message size='large' attached>
                            <Message.Header>Profile</Message.Header>
                            {/* <Message.Content>
                                Whether a leader of a BSF class or interested to serve, create an account to join us and gain access to the tools.
                            </Message.Content> */}
                        </Message>
                        <Form className='attached fluid segment' onSubmit={handleSubmit(onSave)}>
                            <div className="profile-page-avatar-upload-form-field">
                                <input type="file" onChange={e => updateAvatar(e.target.files)} id="avatar-input" accept="image/png, image/jpeg" style={{'display': 'none'}} />
                                <Image src={user && user.avatar && user.avatar.path? user.avatar.path : defaultProfilePic} className="profile-page-avatar-display" avatar bordered size="small" onClick={onClickAvatar}/>
                            </div>
                            {
                                (
                                    user && user.bsf_classes && user.bsf_classes.length > 0 ||
                                    user && user.groups && user.groups.length > 0 
                                ) &&
                                <Message>
                                    <Message.Content style={{textAlign: 'center'}}>
                                        <Message.Header style={{textAlign: 'center'}}>
                                            {user.bsf_classes[0] && user.bsf_classes[0].name ? user.bsf_classes[0].name: ''}
                                        </Message.Header>
                                        {user.groups[0] && user.groups[0].name ? user.groups[0].name: ''}
                                    </Message.Content>
                                </Message>
                            }
                            <Form.Group widths='equal'>
                                <Form.Field width={8}>
                                    <label htmlFor="name">First Name</label>
                                    <input 
                                        type="text" 
                                        name="name" 
                                        id="name" 
                                        defaultValue={user && user.name? user.name : ''}
                                        ref={register}
                                    />
                                </Form.Field>
                                <Form.Field width={8}>
                                    <label htmlFor="surname">Last Name</label>
                                    <input 
                                        type="text" 
                                        name="surname" 
                                        id="surname" 
                                        defaultValue={user && user.surname? user.surname : ''}
                                        ref={register}
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Field width={16}>
                                <label htmlFor="email">Email</label>
                                <Input type="email" name="email" id="email" defaultValue={user && user.email? user.email : ''} disabled/>
                            </Form.Field>
                            {/* <Form.Checkbox inline label='I agree to the terms and conditions' /> */}
                            <Button color="yellow" fluid>Save</Button>
                        </Form>
                    </Container>
                </ResponsiveContainer>
            }
        </>
    );
};