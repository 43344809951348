import React, { useState } from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Header, List, Label} from 'semantic-ui-react';
import PropTypes from 'prop-types'

export const PassageDiscoveryBuilderContentListDisplay = ({
    passageDiscovery, 
    contents,
    ordered,
    displayPrettyIndex,
    selectedSavedContentIndex,
    onClickContent,
}) => {

  return (
    <List divided ordered={ordered}>
        {
            contents && contents.length == 0 &&
            <Header size="tiny">No content created yet.</Header>
        }
        {
            contents && contents.map((content, index) => (
                <List.Item
                    className={selectedSavedContentIndex!= undefined && selectedSavedContentIndex == index? 'active-list-item':''}
                    key={index}
                    onClick={() => {
                        if (onClickContent) {
                            onClickContent(index)
                        }
                    }}
                >
                    <List.Header>
                        {
                            displayPrettyIndex &&
                            <>
                                <Label basic>
                                    {index+1}
                                </Label>
                                <span style={{'opacity': '0'}}>. </span>
                            </>
                        }
                        <Label>
                        {
                            passageDiscovery && passageDiscovery.scopes && passageDiscovery.scopes.map((scope, index) => (
                                <>
                                    {
                                        content.scopes[index].scope_from_verse && content.scopes[index].scope_to_verse &&
                                        <>{content.scopes[index].scope_book} {content.scopes[index].scope_from_verse} - {content.scopes[index].scope_to_verse}</>
                                    }
                                    {
                                        content.scopes[index] && content.scopes[index].scope_from_verse != undefined && content.scopes[index + 1] && content.scopes[index + 1].scope_from_verse != undefined &&
                                        <>, </>
                                    }
                                </>
                            ))
                        }
                        </Label>
                    </List.Header>
                    <br />
                    <List.Content>{content.description}</List.Content>
                    <br />
                </List.Item>
            ))
        }
    </List>
  );
};

PassageDiscoveryBuilderContentListDisplay.propTypes = {
    passageDiscovery: PropTypes.object,
    contents: PropTypes.array,
    ordered: PropTypes.bool,
    onClickContent: PropTypes.func,
    getContentListItemClassName: PropTypes.func,
}
