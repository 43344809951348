export const getWordCount = (string) => {
    // count proper noun
    var pattern = /<div class="ui label" contenteditable="true" placeholder="Proper Noun">.*?<\/div>/g;
    var properNouns = string.match(pattern);

    // the rest
    const tempElement = document.createElement('div');
    tempElement.innerHTML = string;
    var stringWithoutPunctuation = string.replace(/<div class="ui label" contenteditable="true" placeholder="Proper Noun">.*?<\/div>/g,"") // remove all div
                                .split("&nbsp;").join(" ")
                                .split("'s").join("")
                                .split("‘s").join("")
                                .split(";").join(" ")
                                .split(":").join(" ")
                                .split(".").join(" ")
                                .split("!").join(" ")
                                .split("?").join(" ")
                                .split("~").join(" ")
                                .split("`").join(" ")
                                .split("'").join("")
                                .split('"').join(" ")
                                .split('|').join(" ")
                                .split('｜').join(" ")
                                .split(",").join(" ")
                                .split("，").join(" ")
                                .split("。").join(" ")
                                .split("、").join(" ")
                                .split("！").join(" ")
                                .split("？").join(" ")
                                .split("～").join(" ")
                                .split("-").join("")
                                .split("》").join(" ")
                                .split("《").join(" ")
                                .split("【").join(" ")
                                .split("】").join(" ")
                                .split("「").join(" ")
                                .split("」").join(" ");
    var stringWithoutChinese = stringWithoutPunctuation.replace(/[^\x00-\x7F]/g, "");
    var chineseWordCount = stringWithoutPunctuation.length - stringWithoutChinese.length;
    var englishWordCount = stringWithoutChinese
                            .split(" ")
                            .filter(function(item){
                                return item !== "";
                            })
                            .length;
    return chineseWordCount + englishWordCount + (properNouns ? properNouns.length : 0);
}

export const parseHtmlStringToFullText = (string) => {
  return string.replace(/(<([^>]+)>)/gi, "").split("&nbsp;").join(" ").replace(/  +/g, ' ');
}

export function getCaretPosition(editableDiv) {
    var caretPos = 0,
      sel, range;
    if (window.getSelection) {
      sel = window.getSelection();
      if (sel.rangeCount) {
        range = sel.getRangeAt(0);
        console.log('rnage', range);
        if (range.commonAncestorContainer.parentNode == editableDiv) {
          caretPos = range.endOffset;
        }
      }
    } else if (document.selection && document.selection.createRange) {
      range = document.selection.createRange();
      if (range.parentElement() == editableDiv) {
        var tempEl = document.createElement("span");
        editableDiv.insertBefore(tempEl, editableDiv.firstChild);
        var tempRange = range.duplicate();
        tempRange.moveToElementText(tempEl);
        tempRange.setEndPoint("EndToEnd", range);
        caretPos = tempRange.text.length;
      }
    }
    return caretPos;
  }