import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import 'semantic-ui-css/semantic.min.css';
import { Pagination, Table, Dimmer, Search, Loader, Form, Comment, Select, Grid, Segment, Header, Button, TextArea, Image, List, Progress, Divider, Label, Icon, Tab, Input, Accordion, Checkbox, Rating, Card, Container, Dropdown} from 'semantic-ui-react';
import { ResponsiveContainer } from '../containers/ResponsiveContainer';
import { makeApiCalls } from '../redux/api';
import './MyTeamsPage.css';
import { getUser } from '../services/authenticate';
import { getUserProfile } from '../services/user';
import {PassageDiscoveryScopeForm} from '../components/PassageDiscoveryScopeForm';

  

export const MyTeamsPage = () => {
    const user = useSelector(state =>
        state.user
    );
    const dispatch = useDispatch();
    const history = useHistory();

    const dispatchTool = (toDo, ...args) => {
        dispatch(toDo(...args));
    }

    // copy button
    const [copyButtonColor, setCopyButtonColor] = useState('yellow');
    const [copyButtonIcon, setCopyButtonIcon] = useState('copy');
    const [copyButtonText, setCopyButtonText] = useState('Copy');

    const [displayLoader, setDisplayLoader] = useState(false);
    
    const [leaderSearchLoading, setLeaderSearchLoading] = useState(false);
    const [leaderSearchResult, setLeaderSearchResult] = useState([]);
    const [leaderSearchValue, setLeaderSearchValue] = useState('');


    useEffect(() => {
        // check for user
        // setDisplayLoader(true);
        getUserProfile(dispatchTool)
        .then((res) => {
        })
        .catch((err) => {
            // do sth
        });

        // makeApiCalls(
        //     [
        //         {
        //             endpoint: 'MEMBER_PASSAGE_DISCOVERY_LIST'
        //         },
        //     ]
        // ).then((res) => {
        //     setPassageDiscoveries(res[0].data.passage_discoveries);
        //     setTotalPages(res[0].data.total_pages);
        //     // setDisplayLoader(false);
        // }).catch((err) => {
        //     // setDisplayLoader(false);
        // });
    }, []);

    const onPageChange = (event, data) => {
        // console.log(event);
        // console.log(data);
        // makeApiCalls(
        //     [
        //         {
        //             endpoint: 'MEMBER_PASSAGE_DISCOVERY_LIST',
        //             params: {
        //                 'page': data.activePage
        //             }
        //         },
        //     ]
        // ).then((res) => {
        //     setCurrentPage(data.activePage);
        //     setPassageDiscoveries(res[0].data.passage_discoveries);
        //     // setTotalPages(res[0].data.total_pages);
        // }).catch((err) => {

        // });
    }

    const onClickCopyUrl = (url) => {
        // copy
        const el = document.createElement('textarea');
        el.value = url;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        // change button color
        setCopyButtonColor('green');
        setCopyButtonIcon('check');
        setCopyButtonText('Copied!');
        setTimeout(() => {
            setCopyButtonColor('yellow');
            setCopyButtonIcon('copy');
            setCopyButtonText('Copy');
        }, 2000)

    }

    const onLeaderSearchChange = () => {

    }

    const onLeaderResultSelect = () => {

    }

    return (
        <ResponsiveContainer
            headerActiveTab="Class Management"
        >
            <Container>
                <Dimmer active={displayLoader}>
                    <Loader>Loading</Loader>
                </Dimmer>
                <Header as='h2' attached='top'>
                    KYA - HK
                </Header>
                <Segment attached>
                    <Form>
                        <Form.Field>
                            <label>Bot Email</label>
                            <Input icon='at' iconPosition='left' value='kya.hk@bsf-teams.com' 
                                action={{
                                    color: copyButtonColor,
                                    labelPosition: 'right',
                                    icon: copyButtonIcon,
                                    content: copyButtonText,
                                    onClick: () => onClickCopyUrl('kya.hk@bsf-teams.com')
                                }} 
                            />
                        </Form.Field>
                        {/* <Form.Field>
                            <label>Last Name</label>
                            <input placeholder='Last Name' />
                        </Form.Field> */}
                    </Form>
                </Segment>
                <br></br>
                <Tab panes={[
                    { menuItem: 'Leaders', render: () => 
                        <Tab.Pane>
                            <Search
                                loading={leaderSearchLoading}
                                placeholder='Search...'
                                onResultSelect={onLeaderResultSelect}
                                onSearchChange={onLeaderSearchChange}
                                results={leaderSearchResult}
                                value={leaderSearchValue}
                            />
                            <List>
                                <List.Item>
                                    <Image avatar src='/images/avatar/small/rachel.png' />
                                    <List.Content>
                                        <List.Header as='a'>Rachel</List.Header>
                                        <List.Description>
                                        Last seen watching{' '}
                                        <a>
                                            <b>Arrested Development</b>
                                        </a>{' '}
                                        just now.
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                                <List.Item>
                                    <Image avatar src='/images/avatar/small/lindsay.png' />
                                    <List.Content>
                                        <List.Header as='a'>Lindsay</List.Header>
                                        <List.Description>
                                        Last seen watching{' '}
                                        <a>
                                            <b>Bob's Burgers</b>
                                        </a>{' '}
                                        10 hours ago.
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                                <List.Item>
                                    <Image avatar src='/images/avatar/small/matthew.png' />
                                    <List.Content>
                                        <List.Header as='a'>Matthew</List.Header>
                                        <List.Description>
                                        Last seen watching{' '}
                                        <a>
                                            <b>The Godfather Part 2</b>
                                        </a>{' '}
                                        yesterday.
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                                <List.Item>
                                    <Image avatar src='/images/avatar/small/jenny.jpg' />
                                    <List.Content>
                                        <List.Header as='a'>Jenny Hess</List.Header>
                                        <List.Description>
                                        Last seen watching{' '}
                                        <a>
                                            <b>Twin Peaks</b>
                                        </a>{' '}
                                        3 days ago.
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                                <List.Item>
                                    <Image avatar src='/images/avatar/small/veronika.jpg' />
                                    <List.Content>
                                        <List.Header as='a'>Veronika Ossi</List.Header>
                                        <List.Description>Has not watched anything recently</List.Description>
                                    </List.Content>
                                </List.Item>
                            </List>
                        </Tab.Pane> 
                    },
                ]} />
            </Container>
            
        </ResponsiveContainer>
    );
};