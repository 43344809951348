import React, { useState } from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Header, List, Label} from 'semantic-ui-react';
import PropTypes from 'prop-types'

export const PassageDiscoveryBuilderDivisionListDisplay = ({
    passageDiscovery, 
    divisions,
    ordered,
    displayPrettyIndex,
    selectedSavedDivisionIndex,
    onClickDivision,
}) => {

  return (
    <List divided ordered={ordered}>
        {
            divisions && divisions.length == 0 &&
            <Header size="tiny">No divisions created yet.</Header>
        }
        {
            divisions && divisions.map((division, index) => (
                <List.Item
                    className={selectedSavedDivisionIndex != undefined && selectedSavedDivisionIndex == index? 'active-list-item':''}
                    key={index} 
                    onClick={() => {
                        if (onClickDivision) {
                            onClickDivision(index)
                        }
                    }}
                >
                    <List.Header>
                        {
                            displayPrettyIndex &&
                            <>
                                <Label basic>
                                    {index+1}
                                </Label>
                                <span style={{'opacity': '0'}}>. </span>
                            </>
                        }
                        <Label>
                        {
                            passageDiscovery && passageDiscovery.scopes && passageDiscovery.scopes.map((scope, index) => (
                                <>
                                    {
                                        division.scopes[index].scope_from_verse && division.scopes[index].scope_to_verse &&
                                        <>{division.scopes[index].scope_book} {division.scopes[index].scope_from_verse} - {division.scopes[index].scope_to_verse}</>
                                    }
                                    {
                                        division.scopes[index] != undefined && division.scopes[index].scope_from_verse != undefined && division.scopes[index + 1] && division.scopes[index + 1].scope_from_verse != undefined &&
                                        <>, </>
                                    }
                                </>
                            ))
                        }
                        </Label>
                    </List.Header>
                    <br/>
                    <List.Content>{division.description}</List.Content>
                    <br/>
                </List.Item>
            ))
        }
    </List>
  );
};

PassageDiscoveryBuilderDivisionListDisplay.propTypes = {
    passageDiscovery: PropTypes.object,
    contents: PropTypes.array,
    ordered: PropTypes.bool,
    onClickContent: PropTypes.func,
    getContentListItemClassName: PropTypes.func,
}
