import React, { useEffect, useState } from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Dropdown, Grid, Segment, Message, Dimmer, Loader, Header, Button, TextArea, Image, List, Progress, Divider, Label, Icon, Tab, Input, Accordion, Checkbox, Rating, Card} from 'semantic-ui-react';
import PropTypes from 'prop-types'
import { makeApiCalls } from '../redux/api';

export const PassageDiscoveryScopeForm = ({
    selectedScopes,
    setSelectedScopes,

    submitButtonText,
    onClickSubmitButton,

    errorMessage,

    bibleVersion,

    displayLoader,
}) => {
    const [bookOptions, setBookOptions] = useState([]);
    const [chapterOptions, setChapterOptions] = useState([]);
    const [fromVerseOptions, setFromVerseOptions] = useState([]);
    const [toVerseOptions, setToVerseOptions] = useState([]);

    const [localSelectedBookNumber, setLocalSelectedBookNumber] = useState();
    const [localSelectedFromChapterNumber, setLocalSelectedFromChapterNumber] = useState();
    const [localSelectedToChapterNumber, setLocalSelectedToChapterNumber] = useState();
    const [localSelectedFromVerse, setLocalSelectedFromVerse] = useState();
    const [localSelectedToVerse, setLocalSelectedToVerse] = useState();

    const [displayFormLoader, setDisplayFormLoader] = useState(false);

    const [plumber, setPlumber] = useState(0);

    
    useEffect(() => {
        setDisplayFormLoader(true);
        
        // get book list
        makeApiCalls(
            [
                {
                    endpoint: 'PUBLIC_BIBLE_GET_BOOK_LIST',
                    data: {
                        version: bibleVersion,
                    }
                },
            ]
        ).then((res) => {
            var options = [];
            res[0].data.forEach((book) => {
                options.push({ key: book['book_number'], value: book['book_number'], text: book['long_name'] },);
            });
            setBookOptions(options);
            setDisplayFormLoader(false);
        }).catch((err) => {
            setDisplayFormLoader(false);
        });
    }, []);

    const onSelectBook = (e, data) => {
        setLocalSelectedBookNumber(data.value);
        makeApiCalls(
            [
                {
                    endpoint: 'PUBLIC_BIBLE_GET_CHAPTER_LIST',
                    data: {
                        book_number: data.value,
                        version: bibleVersion
                    }
                }
            ]
        ).then((res) => {
            var options = [];
            res[0].data.forEach((chapterNumber) => {
                options.push({ key: chapterNumber, value: chapterNumber, text: chapterNumber },);
            });
            setChapterOptions(options);
        }).catch((err) => {
            
        });
    }

    const onSelectFromChapter = (e, data) => {
        setLocalSelectedFromChapterNumber(data.value)
        makeApiCalls(
            [
                {
                    endpoint: 'PUBLIC_BIBLE_GET_VERSE_LIST',
                    data: {
                        book_number: localSelectedBookNumber,
                        chapter_number: data.value,
                        version: bibleVersion
                    }
                }
            ]
        ).then((res) => {
            var options = [];
            res[0].data.forEach((verseNumber) => {
                options.push({ key: verseNumber, value: verseNumber, text: verseNumber },);
            });
            setFromVerseOptions(options);
        }).catch((err) => {
            
        });
    }

    const onSelectToChapter = (e, data) => {
        setLocalSelectedToChapterNumber(data.value);
        makeApiCalls(
            [
                {
                    endpoint: 'PUBLIC_BIBLE_GET_VERSE_LIST',
                    data: {
                        book_number: localSelectedBookNumber,
                        chapter_number: data.value,
                        version: bibleVersion
                    }
                }
            ]
        ).then((res) => {
            var options = [];
            res[0].data.forEach((verseNumber) => {
                options.push({ key: verseNumber, value: verseNumber, text: verseNumber },);
            });
            setToVerseOptions(options);
        }).catch((err) => {
            
        });
    }

    const onSelectFromVerse = (e, data) => {
        setLocalSelectedFromVerse(data.value);
    }
    
    const onSelectToVerse = (e, data) => {
        setLocalSelectedToVerse(data.value);    
    }

    const onClickAddToSelectedScopes = () => {
        var tmpSelectedScopes = selectedScopes;

        var targetIndex = tmpSelectedScopes.findIndex(item => 
            item.scope_book_number == localSelectedBookNumber &&
            item.scope_from_chapter == localSelectedFromChapterNumber &&
            item.scope_to_chapter == localSelectedToChapterNumber &&
            item.scope_from_verse == localSelectedFromVerse &&
            item.scope_to_verse == localSelectedToVerse
        );
        
        if (targetIndex < 0) {
            tmpSelectedScopes.push({
                'scope_book': bookOptions.filter(item => item.key == localSelectedBookNumber)[0].text,
                'scope_book_number': localSelectedBookNumber,
                'scope_from_chapter': localSelectedFromChapterNumber,
                'scope_to_chapter': localSelectedToChapterNumber,
                'scope_from_verse': localSelectedFromVerse,
                'scope_to_verse': localSelectedToVerse,
            });
        }
        setSelectedScopes(tmpSelectedScopes);

        // reset current selection
        setLocalSelectedBookNumber(undefined);
        setLocalSelectedFromChapterNumber(undefined);
        setLocalSelectedToChapterNumber(undefined);
        setLocalSelectedFromVerse(undefined);
        setLocalSelectedToVerse(undefined);
        
        // reset selection options
        setChapterOptions([]);
        setFromVerseOptions([]);
        setToVerseOptions([]);
        setPlumber(plumber + 1);
    }

    const onRemoveSelectedScope = (scope) => {
        var tmpSelectedScopes = selectedScopes;
        var targetIndex = tmpSelectedScopes.findIndex(item => 
            item.scope_book_number == scope.scope_book_number &&
            item.scope_from_chapter == scope.scope_from_chapter &&
            item.scope_to_chapter == scope.scope_to_chapter &&
            item.scope_from_verse == scope.scope_from_verse &&
            item.scope_to_verse == scope.scope_to_verse
        );

        if (targetIndex > -1) {
            tmpSelectedScopes.splice(targetIndex, 1);            
        }

        setSelectedScopes(tmpSelectedScopes);
        setPlumber(plumber + 1);
    }

    return (
        <>
            <Dimmer  active={displayFormLoader}>
                <Loader>Loading</Loader>
            </Dimmer>
            <Grid stackable columns={2}>
                <Grid.Row>
                    <Grid.Column width={4}>
                        <Message style={{height: '100%'}}>
                            <Header size='tiny'>Selected Scope</Header>
                            <div style={{width: '100%', margin: "10px 0"}}>
                                {
                                    selectedScopes.length <= 0 &&
                                    <p>Select and Click <Label><Icon name="add"/> Add</Label> to build the scope of your new passage discovery!</p>
                                }
                                {
                                    selectedScopes.map((scope) => (
                                        <Label key={JSON.stringify(scope)} id={JSON.stringify(scope)} as='a' style={{margin: '5px'}}>
                                            <span style={{margin: '0 10px 0 0'}}>{scope.scope_book} {scope.scope_from_chapter}:{scope.scope_from_verse} - {scope.scope_to_chapter}:{scope.scope_to_verse}</span>
                                            <Icon name='times' onClick={() => onRemoveSelectedScope(scope)}/>
                                        </Label>
                                    ))
                                }
                            </div>
                        </Message>
                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Message key={JSON.stringify(selectedScopes)}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <Header size='tiny'>Book</Header>
                                        <Dropdown
                                            // key={`book-${bookOptions.length > 0 ? 'loaded' : 'empty'}-${defaultBookNumber != null && defaultBookNumber != undefined ? 'default' : 'empty'}`}
                                            placeholder='Book'
                                            style={{'width': '100%'}}
                                            fluid
                                            search
                                            selection
                                            options={bookOptions}
                                            onChange={onSelectBook}
                                            value={localSelectedBookNumber}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={8}>
                                        <Header size='tiny'>From Chapter</Header>
                                        <Dropdown
                                            // key={`from-chapter-${chapterOptions.length > 0 ? 'loaded' : 'empty'}-${defaultFromChapterNumber != null && defaultFromChapterNumber != undefined ? 'default' : 'empty'}`}
                                            placeholder='From Chapter'
                                            style={{'width': '100%'}}
                                            fluid
                                            search
                                            selection
                                            options={chapterOptions}
                                            onChange={onSelectFromChapter}
                                            value={localSelectedFromChapterNumber}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={8}>
                                        <Header size='tiny'>From Verse</Header>
                                        <Dropdown
                                            // key={`from-verse-${fromVerseOptions.length > 0 ? 'loaded' : 'empty'}-${defaultFromVerse != null && defaultFromVerse != undefined ? 'default' : 'empty'}`}
                                            placeholder='From Verse'
                                            style={{'width': '100%'}}
                                            fluid
                                            search
                                            selection
                                            options={fromVerseOptions}
                                            onChange={onSelectFromVerse}
                                            value={localSelectedFromVerse}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={8}>
                                        <Header size='tiny'>To Chapter</Header>
                                        <Dropdown
                                            // key={`to-chapter-${chapterOptions.length > 0 ? 'loaded' : 'empty'}-${defaultToChapterNumber != null && defaultToChapterNumber != undefined ? 'default' : 'empty'}`}
                                            placeholder='To Chapter'
                                            style={{'width': '100%'}}
                                            fluid
                                            search
                                            selection
                                            options={chapterOptions}
                                            onChange={onSelectToChapter}
                                            value={localSelectedToChapterNumber}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={8}>
                                        <Header size='tiny'>To Verse</Header>
                                        <Dropdown
                                            // key={`to-verse-${toVerseOptions.length > 0 ? 'loaded' : 'empty'}-${defaultToVerse != null && defaultToVerse != undefined ? 'default' : 'empty'}`}
                                            placeholder='To Verse'
                                            style={{'width': '100%'}}
                                            fluid
                                            search
                                            selection
                                            options={toVerseOptions}
                                            onChange={onSelectToVerse}
                                            value={localSelectedToVerse}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <Button  
                                        disabled={
                                            !(
                                                localSelectedBookNumber !== undefined &&
                                                localSelectedFromChapterNumber !== undefined &&
                                                localSelectedToChapterNumber !== undefined &&
                                                localSelectedFromVerse !== undefined &&
                                                localSelectedToVerse !== undefined
                                            )
                                        } 
                                        fluid onClick={onClickAddToSelectedScopes}><Icon name="add"/> Add</Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Message>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        {
                            errorMessage ? 
                            <Button color="red" fluid disabled><Icon name="warning circle"/> {errorMessage}</Button>
                            :
                            <Button color="yellow" fluid loading={displayLoader} disabled={selectedScopes.length <= 0} onClick={onClickSubmitButton}>{submitButtonText}</Button>
                        }
                    </Grid.Column>
                    
                </Grid.Row>
            </Grid>
        </>
    );
};

PassageDiscoveryScopeForm.propTypes = {
    selectedScopes: PropTypes.array,
    setSelectedScopes: PropTypes.func,

    submitButtonText: PropTypes.string,
    onClickSubmitButton: PropTypes.func,

    bibleVersion: PropTypes.string,
}
