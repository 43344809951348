import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import 'semantic-ui-css/semantic.min.css';
import { accountActivation } from '../services/authenticate';

export const ActivationPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    
    useEffect(() => {

        var urlParams = new URLSearchParams(window.location.search);
        var activationCode = urlParams.get('code');

        if (activationCode) {
            accountActivation(activationCode)
            .then((res) => {
                history.push('/login?ac=1');
            })
            .catch((err) => {
                history.push('/');
            });
        }
    }, [])

    return (
        <div></div>
    );
};