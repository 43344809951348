import React, { Suspense, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import './App.css';
import { createBrowserHistory } from 'history';
import { HomePage } from './pages/HomePage';
import { PassageDiscoveryBuilderStartPage } from './pages/PassageDiscoveryBuilderStartPage';
import { PassageDiscoveryBuilderEditPage } from './pages/PassageDiscoveryBuilderEditPage';
import { PassageDiscoveryBuilderOutputPage } from './pages/PassageDiscoveryBuilderOutputPage';
import { SignupPage } from './pages/SignupPage';
import { LoginPage } from './pages/LoginPage';
import { ProfilePage } from './pages/ProfilePage';
import { getUser } from './services/authenticate';
import { useDispatch, useSelector } from 'react-redux'
import { ForgotPasswordPage } from './pages/ForgotPasswordPage';
import { ResetPasswordPage } from './pages/ResetPasswordPage';
import { getUserProfile } from './services/user';
import { ActivationPage } from './pages/ActivationPage';
import { LessonsPage } from './pages/LessonsPage';
import { LessonPage } from './pages/LessonPage';
import { MyTeamsPage } from './pages/MyTeamsPage';

const browserHistory = createBrowserHistory();

function App() {
  const user = useSelector(state =>
      state.user
  );

  const siteFontSize = useSelector(state =>
    state.siteFontSize
);

  const dispatch = useDispatch();
  const dispatchTool = (toDo, ...args) => {
    dispatch(toDo(...args));
  }

  const rootElement = document.documentElement;


  useEffect(() => {
    if (!user || !user.id) {
      getUserProfile(dispatchTool)
        .then((res) => {
            // do sth
        })
        .catch((err) => {
            // do sth
        });
    }

    rootElement.style.fontSize = `${siteFontSize}px`;
  }, []);
  
  useEffect(() => {
    rootElement.style.fontSize = `${siteFontSize}px`;
  }, [siteFontSize])

  useEffect(() => {
    // Function to clear complete cache data
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });

      console.log('cache cleared in script level');
    });
  }, []);

  return (
      <Router history={browserHistory}>
        <Switch>
          <Route exact path="/" component={HomePage}/>
          <Route exact path="/signup" component={SignupPage}/>
          <Route exact path="/account-activation" component={ActivationPage}/>
          <Route exact path="/login" component={LoginPage}/>
          <Route exact path="/profile" component={ProfilePage}/>
          <Route exact path="/forgot-password" component={ForgotPasswordPage}/>
          <Route exact path="/reset-password" component={ResetPasswordPage}/>
          <Route exact path="/passage-discovery-builder/start" component={PassageDiscoveryBuilderStartPage}/>
          <Route exact path="/passage-discovery-builder/edit/:code" component={PassageDiscoveryBuilderEditPage}/>
          <Route exact path="/passage-discovery-builder/output/:code" component={PassageDiscoveryBuilderOutputPage}/>
          <Route exact path="/lessons" component={LessonsPage}/>
          <Route exact path="/lesson/edit/:code" component={LessonPage}/>
          <Route exact path="/my-teams" component={MyTeamsPage}/>
        </Switch>
      </Router>
  );
}

export default App;
