import { Document, Packer, Paragraph, TextRun, HeadingLevel, Table, TableRow, TableCell, AlignmentType, WidthType, BorderStyle} from "docx";
import { getWordCount, parseHtmlStringToFullText } from "./system";
export const downloadAsDocx = (passageDiscovery) => {

    if (passageDiscovery) {
        var noneBorders = {
            top: {style: BorderStyle.NONE, size: 0, color: "FFFFFF"},
            bottom: {style: BorderStyle.NONE, size: 0, color: "FFFFFF"},
            left: {style: BorderStyle.NONE, size: 0, color: "FFFFFF"},
            right: {style: BorderStyle.NONE, size: 0, color: "FFFFFF"},
        };

        var passageDiscoveryScope = '';
        passageDiscovery.scopes.map((scope, index) => {
            passageDiscoveryScope += scope.scope_book + ' ' + scope.scope_from_chapter + ':' + scope.scope_from_verse + ' - ' + scope.scope_to_chapter + ':' + scope.scope_to_verse;
            if (index + 1 < passageDiscovery.scopes.length) {
                passageDiscoveryScope += ', '
            }
        });
        var sectionChildren = [
            new Paragraph({
                text: passageDiscoveryScope,
                heading: HeadingLevel.HEADING_1
            }),
            new Paragraph('')
        ] ;

        // Content
        sectionChildren.push(new Paragraph({
            text: 'Content',
            heading: HeadingLevel.HEADING_2
        }));
        sectionChildren.push(new Paragraph(''));
        if (passageDiscovery.content && passageDiscovery.content.length > 0) {
            var contentTableRows = [];
            passageDiscovery.content.map((content, index) => {
                var contentScope = '';
                passageDiscovery.scopes.map((scope, index) => {
                    if (content.scopes[index].scope_from_verse != undefined) {
                        contentScope += `${content.scopes[index].scope_book} ${content.scopes[index].scope_from_verse} - ${content.scopes[index].scope_to_verse}`;
                        if (content.scopes[index] && content.scopes[index].scope_from_verse != undefined && content.scopes[index + 1] && content.scopes[index + 1].scope_from_verse != undefined) {
                            contentScope += ', ';
                        }
                    }
                });
                contentTableRows.push(
                    new TableRow({
                        children: [
                            new TableCell({
                                borders: noneBorders,
                                width: {
                                    size: 5,
                                    type: WidthType.PERCENTAGE,
                                },
                                children: [new Paragraph(`${index + 1}.`)],
                            }),
                            new TableCell({
                                borders: noneBorders,
                                width: {
                                    size: 18,
                                    type: WidthType.PERCENTAGE,
                                },
                                children: [new Paragraph(contentScope)],
                            }),
                            new TableCell({
                                borders: noneBorders,
                                children: [new Paragraph(content.description)],
                            }),
                        ],
                    })
                );
                contentTableRows.push(
                    new TableRow({
                        children: [
                            new TableCell({
                                borders: noneBorders,
                                width: {
                                    size: 5,
                                    type: WidthType.PERCENTAGE,
                                },
                                children: [new Paragraph('')],
                            }),
                            new TableCell({
                                borders: noneBorders,
                                width: {
                                    size: 18,
                                    type: WidthType.PERCENTAGE,
                                },
                                children: [new Paragraph('')],
                            }),
                            new TableCell({
                                borders: noneBorders,
                                children: [new Paragraph('')],
                            }),
                        ],
                    })
                );
            })
            sectionChildren.push(
                new Table({
                    rows: contentTableRows,
                }),
            );
        } else {
            sectionChildren.push(new Paragraph('No content created yet.'));
        }
        sectionChildren.push(new Paragraph(''));

        // Division
        sectionChildren.push(new Paragraph({
            text: 'Division',
            heading: HeadingLevel.HEADING_2
        }));
        sectionChildren.push(new Paragraph(''));
        if (passageDiscovery.division && passageDiscovery.division.length > 0) {
            var divisionTableRows = [];
            passageDiscovery.division.map((division, index) => {
                var divisionScope = '';
                passageDiscovery.scopes.map((scope, index) => {
                    if (division.scopes[index].scope_from_verse != undefined) {
                        divisionScope += `${division.scopes[index].scope_book} ${division.scopes[index].scope_from_verse} - ${division.scopes[index].scope_to_verse}`;
                        if (division.scopes[index] != undefined && division.scopes[index].scope_from_verse != undefined && division.scopes[index + 1] && division.scopes[index + 1].scope_from_verse != undefined) {
                            divisionScope += ', ';
                        }
                    }
                });
                divisionTableRows.push(
                    new TableRow({
                        children: [
                            new TableCell({
                                borders: noneBorders,
                                width: {
                                    size: 5,
                                    type: WidthType.PERCENTAGE,
                                },
                                children: [new Paragraph(`${index + 1}.`)],
                            }),
                            new TableCell({
                                borders: noneBorders,
                                width: {
                                    size: 18,
                                    type: WidthType.PERCENTAGE,
                                },
                                children: [new Paragraph(divisionScope)],
                            }),
                            new TableCell({
                                borders: noneBorders,
                                children: [new Paragraph(division.description)],
                            }),
                        ],
                    })
                );
                divisionTableRows.push(
                    new TableRow({
                        children: [
                            new TableCell({
                                borders: noneBorders,
                                width: {
                                    size: 5,
                                    type: WidthType.PERCENTAGE,
                                },
                                children: [new Paragraph('')],
                            }),
                            new TableCell({
                                borders: noneBorders,
                                width: {
                                    size: 18,
                                    type: WidthType.PERCENTAGE,
                                },
                                children: [new Paragraph('')],
                            }),
                            new TableCell({
                                borders: noneBorders,
                                children: [new Paragraph('')],
                            }),
                        ],
                    })
                );
            })
            sectionChildren.push(
                new Table({
                    rows: divisionTableRows,
                }),
            );
        } else {
            sectionChildren.push(new Paragraph('No division created yet.'));
        }
        sectionChildren.push(new Paragraph(''));

        // Subject Sentence
        sectionChildren.push(new Paragraph({
            text: 'Subject Sentence',
            heading: HeadingLevel.HEADING_2
        }));
        sectionChildren.push(new Paragraph(''));
        if (passageDiscovery.subject_sentence) {
            sectionChildren.push(new Paragraph(parseHtmlStringToFullText(passageDiscovery.subject_sentence)));
            var subjectSentenceDescriptionWordCount = getWordCount(passageDiscovery.subject_sentence);
            sectionChildren.push(new Paragraph(`(${subjectSentenceDescriptionWordCount} ${subjectSentenceDescriptionWordCount == 1?' Word':' Words'})`));
        } else {
            sectionChildren.push(new Paragraph('No subject sentence created yet.'));
        }
        sectionChildren.push(new Paragraph(''));

        // Aim
        sectionChildren.push(new Paragraph({
            text: 'Aim',
            heading: HeadingLevel.HEADING_2
        }));
        sectionChildren.push(new Paragraph(''));
        if (passageDiscovery.aim) {
            passageDiscovery.aim.split('\r\n').map((aimLine) => {
                sectionChildren.push(new Paragraph(aimLine));
            });
        } else {
            sectionChildren.push(new Paragraph('No aim created yet.'));
        }
        sectionChildren.push(new Paragraph(''));

        // Application
        sectionChildren.push(new Paragraph({
            text: 'Application',
            heading: HeadingLevel.HEADING_2
        }));
        sectionChildren.push(new Paragraph(''));
        if (passageDiscovery.application && passageDiscovery.application.length > 0) {
            var applicationTableRows = [];
            passageDiscovery.application.map((application, index) => {
                var applicationScope = '';
                passageDiscovery.scopes.map((scope, index) => {
                    if (application.scopes[index].scope_from_verse) {
                        applicationScope += `${application.scopes[index].scope_book} ${application.scopes[index].scope_from_verse} - ${application.scopes[index].scope_to_verse}`;
                        if (application.scopes[index] != undefined && application.scopes[index].scope_from_verse != undefined && application.scopes[index + 1] && application.scopes[index + 1].scope_from_verse != undefined) {
                            applicationScope += ', ';
                        }
                    }
                });
                
                var applicationLines = [];
                application.description.split('\r\n').map((descriptionLine) => {
                    applicationLines.push(new Paragraph(descriptionLine));
                });
                applicationTableRows.push(
                    new TableRow({
                        children: [
                            new TableCell({
                                borders: noneBorders,
                                width: {
                                    size: 5,
                                    type: WidthType.PERCENTAGE,
                                },
                                children: [new Paragraph(`${index + 1}.`)],
                            }),
                            new TableCell({
                                borders: noneBorders,
                                width: {
                                    size: 18,
                                    type: WidthType.PERCENTAGE,
                                },
                                children: [new Paragraph(applicationScope)],
                            }),
                            new TableCell({
                                borders: noneBorders,
                                children: applicationLines,
                            }),
                        ],
                    })
                );
                applicationTableRows.push(
                    new TableRow({
                        children: [
                            new TableCell({
                                borders: noneBorders,
                                width: {
                                    size: 5,
                                    type: WidthType.PERCENTAGE,
                                },
                                children: [new Paragraph('')],
                            }),
                            new TableCell({
                                borders: noneBorders,
                                width: {
                                    size: 18,
                                    type: WidthType.PERCENTAGE,
                                },
                                children: [new Paragraph('')],
                            }),
                            new TableCell({
                                borders: noneBorders,
                                children: [new Paragraph('')],
                            }),
                        ],
                    })
                );
            })
            sectionChildren.push(
                new Table({
                    rows: applicationTableRows,
                }),
            );
        } else {
            sectionChildren.push(new Paragraph('No application created yet.'));
        }
        sectionChildren.push(new Paragraph(''));

        const doc = new Document({
            sections: [{
                properties: {},
                children: sectionChildren,
            }],
        });

        Packer.toBlob(doc).then((blob) => {
            // saveAs from FileSaver will download the file
            const downloadURL = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadURL;
            var passageDiscoveryScope = '';
            passageDiscovery.scopes.map((scope, index) => {
                passageDiscoveryScope += scope.scope_book + '_' + scope.scope_from_chapter + '[' + scope.scope_from_verse + ']- ' + scope.scope_to_chapter + '[' + scope.scope_to_verse + ']';
                if (index + 1 < passageDiscovery.scopes.length) {
                    passageDiscoveryScope += '_'
                }
            });
            link.download = `${passageDiscoveryScope}.docx`;
            link.click();
        });
    }
}