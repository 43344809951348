import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import 'semantic-ui-css/semantic.min.css';
import { Dimmer, Loader, Popup, Form, Container, Message, Grid, Segment, Header, Button, TextArea, Image, List, Progress, Divider, Label, Icon, Tab, Input, Accordion, Checkbox, Rating, Card} from 'semantic-ui-react';
import { ResponsiveContainer } from '../containers/ResponsiveContainer';
import { useForm } from "react-hook-form";
import { makeApiCalls } from '../redux/api';
import { resetPassword } from '../services/authenticate';

export const ResetPasswordPage = () => {
    const user = useSelector(state =>
        state.user
    );
    const dispatch = useDispatch();
    const history = useHistory();
    const {register, handleSubmit, getValues} = useForm();
    const [verificationCode, setVerificationCode] = useState();
    const [verificationCodeIsValid, setVerificationCodeIsValid] = useState();
    const [passwordIsValid, setPasswordIsValid] = useState();
    const [displayPasswordResetFailedMessage, setDisplayPasswordResetFailedMessage] = useState(false);
    const [displayLoader, setDisplayLoader] = useState(false);
    
    useEffect(() => {
        var urlParams = new URLSearchParams(window.location.search);
        var code = urlParams.get('code');

        if (code) {
            setVerificationCode(code);
            onChangeActivationCode({target:{value: code}});
        }

    }, [])

    const onChangeActivationCode = (event) => {
        setVerificationCodeIsValid(
            event.target.value != null &&
            event.target.value != undefined && 
            event.target.value != '' 
        );
    }

    const onChangePassword = (event) => {
        setPasswordIsValid(
            event.target.value == getValues('password_confirmation') && 
            event.target.value.length > 7
        );
    }
    
    const onChangePasswordConfirmation = (event) => {
        setPasswordIsValid(
            event.target.value == getValues('password')&& 
            event.target.value.length > 7
        );
    }

    const onSubmit = (formData) => {
        // display loader
        setDisplayLoader(true);

        // reset messages
        setDisplayPasswordResetFailedMessage(false);

        // register
        resetPassword(
            formData.verification_code,
            formData.password,
        )
        .then((res) => {
            setDisplayLoader(false);
            history.push('/login?rp=1');
        })
        .catch((err) => {
            setDisplayLoader(false);
            setDisplayPasswordResetFailedMessage(true);
        });
    }

    return (
        <ResponsiveContainer>
            <Container>
                <Dimmer active={displayLoader}>
                    <Loader>Loading</Loader>
                </Dimmer>
                {
                    displayPasswordResetFailedMessage &&
                    <>
                        <Message icon size='large'  color="red">
                            <Icon name='times' />
                            <Message.Content>
                                <Message.Header>Password Reset failed.</Message.Header>
                                Please try again.
                            </Message.Content>
                        </Message>
                    </> 
                }
                <Message size='large' attached>
                    <Message.Header>Reset Password</Message.Header>
                    <Message.Content>
                        Enter the verification code and new password to reset your account credentials.
                    </Message.Content>
                </Message>

                <Form className='attached fluid segment' onSubmit={handleSubmit(onSubmit)}>
                    <Form.Field width={16} className={verificationCodeIsValid==false?'error':''}>
                        <label htmlFor="verification_code">Verification Code {verificationCodeIsValid == true && <Icon color="green" name='check' />}</label>
                        {
                            verificationCodeIsValid==false && 
                            <Message color="red">
                                <Message.List items={[
                                    'Please enter the verification code.',
                                ]} />
                            </Message>
                        }
                        <input type="text" name="verification_code" id="verification_code" defaultValue={verificationCode} ref={register} onChange={onChangeActivationCode}/>
                    </Form.Field>
                    <Form.Group widths='equal'>
                        <Form.Field width={8} className={passwordIsValid==false?'error':''}>
                            <label htmlFor="password">Password {passwordIsValid == true && <Icon color="green" name='check' />}</label>
                            {
                                passwordIsValid == false &&
                                <Message color="red">
                                    <Message.List items={[
                                        'Please make sure the passwords match.',
                                        'Please choose a password longer than 8 characters.'
                                    ]} />
                                </Message>
                            }
                            <input type="password" name="password" id="password" ref={register} onChange={onChangePassword}/>
                        </Form.Field>
                        <Form.Field width={8} className={passwordIsValid==false?'error':''}>
                            <label htmlFor="password_confirmation">Confirm Password {passwordIsValid == true && <Icon color="green" name='check' />}</label>
                            {
                                passwordIsValid == false &&
                                <Message color="red">
                                    <Message.List items={[
                                        'Please make sure the passwords match.',
                                        'Please choose a password longer than 8 characters.'
                                    ]} />
                                </Message>
                            }
                            <input type="password" name="password_confirmation" id="password_confirmation" ref={register} onChange={onChangePasswordConfirmation}/>
                        </Form.Field>
                    </Form.Group>
                    {/* <Form.Checkbox inline label='I agree to the terms and conditions' /> */}
                    <Button color="yellow" fluid className={(verificationCodeIsValid == true && passwordIsValid == true) ? '' : 'disabled'}>Submit</Button>
                </Form>
            </Container>
            
        </ResponsiveContainer>
    );
};