import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import 'semantic-ui-css/semantic.min.css';
import { Dimmer, Loader, Form, Container, Message, Grid, Segment, Header, Button, TextArea, Image, List, Progress, Divider, Label, Icon, Tab, Input, Accordion, Checkbox, Rating, Card, GridRow, GridColumn} from 'semantic-ui-react';
import { ResponsiveContainer } from '../containers/ResponsiveContainer';
import { useForm } from "react-hook-form";
import { forgotPassword, storeJwtToken } from '../services/authenticate';
import { setUser } from '../redux/actions';
import { makeApiCalls } from '../redux/api';
import './ForgotPasswordPage.css';

export const ForgotPasswordPage = () => {
    const user = useSelector(state =>
        state.user
    );
    const dispatch = useDispatch();
    const history = useHistory();
    const { register, handleSubmit } = useForm();
    const [displayLoader, setDisplayLoader] = useState(false);

    const onSubmit = (formData) => {
        // display loader
        setDisplayLoader(true);

        // forgot password
        forgotPassword(
            formData.email
        )
        .then((res) => {
            setDisplayLoader(false);
            history.push('/login?fp=1');
        })
        .catch((res) => {
            setDisplayLoader(false);
        });

        
    }

    return (
        <ResponsiveContainer
            headerActiveTab="Log in"
        >
            <Container>
                <Dimmer active={displayLoader}>
                    <Loader>Loading</Loader>
                </Dimmer>
                <Message size='large' attached>
                    <Message.Header>Forgot Password</Message.Header>
                    <Message.Content>
                        Enter you BSF Teams account email and request for password reset.
                    </Message.Content>
                </Message>
                <Form className='attached fluid segment' onSubmit={handleSubmit(onSubmit)}>
                    <Form.Field width={16}>
                        <label htmlFor="email">Email</label>
                        <input type="text" name="email" id="email" ref={register} />
                    </Form.Field>
                    <Button color="yellow" fluid>Submit</Button>
                </Form>

                <Message attached='bottom' warning>
                    <Icon name='help' />
                    New to BSF Teams?&nbsp;<a href='/signup'>Sign up here</a>&nbsp;instead.
                </Message>
            </Container>
            
        </ResponsiveContainer>
    );
};